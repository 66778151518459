import { List, Toast } from 'antd-mobile';
import { useH5Company } from './Layout';
import { useEffect, useMemo } from 'react';
import { flatDepartment } from '../../utils';
import { useRequest } from 'ahooks';
import { loginController } from '../../api';
import { useNavigate } from 'react-router-dom';

export default () => {
  const { company, user, allDepartments } = useH5Company();
  const navigate = useNavigate();
  const department = useMemo(() => {
    const result = flatDepartment(allDepartments, user.departmentId)
      .reverse()
      .map(({ name }) => name);

    if (!result.length) {
      return '-';
    }
    return result.join('/');
  }, [user, allDepartments]);

  const { run: logout, loading } = useRequest(
    () => {
      return loginController.logout().then(() => {
        localStorage.clear();
        window.location.reload();
      });
    },
    {
      manual: true,
      onSuccess: () => {
        Toast.clear();
        navigate('/h5/login');
      },
    },
  );

  useEffect(() => {
    if (loading) {
      Toast.show({
        content: '退出登录中',
      });
    }
  }, [loading]);

  const registerId = (mask: boolean) => {
    const value = sessionStorage.getItem('registerId');
    if (!value) {
      return undefined;
    }
    if (mask) {
      return value.replace(/^\w{4}(.*)/, '****$1');
    }
    return value;
  };

  return (
    <>
      <List header={company.name}>
        <List.Item title={department}>{user.name}</List.Item>
        <List.Item>{user.mobile}</List.Item>
      </List>
      <List header="系统">
        <List.Item title="设备信息" description={registerId(false)} />
        <List.Item onClick={() => logout()}>退出登录</List.Item>
      </List>
    </>
  );
};
