import { useRequest } from 'ahooks';
import { SelectProps, Select, Space } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useState, useEffect } from 'react';
import { BaseUserDTO, userController, UserStateEnum } from '../api';
import { useCompany } from '../hooks/company';

const UserSelect = ({ disableAll, ...props }: SelectProps & {disableAll?:true}) => {
  const [users, setUsers] = useState<BaseUserDTO[]>();
  const { id: companyId, departments, currentUser } = useCompany();

  const { loading, data } = useRequest(
    () => {
      return userController
        .listAllBaseUser(companyId)
        .then((users) =>
          users.filter(({ state }) => {
            return state !== UserStateEnum.DELETED
          }),
        ).then((users) => {
          if (!disableAll || currentUser?.isSuperAdmin) {
            return users;
          }
          return users.filter(({ departmentId }) => {
            return departments?.find(({ id }) => id === departmentId);
          })
        });
    },
    {
      refreshDeps: [companyId, disableAll, currentUser],
    },
  );

  useEffect(() => {
    setUsers(data);
  }, [data]);

  return (
    <Select<I.ID, DefaultOptionType & { user: BaseUserDTO }>
      {...props}
      loading={loading}
      showSearch
      filterOption={(v, o) => {
        return !!(
          o?.user?.name?.includes(v) ||
          String(o?.user?.id).includes(v) ||
          o?.user.mobile?.includes(v)
        );
      }}
      options={users?.map((user) => ({
        label: (
          <Space>
            <span>{user.name}</span>
            <span>({user.id})</span>
          </Space>
        ),
        user,
        value: user.id,
      }))}
    />
  );
};

export default UserSelect;
