import { useBoolean, useRequest } from 'ahooks';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Rate,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  message,
} from 'antd';
import {
  CustomerDTO,
  CustomerEventTypeEnum,
  CustomerGroupEnum,
  CustomerPoolEnum,
  CustomerStateEnum,
  GenderEnum,
  customerController,
} from '../api';
import { useCompany } from '../hooks/company';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { customerGroupMapping, extraKeys, generateExtra } from '../constants';
import CustomerStateSelect from './CustomerStateSelect';
import { EyeFill, PhoneFill } from 'antd-mobile-icons';
import LastEvent from './LastEvent';
import { InfoCircleOutlined } from '@ant-design/icons';
import CustomerAlarm, { CustomerAlarmClock } from './CustomerAlarm';

const refreshContext = createContext<{ refresh?: () => void }>({});

export const userRefreshContext = () => {
  return useContext(refreshContext);
};

export const RefreshProvider = ({
  children,
  refresh,
}: PropsWithChildren<{ refresh: () => void }>) => {
  return (
    <refreshContext.Provider value={{ refresh }}>
      {children}
    </refreshContext.Provider>
  );
};

const MakeCall = ({ customerId }: { customerId: I.ID }) => {
  const { id: companyId } = useCompany();
  const { run: makeCall, loading } = useRequest(
    () => {
      return customerController.callCustomer(companyId, customerId);
    },
    {
      refreshDeps: [customerId],
      manual: true,
      onError: (e) => {
        message.error(e.message);
      },
      onSuccess: () => {
        message.success('拨打成功，请去 app 查看');
      },
    },
  );
  return (
    <Button
      type="link"
      icon={<PhoneFill />}
      loading={loading}
      onClick={() => makeCall()}
    >
      打电话
    </Button>
  );
};

const Comment = ({
  onChange,
  value,
  hitSensitiveWord,
}: {
  onChange: (v: string) => void;
  value?: string;
  hitSensitiveWord: boolean;
}) => {
  const tips = ['无人接听', '直接挂断', '不需要', '关机'];

  const change = useCallback(
    (v: string) => {
      onChange(v);
    },
    [onChange],
  );

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <div>
        <Space>
          {tips.map((v) => (
            <Button key={v} type="link" onClick={() => change(v)}>
              {v}
            </Button>
          ))}
        </Space>
      </div>
      {hitSensitiveWord && (
        <Alert message="请不要输入违禁词" type="warning" showIcon />
      )}
      <Input.TextArea
        placeholder="跟进备注"
        value={value}
        status={hitSensitiveWord ? 'error' : undefined}
        onChange={(v) => change(v.target.value)}
        rows={5}
        autoComplete="off"
      />
    </Space>
  );
};

const convertStarState = (star?: number) => {
  switch (star) {
    case undefined:
    case 0:
      return [CustomerStateEnum.WAIT_FOLLOW_UP];
    case 1:
      return [
        CustomerStateEnum.UNQUALIFIED,
        CustomerStateEnum.VISITED,
        CustomerStateEnum.SIGNED,
        CustomerStateEnum.EXAMINING,
        CustomerStateEnum.INVALID,
      ];
    case 2:
    case 3:
    case 4:
    case 5:
      return [
        CustomerStateEnum.LOAN,
        CustomerStateEnum.SIGNED,
        CustomerStateEnum.VISITED,
        CustomerStateEnum.EXAMINING,
        CustomerStateEnum.WAIT_SIGN,
      ];
    default:
      return undefined;
  }
};

export default ({
  id,
  open,
  onCancel,
  next,
  withComment,
  updateCustomerNamePermission,
  showMobile,
}: {
  open?: boolean;
  next?: (currentId: I.ID) => void;
  onCancel: () => void;
  id: I.ID;
  withComment: boolean;
  updateCustomerNamePermission?: boolean;
  showMobile?: boolean;
}) => {
  const {
    id: companyId,
    config: companyConfig,
    currentUser: { isSuperAdmin },
  } = useCompany();

  const [comment, setComment] = useState<string>();
  const [dropPublicPool, setDropPublicPool] = useState<boolean>();

  const [mobile, setMobile] = useState<string>();

  const { refresh: refreshParent } = useContext(refreshContext);

  const [form] = Form.useForm();

  const currentStar = Form.useWatch<number>('star', form);

  const starState = useMemo(() => {
    return convertStarState(currentStar);
  }, [currentStar]);

  const hitSensitiveWord = useMemo(() => {
    if (
      comment &&
      companyConfig?.sensitiveWordList?.find((item) => comment.includes(item))
    ) {
      return true;
    }
    return false;
  }, [comment, companyConfig]);

  const {
    data: customer,
    loading: getLoading,
    run,
    refresh,
  } = useRequest(
    (id: I.ID) => {
      return customerController.getDetail(companyId, id);
    },
    {
      manual: true,
      onSuccess: () => {
        form.resetFields();
      },
    },
  );

  const disableState = useMemo(() => {
    if (isSuperAdmin) {
      return false;
    }
    return customer?.state === CustomerStateEnum.LOAN;
  }, [customer, isSuperAdmin]);

  const {
    data: events,
    loading: eventsLoading,
    run: loadEvents,
  } = useRequest(
    () => {
      return customerController.listEvent(companyId, id);
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    setMobile(customer?.mobile);
  }, [customer?.mobile, setMobile]);

  useEffect(() => {
    if (open) {
      setDropPublicPool(false);
      setComment(undefined);
      loadEvents();
      run(id);
    }
  }, [open, id]);

  const { loading: updateLoading, runAsync: update } = useRequest(
    customerController.updateCustomer,
    {
      manual: true,
      refreshDeps: [id, companyId],
      onSuccess: () => {
        refresh();
        refreshParent?.();
      },
      onError: (e) => {
        message.error(e.message);
      },
    },
  );

  const { loading: createEventLoading, runAsync: addEvent } = useRequest(
    (comment: string, drop?: boolean) => {
      return customerController.addEvent(
        companyId,
        id,
        drop
          ? CustomerEventTypeEnum.DROP_PUBLIC_POOL
          : CustomerEventTypeEnum.COMMENT,
        comment,
      );
    },
    {
      manual: true,
      refreshDeps: [comment, id, companyId],
    },
  );

  const loading = getLoading || updateLoading || createEventLoading;

  const onSubmit = useCallback(
    (customer?: CustomerDTO, comment?: string) => {
      return form
        .validateFields()
        .then((values) => {
          const dto = {
            ...customer,
            ...values,
            mobile,
            extra: undefined,
            pool: dropPublicPool
              ? CustomerPoolEnum.PUBLIC
              : CustomerPoolEnum.BIZ,
          };
          Object.entries(extraKeys).forEach(([key]) => {
            dto[key] = values?.extra.includes(key);
          });

          return update(companyId, id, dto)
            .then(() => {
              if (comment) {
                return addEvent(comment, dropPublicPool);
              }
            })
            .then(() => {
              message.success('更新成功');
            });
        })
        .catch((e) => {
          if (e.errorFields && e.errorFields.length) {
            message.error(e.errorFields[0].errors[0]);
          }
          return Promise.reject(e);
        });
    },
    [customer, mobile, dropPublicPool, form, update, addEvent],
  );

  useEffect(() => {
    form.resetFields();
  }, [customer, open]);

  const groupOptions = Object.entries(customerGroupMapping).map(
    ([value, label]) => ({
      label,
      value,
    }),
  );

  const onSubmitAndNext = useCallback(
    (comment?: string) => {
      return onSubmit(customer, comment)
        .then(() => {
          next?.(id);
        })
        .catch((e) => message.error(e.message));
    },
    [id, customer, mobile, onSubmit],
  );

  return (
    <Modal
      width={withComment ? 900 : undefined}
      title={customer?.name}
      open={open}
      onCancel={onCancel}
      confirmLoading={loading}
      okText="更新"
      footer={[
        <Button
          key="submit"
          type="primary"
          disabled={hitSensitiveWord}
          onClick={() =>
            onSubmit(customer, comment)
              .then(() => onCancel())
              .catch((e) => message.error(e.message))
          }
          loading={loading}
        >
          提交并关闭
        </Button>,
        next && (
          <Button
            disabled={hitSensitiveWord}
            key="back"
            type="primary"
            onClick={() => onSubmitAndNext(comment)}
          >
            下一个并提交
          </Button>
        ),
      ]}
    >
      <Spin spinning={loading}>
        <Row>
          <Col span={withComment ? 14 : 24}>
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              form={form}
              initialValues={{
                ...customer,
                extra: generateExtra(customer),
                mobile: customer?.mobile?.replace(
                  /^([0-9]{4})[0-9]{4}(.*)$/,
                  '$1****$2',
                ),
              }}
              autoComplete="off"
            >
              <Form.Item
                name="mobile"
                label={
                  <Space>
                    <span>电话</span>
                    {showMobile && (
                      <Tooltip title={mobile}>
                        <EyeFill />
                      </Tooltip>
                    )}
                  </Space>
                }
              >
                <Input disabled={!!customer?.mobile} />
              </Form.Item>
              <Form.Item
                name="channel"
                label="渠道"
                rules={[{ required: true }]}
              >
                <Input disabled={true} />
              </Form.Item>
              <Form.Item name="name" label="姓名" rules={[{ required: true }]}>
                <Input disabled={!updateCustomerNamePermission} />
              </Form.Item>
              <Form.Item name="aliasName" label="别名">
                <Input />
              </Form.Item>
              <Form.Item name="gender" label="性别">
                <Radio.Group
                  options={[
                    { label: '男', value: GenderEnum.MAN },
                    { label: '女', value: GenderEnum.WOMEN },
                  ]}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
              <Form.Item
                name="star"
                label={
                  <Space>
                    <Tooltip
                      overlayInnerStyle={{ width: '550px' }}
                      placement="right"
                      title={
                        <div style={{ lineHeight: '15px' }}>
                          <p>
                            0星 待跟进，没有有效沟通或者没有探出任何客户的资质
                          </p>
                          <p>
                            1星
                            资质不符，完全没有可贷条件、自由职业者无资产之类。
                          </p>
                          <p>
                            1星 捣乱申请，
                            <p style={{ paddingLeft: '20px' }}>
                              <p>
                                1.空号、停机、老赖、黑户、执行人、逾期时间很长。
                              </p>
                              <p>2. 态度不好、骂人、要举报、投诉之类。</p>
                              <p>3.异地且不来上海的。</p>
                            </p>
                          </p>
                          <p>
                            2星 待签约，
                            <p style={{ paddingLeft: '20px' }}>
                              <p>1. 单纯上班工资，收入不高(无公积金)。</p>
                              <p>2.一辆价值不高的车。</p>
                              <p>3. 一份小保单。</p>
                            </p>
                          </p>
                          <p>
                            3星
                            待签约，低额公积金、房(上海全款或者全国按揭房)、小法人等单一可贷点
                          </p>
                          <p>
                            4星
                            待签约，多维度小可贷点或者高额公积金、高额的开票法人、高收入，高额的资产
                          </p>
                          <p>5星 待签约，一般资质非常好客户或者已放款客户</p>
                        </div>
                      }
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                    <span>星级</span>
                  </Space>
                }
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value, callback) {
                      const state = getFieldValue('state');
                      const starState = convertStarState(value);
                      if (!starState?.length) {
                        return Promise.resolve();
                      }
                      if (!starState.includes(state)) {
                        return Promise.reject('星级不符合当前状态');
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                dependencies={['state']}
              >
                <Rate />
              </Form.Item>
              <Form.Item
                name="state"
                label={
                  <Space>
                    <Tooltip
                      overlayInnerStyle={{ width: '350px' }}
                      title={
                        <div>
                          <p>以下几类客户遇到星级和状态修改成：</p>
                          <p>1星--捣乱申请</p>
                          <p>一：空号、停机（注意是否是手机号码问题）</p>
                          <p>二：客户不来上海了、同行</p>
                          <p>三：再打报警之类很暴躁的</p>
                          <p>四：黑户、老赖、执行人等</p>
                          <p>每个人都要严格执行。</p>
                        </div>
                      }
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                    <span>状态</span>
                  </Space>
                }
              >
                <CustomerStateSelect
                  enableState={starState}
                  disabled={disableState}
                  disableState={[CustomerStateEnum.LOAN]}
                />
              </Form.Item>
              <Form.Item name="group" label="分组">
                <Select options={groupOptions} />
              </Form.Item>
              <Form.Item name="remark" label="其他资质">
                <Input.TextArea rows={5} />
              </Form.Item>
              <Form.Item name="extra" label="附属信息">
                <Checkbox.Group
                  options={Object.entries(extraKeys).map(([value, label]) => ({
                    value,
                    label,
                  }))}
                />
              </Form.Item>
            </Form>
          </Col>
          {withComment && (
            <Col span={10}>
              <Row>
                <Col style={{ width: '100%' }}>
                  <Comment
                    onChange={setComment}
                    value={comment}
                    hitSensitiveWord={hitSensitiveWord}
                  />
                </Col>
                <Col style={{ marginTop: '10px', width: '100%' }}>
                  <Space
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <>{customer?.id && <MakeCall customerId={customer.id} />}</>
                    <Checkbox
                      checked={dropPublicPool}
                      onChange={(v) => {
                        setDropPublicPool(v.target.checked);
                      }}
                    >
                      抛入公共池
                    </Checkbox>
                  </Space>
                </Col>
              </Row>
              {customer?.id && (
                <Row style={{ marginTop: '10px' }}>
                  <Col span={24}>
                    <Space>
                      {[5, 15, 30].map((minute) => {
                        return (
                          <CustomerAlarm
                            key={minute}
                            customerId={customer?.id}
                            minute={minute}
                            msg="处理客户"
                          />
                        );
                      })}
                      <span>分钟后提醒处理</span>
                    </Space>
                  </Col>
                  <Col span={24}>
                    <Space>
                      <CustomerAlarmClock customerId={customer.id} />
                    </Space>
                  </Col>
                </Row>
              )}
              <Divider />
              <Row style={{ marginTop: '10px' }}>
                <Col span={24}>
                  {customer && (
                    <Spin spinning={eventsLoading}>
                      <LastEvent
                        customerId={customer?.id}
                        customerName={customer?.name}
                        event={events?.[0]}
                      />
                    </Spin>
                  )}
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Spin>
    </Modal>
  );
};
