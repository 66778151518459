import MobileIndex from '.';
import CustomerDetail from './CustomerDetail';
import MobileLayout from './Layout';
import Login from './Login';
import My from './My';

export interface MobileMenuRouter {
  key: string;
  label: string;
  component?: React.ReactNode;
  children?: MobileMenuRouter[];
}

export const mobileRoutes: MobileMenuRouter[] = [
  {
    key: '',
    label: '首页',
    component: <MobileLayout />,
    children: [
      {
        key: '',
        label: '首页',
        component: <MobileIndex />,
      },
      {
        key: 'customer/:customerId',
        label: '客户详情',
        component: <CustomerDetail />,
      },
      {
        key: 'my',
        label: '个人中心',
        component: <My />,
      },
    ],
  },
  {
    key: 'login',
    label: '登录',
    component: <Login />,
  },
];
