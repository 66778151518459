import { NavBar, SafeArea, TabBar, Toast, WaterMark } from 'antd-mobile';
import {
  Outlet,
  useLocation,
  useNavigate,
  useNavigationType,
} from 'react-router-dom';

import './layout.css';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  BaseDepartmentDTO,
  CompanyDTO,
  Long,
  RoleDTO,
  SystemConfigModel,
  UserDTO,
  UserStateEnum,
  companyController,
  loginController,
  roleController,
} from '../../api';
import {
  useEventEmitter,
  useGetState,
  useRequest,
  useTitle,
  useWebSocket,
} from 'ahooks';
import { findAllDepartmentId } from '../../utils';
import { pushHost } from '../../apis/request';
import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import { Result } from 'ahooks/lib/useWebSocket';

const useSearch = () => {
  const { search } = useLocation();
  return new URLSearchParams(search?.substring(1));
};

interface ContextProps {
  user: UserDTO;
  company: CompanyDTO;
  departments: BaseDepartmentDTO[];
  allDepartments: BaseDepartmentDTO[];
  rootDepartmentId: I.ID | null;
  systemConfig?: SystemConfigModel;
  roles?: RoleDTO[];
  setTitle: (title: string) => void;
  withCall?: I.ID;
  resetCall?: () => void;
}

const defaultContextValue: ContextProps = {
  user: {
    id: '',
    createdAt: '',
    isSuperAdmin: false,
    mobile: '',
    name: '',
    state: UserStateEnum.INACTIVE,
    updatedAt: '',
  },
  rootDepartmentId: '',
  allDepartments: [],
  departments: [],
  company: {
    createdAt: '',
    id: '',
    name: '',
    updatedAt: '',
  },
  setTitle() {},
};

const context = createContext<ContextProps>({
  ...defaultContextValue,
});

export const useH5Company = () => {
  return useContext(context);
};

const H5NavBar = ({ title }: { title?: string }) => {
  const [activeKey, setActivekey] = useState<string>();
  const { pathname } = useLocation();

  const { user } = useH5Company();

  const qs = useSearch();

  const prePath = useMemo(() => {
    return qs.get('prePath');
  }, [qs]);

  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === '/h5/my') {
      setActivekey('/h5/my');
    } else if (pathname === '/h5') {
      setActivekey('/h5');
    }
  }, [pathname, setActivekey]);

  return (
    <>
      <div
        style={{
          // backgroundColor: '#f1f1f1',
          height: '100vh',
          flexDirection: 'column',
          display: 'flex',
        }}
      >
        <div className="top-tabnav">
          <NavBar
            backArrow={!!prePath}
            onBack={() => prePath && navigate(prePath)}
          >
            {title}
          </NavBar>
        </div>
        <div className="body-tabnav">
          <Outlet />
          <WaterMark
            content={[user.name, user.mobile.substring(7)]}
            fullPage={false}
          />
        </div>
        <div className="bottom-tabnav">
          <TabBar
            activeKey={activeKey}
            onChange={(v) => {
              setActivekey(v);
              navigate(v);
            }}
          >
            <TabBar.Item key="/h5" title="我的客户" />
            <TabBar.Item key="/h5/my" title="个人中心" />
          </TabBar>
        </div>
      </div>
    </>
  );
};

const useToken = () => {
  const [token, setToken] = useState<string>();

  useEffect(() => {
    const h5Info = localStorage.getItem('h5_info');
    if (h5Info) {
      const info = JSON.parse(h5Info) as { token: string };
      setToken(info.token);
    }
  }, []);
  return token;
};

export default function MobileLayout() {
  const [props, setProps] = useState<ContextProps>({ ...defaultContextValue });
  const [companyId, setCompanyId] = useState<Long>();

  const [title, setTitle] = useState<string>();

  const location = useLocation();

  const navigate = useNavigate();

  const prevPath = useRef<string>();

  const token = useToken();

  const ws = useRef<Result>();

  const [withCall, setWithCall] = useState<I.ID>();

  ws.current = useWebSocket(`${pushHost}/push?token=${token}`, {
    manual: true,
    reconnectLimit: 10000,
    onMessage: (e) => {
      const msg = JSON.parse(e.data) as I.PushMessage;
      console.log(msg);
      switch (msg.type) {
        case 'MAKE_CALL': {
          const data = msg.data as { id: I.ID; mobile: string; name: string };
          setWithCall(data.id);
          const path = `/h5/customer/${data.id}`;
          const prePath = location.pathname === path ? '' : location.pathname;
          navigate(`${path}?prePath=${prePath}`);
          break;
        }
      }
    },
  });

  useEffect(() => {
    if (token) {
      ws.current?.connect();
    }
  }, [token]);

  useEffect(() => {
    document.addEventListener('plusready', () => {
      console.log('plusready');
      const plus = (window as any).plus;
      const webview = plus.webview.currentWebview();
      plus.key.addEventListener('backbutton', () => {
        webview.canBack((e: any) => {
          console.log('back', prevPath.current, window.location.pathname);
          // if (prevPath.current === location.pathname) {
          //   // webview.close();
          //   return;
          // }
          // prevPath.current = location.pathname;
          navigate(-1);
        });
      });
    });

    return () => {
      document.removeEventListener('plusready', () => {}, true);
    };
  }, [navigate, location.pathname]);

  useEffect(() => {
    const qs = new URLSearchParams(location.search?.substring(1));
    console.log(qs.get('registerId'));
    const registerId = qs.get('registerId');
    if (registerId) {
      sessionStorage.setItem('registerId', registerId);
    }
  }, [location]);

  useEffect(() => {
    const value = localStorage.getItem('h5_info');
    if (value) {
      const { user } = JSON.parse(value) as { user: { companyId?: Long } };
      setCompanyId(user.companyId);
    } else {
      Toast.show({
        icon: 'fail',
        content: '未登录',
        duration: 1000,
        afterClose() {
          navigate('/h5/login');
        },
      });
    }
  }, [setCompanyId, navigate]);

  const { loading } = useRequest(
    () => {
      return Promise.all([
        loginController.getUser(),
        companyController.getCompany(companyId!),
        companyController.listDepartment(companyId!),
        loginController.getSystemConfig(),
        roleController.list(companyId!),
      ]);
    },
    {
      ready: !!companyId,
      onError: (e) => {
        Toast.show({
          icon: 'fail',
          content: '未登录',
          duration: 1000,
          afterClose() {
            navigate('/h5/login');
          },
        });
      },
      onSuccess: ([user, company, allDepartments, systemConfig, roles]) => {
        const rootDepartmentId = user.departmentId || null;
        // 过滤下 department，只能看到自己所在的区域和下属区域
        const departments = findAllDepartmentId(
          allDepartments,
          rootDepartmentId,
        );
        setProps({
          user,
          company,
          departments,
          systemConfig,
          rootDepartmentId,
          roles,
          allDepartments,
          setTitle,
        });
        Toast.clear();
      },
    },
  );

  useEffect(() => {
    if (loading) {
      Toast.show({
        content: '加载中',
        maskClickable: false,
        duration: 30 * 1000,
      });
    }
  }, [loading]);

  return (
    <div style={{ height: '100%' }}>
      <div style={{ background: '#ace0ff' }}>
        <SafeArea position="top" />
      </div>
      <context.Provider
        value={{
          ...props,
          withCall,
          resetCall: () => {
            setWithCall(undefined);
            console.log('withCall result', withCall);
          },
        }}
      >
        <H5NavBar title={title} />
      </context.Provider>
      <div style={{ background: '#ffcfac' }}>
        <SafeArea position="bottom" />
      </div>
    </div>
  );
}
