import { useRequest } from 'ahooks';
import { Checkbox, FloatButton, Form, Input, Modal, message } from 'antd';
import { useEffect, useState } from 'react';
import { companyMessageController, userFeedbackController } from '../api';
import { MessageOutlined } from '@ant-design/icons';
import { useCompany } from '../hooks/company';
import { CompanyMessage } from '../pages/CompanyMessageList';

export default function AddUserFeedback() {
  const [open, setOpen] = useState<boolean>(false);
  const [form] = Form.useForm<{ anno: boolean; body: string }>();
  const { id: companyId, config, refresh: refreshCompanyConfig } = useCompany();

  const [globalMessageOpen, setGlobalMessageOpen] = useState<boolean>(false);

  const { data: globalMessage, loading: globalCompanyMessageLoading } =
    useRequest(
      () => {
        if (!config?.globalCompanyMessageId) {
          return Promise.resolve(undefined);
        }
        return companyMessageController.getMessage(
          companyId,
          config?.globalCompanyMessageId,
        );
      },
      {
        ready: !!config?.globalCompanyMessageId,
        refreshDeps: [config?.globalCompanyMessageId, companyId],
      },
    );

  useEffect(() => {
    if (globalMessage) {
      const prevSessionId = localStorage.getItem('globalMessageOpen');
      const currentSessionId = sessionStorage.getItem('token');
      const shouldOpen = prevSessionId !== currentSessionId;
      if (currentSessionId) {
        localStorage.setItem('globalMessageOpen', currentSessionId);
      }
      if (shouldOpen) {
        setGlobalMessageOpen(true);
      }
    }
  }, [globalMessage, setGlobalMessageOpen]);

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
  }, [form, open]);

  const { loading, runAsync } = useRequest(
    (body: string, anon: boolean) => {
      return userFeedbackController.create(companyId, body, anon);
    },
    {
      manual: true,
      onSuccess: () => {
        message.success('你的反馈已经提交');
        setOpen(false);
      },
      onError: (e) => {
        message.error(`发生了一些错误：${e.message}`);
      },
    },
  );

  const onSubmit = () => {
    return form.validateFields().then((values) => {
      return runAsync(values.body, values.anno).then(() => {
        setOpen(false);
      });
    });
  };

  return (
    <>
      <FloatButton.Group>
        <FloatButton onClick={() => setOpen(true)} tooltip="我要反馈" />
        <FloatButton
          onClick={() => {
            refreshCompanyConfig();
            setGlobalMessageOpen(true);
          }}
          tooltip="重要消息"
          icon={<MessageOutlined />}
        />
      </FloatButton.Group>
      {globalMessage && (
        <CompanyMessage
          dto={globalMessage}
          open={globalMessageOpen}
          onCancel={() => setGlobalMessageOpen(false)}
          delayClose={10 * 1000}
        />
      )}

      <Modal
        confirmLoading={loading}
        open={open}
        onCancel={() => setOpen(false)}
        title="我要反馈"
        onOk={onSubmit}
      >
        <Form form={form} initialValues={{ anno: true }}>
          <Form.Item name="body" label="内容" rules={[{ required: true }]}>
            <Input.TextArea rows={5} />
          </Form.Item>
          <Form.Item name="anno" label="匿名" valuePropName="checked">
            <Checkbox />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
