import { useRequest } from 'ahooks';
import {
  Button,
  Col,
  DatePicker,
  Rate,
  Row,
  Select,
  Space,
  Statistic,
  Table,
  Tag,
  TagProps,
  Tooltip,
} from 'antd';
import { useCompany } from '../../hooks/company';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  ChannelAnalyzeDTO,
  CustomerFromEnum,
  LoanChannelAnalyzeDTO,
  dataAnalyzeController,
} from '../../api';
import dayjs from 'dayjs';
import { createContext, useContext, useMemo, useState } from 'react';
import { ColumnType } from 'antd/es/table';
import DepartmentSelect from '../../components/DepartmentSelect';
import ResizeTable from '../../components/ResizeTable';

import {
  findAllDepartmentId,
  formatEndTime,
  formatStartTime,
} from '../../utils';
import DateRangePicker from '../../components/DateRangePicker';
import { ColumnFilterItem } from 'antd/es/table/interface';
import '../../styles/common.scss';
import FromSelect from '../../components/FromSelect';
import { Link, useNavigate } from 'react-router-dom';
import { useAliveController } from 'react-activation';
import { ExpandAltOutlined } from '@ant-design/icons';
import { setLoainListSessionQuery } from '../customer/LoanList';

const queryContext = createContext<{
  createdAt?: [dayjs.Dayjs, dayjs.Dayjs];
  depId?: I.ID;
}>({});

const RateStar = ({
  value,
  dto,
  color,
}: {
  value: number;
  dto: Pick<
    ChannelAnalyzeDTO,
    'star0' | 'star1' | 'star2' | 'star3' | 'star4' | 'star5'
  >;
  color?: TagProps['color'];
}) => {
  const total =
    Number(dto.star0) +
    Number(dto.star1) +
    Number(dto.star2) +
    Number(dto.star3) +
    Number(dto.star4) +
    Number(dto.star5);

  return (
    <Space align="center">
      <span style={{}}>{value}</span>
      <div style={{}}>
        <Tag color={color || 'blue'}>
          {total ? `${((value * 100) / total).toFixed(2)}%` : ''}
        </Tag>
      </div>
    </Space>
  );
};

const LoanStatistical = ({ loan }: { loan: LoanChannelAnalyzeDTO }) => {
  const { drop } = useAliveController();

  const { createdAt: date, depId } = useContext(queryContext);

  const { id: companyId } = useCompany();

  const navigate = useNavigate();

  if (!loan) {
    return <span>-</span>;
  }
  return (
    <Space>
      <Statistic
        value={Number(loan.loanAmount) / 100}
        prefix="¥"
        valueStyle={{ fontSize: '14px' }}
      />
      <span>(</span>
      <Statistic
        value={Number(loan.loanBrokerage) / 100}
        prefix="¥"
        valueStyle={{ fontSize: '14px' }}
      />
      <span>)</span>
      <ExpandAltOutlined
        onClick={() => {
          setLoainListSessionQuery({
            channel: loan.channel,
            loanedAtBegin: date?.[0] && formatStartTime(date[0]),
            loanedAtEnd: date?.[1] && formatEndTime(date[1]),
            departmentId: depId,
          });
          drop('loan-list').then(() => {
            navigate(`/${companyId}/analyse/loan-list`);
          });
        }}
      />
    </Space>
  );
};

export default () => {
  const { id: companyId, departments, hasPermission } = useCompany();
  const defaultCreatedAt: [dayjs.Dayjs, dayjs.Dayjs] = [
    dayjs().startOf('month'),
    dayjs().endOf('month'),
  ];
  const [departmentId, setDepartmentId] = useState<I.ID | null>(null);
  const [createdAt, setCreatedAt] =
    useState<[dayjs.Dayjs, dayjs.Dayjs]>(defaultCreatedAt);

  const departmentIds = useMemo(() => {
    return findAllDepartmentId(departments, departmentId).map(
      (item) => item.id,
    );
  }, [departmentId, departments]);

  const [fromList, setFromList] = useState<CustomerFromEnum>();

  const {
    data: rawData,
    loading,
    refresh,
  } = useRequest(
    () => {
      return dataAnalyzeController.channelAnalyze(companyId, {
        start: formatStartTime(createdAt[0]),
        end: formatEndTime(createdAt[1]),
        depIds: departmentIds,
        fromEnums: fromList,
      });
    },
    {
      refreshDeps: [companyId, createdAt, departmentIds, fromList],
    },
  );

  const data = useMemo(() => {
    return rawData;
  }, [rawData]);

  const channelFilter: ColumnFilterItem[] = useMemo(() => {
    return (
      data
        ?.map((item) => ({
          text: item.channel || '',
          value: item.channel || '',
        }))
        .filter((item) => item.text.length) || []
    );
  }, [data]);

  const columns: ColumnType<ChannelAnalyzeDTO>[] = [
    {
      dataIndex: 'channel',
      title: (
        <Tooltip title="只统计数据源为 RMS 的数据">
          <Space>
            <InfoCircleOutlined />
            <span>渠道</span>
          </Space>
        </Tooltip>
      ),
      filters: channelFilter,
      filterSearch: true,
      filtered: true,
      onFilter: (value: string | number | boolean, record) =>
        record.channel === value,
      align: 'left',
      width: 90,
    },
    {
      dataIndex: 'cnt',
      title: '客户总数',
      sorter: (a, b) => (a.cnt > b.cnt ? 1 : -1),
      align: 'right',
      width: 100,
    },
    {
      dataIndex: 'stateVisitedCount',
      title: '已上门',
      sorter: (a, b) => (a.stateVisitedCount > b.stateVisitedCount ? 1 : -1),
      align: 'right',
      width: 80,
    },
    {
      dataIndex: 'stateSignedCount',
      sorter: (a, b) => (a.stateSignedCount > b.stateSignedCount ? 1 : -1),
      title: '已签约',
      align: 'right',
      width: 80,
    },
    {
      dataIndex: 'loan',
      title: '已放款',
      sorter: (a, b) =>
        (a.loan?.customerCount || 0) > (b.loan?.customerCount || 0) ? 1 : -1,
      render: (loan: LoanChannelAnalyzeDTO) => {
        return <span>{loan?.customerCount || 0}</span>;
      },
      align: 'right',
      width: 80,
    },
    {
      dataIndex: 'loan',
      title: '放款额度（佣金）',
      width: 130,
      sorter: (a, b) =>
        (a.loan?.loanAmount || 0) > (b.loan?.loanAmount || 0) ? 1 : -1,
      render: (loan: LoanChannelAnalyzeDTO) => {
        return <LoanStatistical loan={loan} />;
      },
      align: 'right',
    },
    {
      dataIndex: 'star5',
      sorter: (a, b) => (a.star5 > b.star5 ? 1 : -1),
      title: <Rate disabled value={5} count={5} style={{ fontSize: '12px' }} />,
      render: (value: number, dto) => {
        return <RateStar value={value} dto={dto} />;
      },
      align: 'right',
      width: 110,
    },
    {
      dataIndex: 'star4',
      sorter: (a, b) => (a.star4 > b.star4 ? 1 : -1),
      title: <Rate disabled value={4} count={4} style={{ fontSize: '12px' }} />,
      render: (value: number, dto) => {
        return <RateStar value={value} dto={dto} />;
      },
      align: 'right',
      width: 95,
    },
    {
      dataIndex: 'star3',
      sorter: (a, b) => (a.star3 > b.star3 ? 1 : -1),
      title: <Rate disabled value={3} count={3} style={{ fontSize: '12px' }} />,
      render: (value: number, dto) => {
        return <RateStar value={value} dto={dto} />;
      },
      align: 'right',
      width: 80,
    },
    {
      dataIndex: 'star2',
      sorter: (a, b) => (a.star2 > b.star2 ? 1 : -1),
      title: <Rate disabled value={2} count={2} style={{ fontSize: '12px' }} />,
      render: (value: number, dto) => {
        return <RateStar value={value} dto={dto} />;
      },
      align: 'right',
      width: 65,
    },
    {
      dataIndex: 'star1',
      align: 'right',
      sorter: (a, b) => (a.star1 > b.star1 ? 1 : -1),
      title: <Rate disabled value={1} count={1} style={{ fontSize: '12px' }} />,
      render: (value: number, dto) => {
        return <RateStar value={value} dto={dto} />;
      },
      width: 50,
    },
    {
      dataIndex: 'star0',
      sorter: (a, b) => (a.star0 > b.star0 ? 1 : -1),
      title: <Rate disabled value={0} count={0} style={{ fontSize: '12px' }} />,
      align: 'right',
      render: (value: number, dto) => {
        return <RateStar value={value} dto={dto} />;
      },
      width: 50,
    },
    {
      title: (
        <Tooltip title="3 星以及以上为优质">
          <Space>
            <InfoCircleOutlined />
            <span>优质率</span>
          </Space>
        </Tooltip>
      ),
      width: 100,
      align: 'right',
      sorter: (a, b) => {
        const cal = (dto: ChannelAnalyzeDTO) => {
          const total =
            Number(dto.star0) +
            Number(dto.star1) +
            Number(dto.star2) +
            Number(dto.star3) +
            Number(dto.star4) +
            Number(dto.star5);
          return (
            ((Number(dto.star3) + Number(dto.star4) + Number(dto.star5)) *
              100) /
            total
          );
        };
        return cal(a) > cal(b) ? 1 : -1;
      },
      render: (_, dto) => {
        return (
          <RateStar
            color="yellow"
            value={Number(dto.star3) + Number(dto.star4) + Number(dto.star5)}
            dto={dto}
          />
        );
      },
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Space>
          {hasPermission('page:report:channel:source-select') && (
            <FromSelect value={fromList} onChange={(v) => setFromList(v)} />
          )}
          <DepartmentSelect
            style={{ width: '300px' }}
            value={departmentId}
            onSelect={(v) => {
              setDepartmentId(v);
              // refresh();
            }}
            onClear={() => setDepartmentId(null)}
          />
          <DateRangePicker
            disabled={loading}
            value={createdAt}
            onChange={(v) => {
              setCreatedAt(
                (v as [dayjs.Dayjs, dayjs.Dayjs]) || defaultCreatedAt,
              );
            }}
          />
          <Button type="primary" loading={loading} onClick={refresh}>
            查询
          </Button>
        </Space>
      </Col>
      <Col span={24} style={{ marginTop: '20px' }}>
        <queryContext.Provider
          value={{ createdAt, depId: departmentId || undefined }}
        >
          <ResizeTable
            className="normal-table"
            size="small"
            bordered
            summary={(list) => {
              if (list.length === 0) {
                return null;
              }
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell key={0} index={0}>
                      合计
                    </Table.Summary.Cell>
                    <Table.Summary.Cell key={1} index={1} align="right">
                      {list.reduce((pre, cur) => pre + Number(cur.cnt), 0)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell key={2} index={2} align="right">
                      {/* 上门 */}
                      {list.reduce(
                        (pre, cur) => pre + Number(cur.stateVisitedCount),
                        0,
                      )}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell key={3} index={3} align="right">
                      {/* 签约 */}
                      {list.reduce(
                        (pre, cur) => pre + Number(cur.stateSignedCount),
                        0,
                      )}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell key={4} index={4} align="right">
                      {/* 已放款 */}
                      {list.reduce(
                        (pre, cur) =>
                          pre + Number(cur.loan?.customerCount || 0),
                        0,
                      )}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell key={5} index={5} align="right">
                      <Space>
                        <Statistic
                          value={
                            list.reduce(
                              (pre, cur) =>
                                pre + Number(cur.loan?.loanAmount || 0),
                              0,
                            ) / 100
                          }
                          prefix="¥"
                          valueStyle={{ fontSize: '14px' }}
                        />
                        <span>(</span>
                        <Statistic
                          value={
                            list.reduce(
                              (pre, cur) =>
                                pre + Number(cur.loan?.loanBrokerage || 0),
                              0,
                            ) / 100
                          }
                          prefix="¥"
                          valueStyle={{ fontSize: '14px' }}
                        />
                        <span>)</span>
                      </Space>
                    </Table.Summary.Cell>
                    {[5, 4, 3, 2, 1, 0].map((star, idx) => {
                      return (
                        <Table.Summary.Cell
                          key={star}
                          index={idx + 6}
                          align="right"
                        >
                          <RateStar
                            value={list.reduce(
                              // @ts-ignore
                              (pre, cur) =>
                                pre + Number(cur[`star${star}`] || 0),
                              0,
                            )}
                            dto={list.reduce(
                              (prev, cur) => {
                                return {
                                  star0: Number(prev.star0) + Number(cur.star0),
                                  star1: Number(prev.star1) + Number(cur.star1),
                                  star2: Number(prev.star2) + Number(cur.star2),
                                  star3: Number(prev.star3) + Number(cur.star3),
                                  star4: Number(prev.star4) + Number(cur.star4),
                                  star5: Number(prev.star5) + Number(cur.star5),
                                };
                              },
                              {
                                star0: 0,
                                star1: 0,
                                star2: 0,
                                star3: 0,
                                star4: 0,
                                star5: 0,
                              },
                            )}
                          />
                        </Table.Summary.Cell>
                      );
                    })}
                    <Table.Summary.Cell key={12} index={12} align="right">
                      <RateStar
                        color="yellow"
                        value={list.reduce((prev, cur) => {
                          return (
                            prev +
                            Number(cur.star3) +
                            Number(cur.star4) +
                            Number(cur.star5)
                          );
                        }, 0)}
                        dto={list.reduce(
                          (prev, cur) => {
                            return {
                              star0: Number(prev.star0) + Number(cur.star0),
                              star1: Number(prev.star1) + Number(cur.star1),
                              star2: Number(prev.star2) + Number(cur.star2),
                              star3: Number(prev.star3) + Number(cur.star3),
                              star4: Number(prev.star4) + Number(cur.star4),
                              star5: Number(prev.star5) + Number(cur.star5),
                            };
                          },
                          {
                            star0: 0,
                            star1: 0,
                            star2: 0,
                            star3: 0,
                            star4: 0,
                            star5: 0,
                          },
                        )}
                      />
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
            loading={loading}
            pagination={false}
            columns={columns}
            dataSource={data}
            rowKey={'channel'}
          />
        </queryContext.Provider>
      </Col>
    </Row>
  );
};
