import { useRequest } from 'ahooks';
import { listDepartment } from '../apis/api';
import { useCompany } from '../hooks/company';
import { TreeSelect, TreeSelectProps } from 'antd';
import { BaseDepartmentDTO } from '../api';
import { useMemo } from 'react';
import { sortName } from '../utils';

interface TreeData {
  key: I.ID;
  title: string;
  value: I.ID;
  children?: TreeData[];
}

export const buildTree = (
  list: BaseDepartmentDTO[],
  rootID: I.ID | null,
): TreeData[] | undefined => {
  if (!list?.length) {
    return undefined;
  }
  const root = list.filter(({ parentId }) => parentId === rootID);

  return root
    .sort((a, b) => sortName(a.name, b.name))
    .map((item) => {
      return {
        key: item.id,
        title: item.name,
        value: item.id,
        children: buildTree(list, item.id),
      };
    });
};

export default ({ ...props }: TreeSelectProps) => {
  const { departments, allDepartments, rootDepartmentId } = useCompany();

  const tree = useMemo(() => {
    const children = buildTree(allDepartments, rootDepartmentId)?.sort((a, b) =>
      sortName(a.title, b.title),
    );
    if (props.value === rootDepartmentId) {
      // 说明它是根区域
      const dep = allDepartments.find((item) => item.id === rootDepartmentId);
      if (dep) {
        return [
          {
            key: dep.id,
            title: dep.name,
            value: dep.id,
            children,
          },
        ];
      }
    }
    return children;
  }, [allDepartments, rootDepartmentId, props.value]);

  const treeData = useMemo(() => {
    if (!!tree?.length) {
      return tree;
    }

    // 如果当前区域为空，就把自己当前的区域给加上去
    const currentDepartment = departments.find(
      (item) => item.id === rootDepartmentId,
    );
    if (!currentDepartment) {
      return [];
    }

    return [
      {
        key: currentDepartment?.id,
        title: currentDepartment.name,
        value: currentDepartment.id,
      },
    ];
  }, [tree, departments, rootDepartmentId]);

  return (
    <TreeSelect
      {...props}
      showSearch
      allowClear
      disabled={!tree?.length || props.disabled}
      treeDefaultExpandAll
      placeholder="选择区域"
      treeData={treeData}
    />
  );
};
