import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Rate,
  Row,
  Select,
  Space,
  message,
} from 'antd';
import { useEffect, useState } from 'react';
import CustomerStateSelect from './CustomerStateSelect';
import { customerGroupMapping, extraKeys } from '../constants';
import {
  AddCustomerDTO,
  CustomerEventTypeEnum,
  CustomerGroupEnum,
  CustomerStateEnum,
  customerController,
} from '../api';
import StarTips from './StarTips';
import StateTips from './StateTips';
import { useRequest } from 'ahooks';
import { useCompany } from '../hooks/company';

export default () => {
  const { id: companyId } = useCompany();
  const [open, setOpen] = useState<boolean>(false);
  const [form] = Form.useForm<{
    name: string;
    mobile: string;
    star: number;
    state: CustomerStateEnum;
    group: CustomerGroupEnum;
    extra: string[];
    remark: string;
    comment?: string;
  }>();

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
  }, [open]);

  const { runAsync: addCustomer, loading } = useRequest(
    (params: AddCustomerDTO) => {
      return customerController.createCustomer(companyId, params);
    },
    {
      manual: true,
      onSuccess: () => {
        message.success('添加成功');
      },
      onError: (e) => {
        message.error(`添加失败：${e.message}`);
      },
    },
  );

  const { runAsync: addEvent, loading: addEventLoading } = useRequest(
    (id: I.ID, comment: string) => {
      return customerController.addEvent(
        companyId,
        id,
        CustomerEventTypeEnum.COMMENT,
        comment,
        '添加客户',
      );
    },
    {
      manual: true,
      onSuccess: () => {
        message.success('跟进成功');
      },
      onError: (e) => {
        setOpen(false);
        message.error(`跟进失败：${e.message}`);
      },
    },
  );

  const onSubmit = () => {
    return form
      .validateFields()
      .then((values) => {
        const params: AddCustomerDTO = {
          ...values,
        };
        values.extra?.map((key: string) => {
          (params as any)[key] = true;
        });
        return addCustomer(params).then((customer) => {
          if (!values?.comment) {
            return Promise.resolve();
          }
          return addEvent(customer.id, values?.comment).then(() => {});
        });
      })
      .then(() => {
        setOpen(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <Modal
        open={open}
        confirmLoading={loading}
        title="添加客户"
        onCancel={() => setOpen(false)}
        onOk={onSubmit}
      >
        <Form
          autoComplete="off"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          form={form}
          initialValues={{
            group: CustomerGroupEnum.NEW_CUSTOMER,
            state: CustomerStateEnum.WAIT_FOLLOW_UP,
            star: 0,
          }}
        >
          <Form.Item label="客户姓名" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="客户电话"
            name="mobile"
            rules={[
              { required: true },
              {
                pattern: /^1[0-9]{10}$/,
                message: '请输入正确的手机号',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={
              <Space>
                <span>星级</span>
                <StarTips />
              </Space>
            }
            name="star"
          >
            <Rate count={5} allowHalf={false} />
          </Form.Item>
          <Form.Item
            label={
              <Space>
                <span>状态</span>
                <StateTips />
              </Space>
            }
            name="state"
          >
            <CustomerStateSelect />
          </Form.Item>
          <Form.Item label="分组" name="group">
            <Select
              options={Object.entries(customerGroupMapping).map((group) => ({
                label: group[1],
                value: group[0],
              }))}
            />
          </Form.Item>
          <Form.Item label="其他资质" name="remark">
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item name="comment" label="跟进">
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item name="extra" label="附属信息">
            <Checkbox.Group
              options={Object.entries(extraKeys).map(([value, label]) => ({
                value,
                label,
              }))}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Button type="primary" onClick={() => setOpen(true)}>
        添加客户
      </Button>
    </>
  );
};
