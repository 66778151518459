import { Button, Grid, Input, Space, Toast } from 'antd-mobile';

import './login.css';
import { useRequest } from 'ahooks';
import { loginController } from '../../api';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default () => {
  const [mobile, setMobile] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const registerId = (mask: boolean) => {
    const value = sessionStorage.getItem('registerId');
    if (!value) {
      return undefined;
    }
    if (mask) {
      return value.replace(/^\w{4}(.*)/, '****$1');
    }
    return value;
  };

  const navigate = useNavigate();

  const { run: login, loading } = useRequest(
    () => {
      return loginController
        .loginApp({
          mobile,
          password,
          registerId: registerId(false)!,
        })
        .then(({ user, token }) => {
          localStorage.setItem(
            'h5_info',
            JSON.stringify({
              user,
              token,
            }),
          );
          Toast.show({
            icon: 'success',
            content: '登录成功',
            duration: 500,
            afterClose: () => {
              navigate(`/h5`);
            },
          });
        });
    },
    {
      manual: true,
      onError: (e) => {
        Toast.show({
          icon: 'fail',
          content: `登录失败：${e.message}`,
        });
      },
    },
  );

  const canLogin = mobile.length && password.length;
  return (
    <>
      <div className="mobile-login">
        <div className="mobile-login-field">
          <Input
            placeholder="账号"
            value={mobile}
            onChange={(v) => setMobile(v)}
          />
        </div>
        <div className="mobile-login-field">
          <Input
            placeholder="密码"
            type="password"
            onChange={(v) => setPassword(v)}
            value={password}
          />
        </div>
        <div className="mobile-login-field">
          <Space>
            <span>设备码</span>
            <span>{registerId(true)}</span>
          </Space>
        </div>
        <div className="mobile-login-field">
          <Button
            onClick={() => {
              return login();
            }}
            color="primary"
            disabled={!canLogin}
            loading={loading}
            style={{ width: '100%' }}
          >
            登录
          </Button>
        </div>
      </div>
    </>
  );
};
