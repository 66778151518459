import { useRequest } from 'ahooks';
import { Button, Popconfirm, message } from 'antd';
import { useCompany } from '../hooks/company';
import { customerController } from '../api';

interface Props {
  targets: { id: I.ID }[];
  onSuccess: () => void;
}

export default ({ targets, onSuccess }: Props) => {
  const { id: companyId } = useCompany();
  const { loading, runAsync } = useRequest(
    () => {
      return customerController.dropToPublicBatch(
        companyId,
        targets.map((item) => item.id),
      );
    },
    {
      manual: true,
      onSuccess: () => {
        message.success('抛入公共池成功');
      },
      onError: (e) => {
        message.error(e.message || '抛入公共池失败');
      },
    },
  );
  return (
    <Popconfirm
      title="确认抛入公共池"
      onConfirm={() => {
        runAsync().then(() => {
          onSuccess();
        });
      }}
    >
      <Button type="primary" disabled={!targets.length} loading={loading}>
        抛入公共池
      </Button>
    </Popconfirm>
  );
};
