import { useRequest } from 'ahooks';
import { Select, SelectProps } from 'antd';
import { customerController } from '../api';
import { useCompany } from '../hooks/company';
import { useEffect, useState } from 'react';

export default (props: SelectProps) => {
  const { id: comapnyId } = useCompany();

  const [channel, setChannel] = useState<string[]>();

  const { data, loading } = useRequest(
    () => {
      return customerController
        .listChannel(comapnyId)
        .then((v) => v.filter((item) => !!item));
    },
    {
      refreshDeps: [comapnyId],
    },
  );

  useEffect(() => {
    setChannel(data);
  }, [data]);

  return (
    <Select
      {...props}
      loading={loading}
      placeholder="渠道来源"
      allowClear
      showSearch
      onSearch={(v) => {
        if (!v) {
          setChannel(data);
        } else {
          setChannel(
            data?.filter((c) => c.toLowerCase().startsWith(v.toLowerCase())),
          );
        }
      }}
      options={channel?.map((item) => {
        return {
          label: item,
          value: item,
        };
      })}
    />
  );
};
