import { useRequest } from 'ahooks';
import { systemController } from '../api';
import { Select, SelectProps } from 'antd';
import { useCompany } from '../hooks/company';

export default ({ ...props }: SelectProps) => {
  const { id } = useCompany();
  const {
    data: companies,
    refresh: refreshCompany,
    loading: companyListLoading,
  } = useRequest(() => {
    return systemController.listCompany();
  }, {});
  return (
    <Select
      {...props}
      loading={props.loading || companyListLoading}
      options={companies?.map((item) => ({
        label: item.name,
        value: item.id,
        disabled: item.id === id,
      }))}
    />
  );
};
