import { useRequest } from 'ahooks';
import { List, Button } from 'antd';
import { companyController, CompanyListItem, systemController } from '../api';

export default ({ showAll }: { showAll?: boolean }) => {
  const { loading, data } = useRequest(() => {
    return companyController.getCompanyList('1');
  });

  const { loading: goLoading, runAsync: go } = useRequest(
    (company: CompanyListItem) => {
      return systemController
        .generateCompanyLoginToken(company.id)
        .then((result) => {
          const qs = new URLSearchParams();
          qs.set('token', result.token);
          qs.set('timestamp', result.timestamp);
          qs.set('nonce', result.nonce);
          qs.set('mobile', result.mobile);
          qs.set('companyId', company.id.toString());

          return `${company.url}/login_with_token?${qs.toString()}`;
        });
    },
    {
      manual: true,
      onSuccess: (result) => {
        window.location.href = result;
      },
    },
  );

  return (
    <List loading={loading}>
      {data?.map((company) => {
        return (
          <List.Item
            key={company.url}
            extra={
              <Button
                disabled={
                  window.location.href.startsWith(
                    `${company.url}/${company.id}`,
                  ) && !showAll
                }
                type="primary"
                loading={goLoading}
                onClick={() => {
                  if (window.location.href.startsWith(`${company.url}`)) {
                    window.location.href = `/${company.id}/welcome`; // 同一个域名直接跳
                  } else {
                    go(company);
                  }
                }}
              >
                选择
              </Button>
            }
          >
            {company.name}
          </List.Item>
        );
      })}
    </List>
  );
};
