import { useRequest } from 'ahooks';
import { Spin } from 'antd';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { loginController } from '../../api';

export default () => {
  const location = useLocation();

  const navigate = useNavigate();

  const { loading } = useRequest(
    () => {
      const qs = new URLSearchParams(location.search);
      return loginController
        .loginWithCompanyToken(
          qs.get('token') || '',
          qs.get('nonce') || '',
          qs.get('timestamp') || '',
          qs.get('mobile') || '',
        )
        .then((result) => {
          return {
            token: result.token,
            companyId: qs.get('companyId') || '',
          };
        });
    },
    {
      refreshDeps: [location],
      onSuccess: (result) => {
        console.log(result);
        sessionStorage.setItem('token', result.token);
        navigate(`/${result.companyId}/welcome`);
      },
    },
  );

  return <Spin spinning={loading} tip="登录中" />;
};
