import { useRequest } from 'ahooks';
import { useCompany } from '../../hooks/company';
import {
  PlayCircleOutlined,
  DeleteOutlined,
  InboxOutlined,
} from '@ant-design/icons';
import {
  ActionType,
  ProColumnType,
  ProColumns,
  ProTable,
} from '@ant-design/pro-components';
import {
  AddCompanyStudyMediaDTO,
  CompanyStudyMediaTypeEnum,
  ListCompanyStudyMediaItemDTO,
  companyStudyController,
  oSSController,
} from '../../api';

import dayjs from 'dayjs';
import {
  AutoComplete,
  AutoCompleteProps,
  Button,
  ButtonProps,
  Modal,
  Popconfirm,
  Select,
  SelectProps,
  Space,
  Spin,
  Statistic,
  Tag,
  Tooltip,
  Upload,
  UploadFile,
  UploadProps,
  message,
} from 'antd';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import '../../styles/common.scss';
import './studylist.css';
import Transfer from './Transfer';

import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(isoWeek);

const context = createContext<{
  setPlayMedia: (dto: ListCompanyStudyMediaItemDTO) => void;
  refresh: () => void;
  editorPermission?: boolean;
}>({
  setPlayMedia() {},
  refresh() {},
});

const PlayModal = ({
  open,
  onCancel,
  media,
}: {
  open?: boolean;
  media: ListCompanyStudyMediaItemDTO;
  onCancel: () => void;
}) => {
  const { id: companyId } = useCompany();

  const { data: url, loading } = useRequest(() => {
    return oSSController.getOSSUrl(companyId, media.ossKey);
  }, {});

  const { run: play } = useRequest(
    () => {
      return companyStudyController.play(companyId, media.id);
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    play();
  }, [media]);

  return (
    <Modal
      title={media.name}
      onCancel={onCancel}
      maskClosable={false}
      onOk={onCancel}
      footer={[]}
      open={open}
      width="800px"
      bodyStyle={{ paddingTop: '20px' }}
    >
      <p>{media.description}</p>
      <Spin spinning={loading}>
        {media.type === CompanyStudyMediaTypeEnum.AUDIO && (
          <audio
            style={{ width: '100%' }}
            controls
            autoPlay
            controlsList="nodownload"
            src={url}
          />
        )}
        {media.type === CompanyStudyMediaTypeEnum.VIDEO && (
          <video
            style={{ width: '100%', height: '400px' }}
            autoPlay
            controls
            controlsList="nodownload"
            src={url}
          />
        )}
      </Spin>
    </Modal>
  );
};

const CategorySelect = (props: SelectProps) => {
  const { id: companyId } = useCompany();
  const { data, loading } = useRequest(
    () => {
      return companyStudyController.listCategory(companyId);
    },
    {
      refreshDeps: [companyId],
    },
  );
  return (
    <Select
      {...props}
      loading={loading}
      options={data?.map((item) => ({
        label: item,
        value: item,
      }))}
    />
  );
};

const CategoryAutoComplete = ({
  value: defaultValue,
  ...props
}: AutoCompleteProps) => {
  const { id: companyId } = useCompany();
  const [options, setOptions] = useState<string[]>([]);
  const { data, loading } = useRequest(
    () => {
      return companyStudyController.listCategory(companyId);
    },
    {
      refreshDeps: [companyId],
    },
  );
  useEffect(() => {
    setOptions(data || []);
  }, [data]);
  return (
    <AutoComplete
      {...props}
      allowClear
      defaultValue={defaultValue}
      onSearch={(value) => {
        if (value) {
          setOptions(data?.filter((item) => item.includes(value)) || []);
        } else {
          setOptions(data || []);
        }
      }}
      options={options?.map((item) => ({
        label: item,
        value: item,
      }))}
    />
  );
};

const renderKey = async (file: UploadFile) => {
  const now = dayjs();
  const array = new Uint32Array(128);
  window.crypto.getRandomValues(array);
  const randText = array.join('');
  const message = `${now.format('YYYYMMDD')}/${randText}${file.name}${
    file.uid
  }${+new Date()}`;

  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join(''); // convert bytes to hex string
  return hashHex;
};

const UploadMedia = ({ refresh }: { refresh: () => void }) => {
  const { id: companyId } = useCompany();

  const [open, setOpen] = useState<boolean>();
  const [file, setFile] = useState<UploadFile>();
  const [uploadData, setUploadData] = useState<AddCompanyStudyMediaDTO>({
    name: '',
    category: '',
    ossKey: '',
    size: 0,
    type: CompanyStudyMediaTypeEnum.AUDIO,
  });

  useEffect(() => {
    setFile(undefined);
    setUploadData({
      name: '',
      category: '',
      ossKey: '',
      size: 0,
      type: CompanyStudyMediaTypeEnum.AUDIO,
    });
  }, [open]);

  const [uploadLoading, setUploadLoading] = useState<boolean>();

  const { data: uploadInfo, loading } = useRequest(
    () => {
      return oSSController.getOSSDirectUploadToken(companyId);
    },
    {
      refreshDeps: [open, companyId],
    },
  );

  const { run: createMedia, loading: createLoading } = useRequest(
    () => {
      return companyStudyController.create(companyId, uploadData);
    },
    {
      manual: true,
      onSuccess: () => {
        message.success('上传成功');
        setOpen(false);
        refresh();
      },
    },
  );

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    accept: 'video/mp4,audio/mp3,audio/amr',
    action: uploadInfo?.host,
    maxCount: 1,
    data: async (file) => {
      const ext = file.name.split('.').pop();
      const key = `${uploadInfo?.dir}${dayjs().format(
        'YYYYMMDD',
      )}/${await renderKey(file)}${ext ? `.${ext}` : ''}`;
      setUploadData((prev) => ({
        ...prev,
        ossKey: key,
      }));
      return {
        key,
        OSSAccessKeyId: uploadInfo?.accessKey,
        policy: uploadInfo?.policy,
        signature: uploadInfo?.signature,
      };
    },
    onChange: (info) => {
      setUploadLoading(true);
      console.log(info.file);
      if (info.file.status === 'done') {
        setUploadLoading(undefined);
        setUploadData({
          ...uploadData,
          name: info.file.name,
          size: info.file.size || 0,
          type: info.file.type?.startsWith('video')
            ? CompanyStudyMediaTypeEnum.VIDEO
            : CompanyStudyMediaTypeEnum.AUDIO,
        });
        setFile(info.file);
      } else if (info.file.status === 'removed') {
        setFile(undefined);
        setUploadLoading(undefined);
      }
    },
  };
  return (
    <>
      <Modal
        confirmLoading={uploadLoading || createLoading}
        closable={!uploadLoading}
        destroyOnClose
        cancelButtonProps={{ loading: uploadLoading }}
        okButtonProps={{
          loading: uploadLoading,
          disabled: !file || !uploadData.category,
        }}
        onOk={() => {
          createMedia();
        }}
        open={open}
        onCancel={() => setOpen(false)}
        maskClosable={false}
        title="上传文件"
      >
        <Spin spinning={loading}>
          <Space direction="vertical">
            <Upload {...props} disabled={uploadLoading}>
              <Button loading={uploadLoading} disabled={!!file}>
                选择文件
              </Button>
            </Upload>
            <Space>
              <span className="study-uploader-label">分类</span>
              <CategoryAutoComplete
                className="study-uploader-field"
                value={uploadData?.category}
                onChange={(v: string) => {
                  setUploadData((prev) => ({ ...prev, category: v }));
                }}
              />
            </Space>
          </Space>
        </Spin>
      </Modal>
      <Button type="primary" onClick={() => setOpen(true)}>
        上传
      </Button>
    </>
  );
};

const RemoveButton = ({
  mediaId,
  ...props
}: ButtonProps & { mediaId: I.ID }) => {
  const { id: companyId } = useCompany();
  const { refresh } = useContext(context);
  const { run: remove, loading } = useRequest(
    () => {
      return companyStudyController.remove(companyId, mediaId);
    },
    {
      manual: true,
      onSuccess: () => {
        message.success('删除成功');
        refresh();
      },
    },
  );
  return (
    <Popconfirm title="确认删除" onConfirm={() => remove()}>
      <Button
        {...props}
        loading={loading || props.loading}
        icon={<DeleteOutlined />}
        danger
        type="primary"
        shape="circle"
        size="small"
      />
    </Popconfirm>
  );
};

const WatchButton = ({ dto }: { dto: ListCompanyStudyMediaItemDTO }) => {
  const { setPlayMedia } = useContext(context);
  const { config, currentUser, hasPermission } = useCompany();
  const canWatch = useMemo(() => {
    if (currentUser.isSuperAdmin) {
      return true;
    }
    if (hasPermission('page:study:editor')) {
      return true;
    }
    // 检查用户创建时间是否是 7 天以内
    const now = dayjs();
    if (now.diff(dayjs(currentUser.createdAt), 'day') < 7) {
      return true;
    }

    // 得到今天是第几周
    const weekday = now.isoWeekday() - 1;

    const studyTime = config?.studyTime?.[weekday];

    if (!studyTime?.enable) {
      return false;
    }

    const start = dayjs()
      .set('hour', Number(studyTime.start.split(':')[0]))
      .set('minute', Number(studyTime.start.split(':')[1]));

    const end = dayjs()
      .set('hour', Number(studyTime.end.split(':')[0]))
      .set('minute', Number(studyTime.end.split(':')[1]));

    if (now.isAfter(start) && now.isBefore(end)) {
      return true;
    }

    return false;
  }, [
    config?.studyTime,
    currentUser.createdAt,
    currentUser.isSuperAdmin,
    hasPermission,
  ]);

  const message = useMemo(() => {
    if (canWatch) {
      return undefined;
    }
    const avaiableTime = config?.studyTime?.map((item, idx) => {
      if (!item.enable) {
        return undefined;
      }
      const weeks = ['一', '二', '三', '四', '五', '六', '日'];
      return `星期${weeks[idx]} ${item.start} - ${item.end}`;
    });
    return `现在不是学习时间，请在 ${avaiableTime
      ?.filter((item) => !!item)
      .join('|')} 时间段内学习`;
  }, [canWatch, config?.studyTime]);

  return (
    <Tooltip title={message}>
      <Button
        onClick={() => {
          setPlayMedia(dto);
        }}
        disabled={!canWatch}
        icon={<PlayCircleOutlined />}
        shape="circle"
        size="small"
        type="primary"
      />
    </Tooltip>
  );
};

const columns: ProColumns<ListCompanyStudyMediaItemDTO>[] = [
  {
    dataIndex: 'id',
    hideInForm: true,
    hideInSearch: true,
  },
  {
    title: '名称',
    dataIndex: 'name',
    hideInSearch: true,
    hideInForm: true,
  },
  {
    title: '分类',
    dataIndex: 'category',
    renderFormItem() {
      return <CategorySelect allowClear />;
    },
  },
  {
    title: '类型',
    dataIndex: 'type',
    hideInSearch: true,
    hideInForm: true,
    render: (_, dto) => {
      return (
        <Tag>
          {dto.type === CompanyStudyMediaTypeEnum.AUDIO ? '音频' : '视频'}
        </Tag>
      );
    },
  },
  {
    title: '大小',
    dataIndex: 'size',
    hideInForm: true,
    hideInSearch: true,
    render: (_, dto) => {
      return (
        <Statistic
          value={dto.size}
          suffix="KB"
          valueStyle={{ fontSize: '14px' }}
        />
      );
    },
  },
  {
    title: '播放次数',
    dataIndex: 'count',
    hideInSearch: true,
    hideInForm: true,
    render: (_, dto) => {
      return <Statistic value={dto.count} valueStyle={{ fontSize: '14px' }} />;
    },
  },
  {
    title: '创建时间',
    dataIndex: 'createdAt',
    hideInSearch: true,
    render: (_, dto) => {
      return <span>{dayjs(dto.createdAt).fromNow()}</span>;
    },
  },
  {
    title: '上传人',
    dataIndex: 'userName',
    hideInSearch: true,
  },
  {
    title: '操作',
    hideInSearch: true,
    render: (_, dto) => {
      const { editorPermission } = useContext(context);

      return (
        <Space>
          <WatchButton dto={dto} />
          {editorPermission && <RemoveButton mediaId={dto.id} />}
          <Transfer id={dto.id} />
        </Space>
      );
    },
  },
];

export default () => {
  const { id: companyId, departments, hasPermission, refresh } = useCompany();

  const [playMedia, setPlayMedia] = useState<ListCompanyStudyMediaItemDTO>();

  useEffect(() => {
    refresh();
  }, []);

  const ref = useRef<ActionType>();
  return (
    <>
      <context.Provider
        value={{
          refresh: () => {
            ref.current?.reloadAndRest?.();
          },
          setPlayMedia: (dto) => {
            setPlayMedia(dto);
          },
          editorPermission: hasPermission('page:study:editor'),
        }}
      >
        <ProTable
          className="pro-table"
          toolBarRender={() => {
            return [
              hasPermission('page:study:editor') && (
                <UploadMedia
                  key="uploader"
                  refresh={() => {
                    ref.current?.reloadAndRest?.();
                  }}
                />
              ),
            ];
          }}
          actionRef={ref}
          rowKey={'id'}
          request={({
            companyId,
            departments,
            current,
            pageSize,
            ...params
          }) => {
            return companyStudyController
              .list(
                companyId,
                {
                  ...params,
                  departmentIds: departments.map((item) => item.id),
                },
                current,
                pageSize,
              )
              .then((resp) => ({
                data: resp.list,
                total: resp.total,
                success: true,
              }));
          }}
          columns={columns}
          params={{ companyId, departments }}
        />
      </context.Provider>
      {playMedia && (
        <PlayModal
          open={!!playMedia}
          onCancel={() => setPlayMedia(undefined)}
          media={playMedia}
        />
      )}
    </>
  );
};
