import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

export default ({ ...props }: RangePickerProps) => {
  return (
    <RangePicker
      {...props}
      presets={[
        {
          label: '今天',
          value: [dayjs().startOf('day'), dayjs().endOf('day')],
        },
        {
          label: '最近一周',
          value: [dayjs().add(-1, 'week'), dayjs().endOf('day')],
        },
        {
          label: '最近一个月',
          value: [dayjs().add(-1, 'month'), dayjs().endOf('day')],
        },
        {
          label: '最近三个月',
          value: [dayjs().add(-3, 'month'), dayjs().endOf('day')],
        },
      ]}
    />
  );
};
