import { Select, SelectProps } from 'antd';
import { CustomerStateEnum } from '../api';
import { DefaultOptionType } from 'antd/es/select';
import { useMemo } from 'react';

const stateOptions: DefaultOptionType[] = [
  // {
  //   label: '未知',
  //   value: CustomerStateEnum.UNKNOWN,
  // },
  {
    label: '待跟进',
    value: CustomerStateEnum.WAIT_FOLLOW_UP,
  },
  {
    label: '贷款资质不符合',
    value: CustomerStateEnum.UNQUALIFIED,
  },
  {
    label: '待签约',
    value: CustomerStateEnum.WAIT_SIGN,
  },
  {
    label: '已上门',
    value: CustomerStateEnum.VISITED,
  },
  {
    label: '已签约',
    value: CustomerStateEnum.SIGNED,
  },
  // {
  //   label: '审核中',
  //   value: CustomerStateEnum.APPROVAL,
  // },
  {
    label: '已放款',
    value: CustomerStateEnum.LOAN,
  },
  // {
  //   label: '在审件',
  //   value: CustomerStateEnum.EXAMINING,
  // },
  {
    label: '捣乱申请',
    value: CustomerStateEnum.INVALID,
  },
];

export default (
  props: SelectProps & {
    enableState?: CustomerStateEnum[];
    disableState?: CustomerStateEnum[];
  },
) => {
  const options = useMemo(() => {
    if (!props.enableState?.length) {
      return stateOptions;
    }
    return stateOptions.filter((option) =>
      props.enableState?.includes(option.value as CustomerStateEnum),
    );
  }, [props.enableState]);
  return (
    <Select
      {...props}
      options={options
        .filter((item) => item.value !== CustomerStateEnum.APPROVAL)
        .map((item) => {
          return {
            ...item,
            disabled: props.disableState?.includes(
              item.value as CustomerStateEnum,
            ),
          };
        })}
      placeholder="客户状态"
      style={{ width: '140px', ...props.style }}
    />
  );
};
