import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useH5Company } from './Layout';
import { useRequest } from 'ahooks';
import { CustomerEventTypeEnum, customerController } from '../../api';
import { PhoneOutlined } from '@ant-design/icons';
import {
  CenterPopup,
  Input,
  List,
  Modal,
  Rate,
  Space,
  Steps,
  Tag,
  TextArea,
  TextAreaProps,
  Toast,
} from 'antd-mobile';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { customerGroupMapping, customerStateMapping } from '../../constants';
import dayjs from 'dayjs';
import { PhoneFill } from 'antd-mobile-icons';
import { Button } from 'antd';

const callPhone = (phone: string, confirm?: boolean) => {
  if ('plus' in window) {
    (window.plus as any).device.dial(
      phone,
      confirm === undefined ? true : confirm,
    );
  } else {
    alert('拨打电话：' + phone);
  }
};

const getPlus = () => {
  return new Promise((resolve) => {
    document.addEventListener('plusready', () => {
      resolve((window as any).plus);
    });
  });
};

const getCallLog = () => {
  const plus = (window as any).plus;
  // return getPlus().then((plus: any) => {
  console.log('getCallLog');
  const CallLog = plus.android.importClass('android.provider.CallLog');
  const main = plus.android.runtimeMainActivity();
  const resolver = main.getContentResolver();
  plus.android.importClass(resolver);

  const cursor = resolver.query(
    CallLog.Calls.CONTENT_URI,
    null,
    null,
    null,
    null,
  );
  plus.android.importClass(cursor);
  const content = [];
  let count = 0;
  if (cursor.moveToFirst()) {
    do {
      const callLog = {
        number: cursor.getString(cursor.getColumnIndex(CallLog.Calls.NUMBER)),
        date: cursor.getString(cursor.getColumnIndex(CallLog.Calls.DATE)),
        type: cursor.getString(cursor.getColumnIndex(CallLog.Calls.TYPE)),
        duration: cursor.getString(
          cursor.getColumnIndex(CallLog.Calls.DURATION),
        ),
      };
      content.push(callLog);
      count++;
    } while (cursor.moveToNext() && count < 10);
  }
  console.log(content);
  // });
};

// document.addEventListener(
//   'plusready',
//   function () {
//     (function (plus: any) {
//       let maintest = plus.android.runtimeMainActivity();
//       let Contexttest = plus.android.importClass('android.content.Context');
//       let telephonyManager = plus.android.importClass(
//         'android.telephony.TelephonyManager',
//       );
//       let telManager = plus.android
//         .runtimeMainActivity()
//         .getSystemService(Contexttest.TELEPHONY_SERVICE);
//       let receiver = plus.android.implements(
//         'io.dcloud.android.content.BroadcastReceiver',
//         {
//           onReceive: function (Contexttest: any, intent: any) {
//             plus.android.importClass(intent);
//             console.log('intent', intent.getAction());
//             // let telephonyManager = plus.android.importClass("android.telephony.TelephonyManager");
//             let telephonyManager = plus.android
//               .runtimeMainActivity()
//               .getSystemService(Contexttest.TELEPHONY_SERVICE);
//             let phonetype = telManager.getCallState();
//             let phoneNumber = intent.getStringExtra(
//               telephonyManager.EXTRA_INCOMING_NUMBER,
//             );
//             console.log('phonetype:', phonetype); //电话状态 0->空闲状态 1->振铃状态 2->通话存在
//             console.log('phonenumber', phoneNumber); //电话号
//             switch (phonetype) {
//               case 0:
//                 console.log('空闲状态');
//                 break;
//               case 1:
//                 console.log('响铃');
//                 break;
//               case 2:
//                 console.log('通话存在');
//                 break;
//             }

//             // _t.state.push({state:phonetype == 0?'空闲状态':phonetype == 1?'振铃状态':'通话存在', time:dateFormat('hh:mm:ss')})
//           },
//         },
//       );
//       let IntentFilter = plus.android.importClass(
//         'android.content.IntentFilter',
//       );
//       let filter = new IntentFilter();
//       filter.addAction(telephonyManager.ACTION_PHONE_STATE_CHANGED);
//       maintest.registerReceiver(receiver, filter);
//     })((window as any).plus);
//   },
//   false,
// );

const CommentInput = ({ value, onChange }: TextAreaProps) => {
  return (
    <Space direction="vertical" style={{}}>
      <Space wrap>
        {['无人接听', '关机', '停机', '空号'].map((item) => (
          <Button
            key={item}
            type="link"
            onClick={() => {
              onChange?.(item);
            }}
          >
            {item}
          </Button>
        ))}
      </Space>
      <TextArea
        style={{ border: '1px solid #ddd' }}
        placeholder="跟进信息"
        rows={4}
        value={value}
        onChange={onChange}
      />
    </Space>
  );
};

export default () => {
  const { company, setTitle, withCall, resetCall } = useH5Company();

  const { customerId } = useParams<{
    customerId: string;
  }>();

  const location = useLocation();

  // useEffect(() => {
  //   getCallLog();
  // }, []);

  const { data, loading, refresh } = useRequest(
    () => {
      return Promise.all([
        customerController.getDetail(company.id, customerId!),
        customerController.listEvent(company.id, customerId!),
      ]).then(([customer, events]) => ({
        customer,
        events: events,
      }));
    },
    {
      ready: !!company.id && !!customerId,
      refreshDeps: [company.id, customerId],
    },
  );

  const { customer, events } = data || {};

  useEffect(() => {
    setTitle(customer?.name || '我的客户');
  }, [customer, setTitle]);

  const [comment, setComment] = useState<string>();

  const [showComment, setShowComment] = useState<boolean>(false);

  const { runAsync: addEvent, loading: addEventLoading } = useRequest(
    (comment: string) => {
      return customerController.addEvent(
        company.id,
        customerId!,
        CustomerEventTypeEnum.COMMENT,
        comment,
      );
    },
    {
      manual: true,
      onSuccess: () => {
        setComment(undefined);
        setShowComment(false);
        refresh();
        Toast.show('跟进成功');
      },
      onError: (e) => {
        setShowComment(false);
        Toast.show(e.message);
      },
    },
  );

  const makeCall = useCallback(() => {
    if (customer?.mobile) {
      callPhone(customer?.mobile, false);
    }
    setShowComment(true);
    resetCall?.();
  }, [customer?.mobile, setShowComment, resetCall]);

  useEffect(() => {
    if (withCall && customer?.id && withCall === customer.id) {
      console.log('test', withCall, customer?.id);
      makeCall();
    }
  }, [withCall, makeCall, customer?.id]);

  return (
    <>
      <List header="基本信息">
        <List.Item title="姓名">{customer?.name}</List.Item>
        <List.Item
          title="手机号码"
          onClick={customer?.mobile ? () => makeCall() : undefined}
          arrow={<PhoneFill color="blue" />}
        >
          {customer?.mobile}
        </List.Item>
      </List>
      <List header="拓展信息">
        <List.Item title="星级">
          <Rate readOnly value={customer?.star} />
        </List.Item>
        <List.Item title="状态">
          {customer?.state && customerStateMapping[customer?.state]}
        </List.Item>
        <List.Item title="分组">
          {customer?.group && customerGroupMapping[customer?.group]}
        </List.Item>
      </List>
      <List header="资质信息">
        <List.Item title="附属信息">
          <Space wrap>
            {customer?.isNative && <Tag>本地人</Tag>}
            {customer?.hasCar && <Tag>车</Tag>}
            {customer?.hasHouse && <Tag>房</Tag>}
            {customer?.hasCreditCard && <Tag>信用卡</Tag>}
            {customer?.hasInsurance && <Tag>寿险保单</Tag>}
            {customer?.hasProvidentFund && <Tag>公积金</Tag>}
            {customer?.hasSocialSecurity && <Tag>社保</Tag>}
            {customer?.hasWages && <Tag>代发工资</Tag>}
            {customer?.hasBusinessLicense && <Tag>营业执照</Tag>}
          </Space>
        </List.Item>
        <List.Item title="其他资质">{customer?.remark}</List.Item>
      </List>
      <Steps direction="vertical">
        {events?.map((event) => (
          <Steps.Step
            key={event.id}
            status="finish"
            title={
              <div>
                <span>{event.userName}</span>@
                <span>
                  {dayjs(event?.createdAt).format('YYYY-MM-DD HH:mm')}
                </span>
              </div>
            }
            description={
              <Space direction="vertical">
                <div>{event.comment}</div>
                <div>{event.remark}</div>
              </Space>
            }
          />
        ))}
      </Steps>
      <CenterPopup visible={showComment} style={{ zIndex: 999999 }}>
        <div style={{ padding: '4px' }}>
          <CommentInput value={comment} onChange={setComment} />
          <Space style={{ marginTop: '5px', width: '100%' }} justify="center">
            <Button
              disabled={!comment}
              loading={addEventLoading}
              type="primary"
              onClick={() => {
                if (comment) {
                  addEvent(comment);
                }
              }}
            >
              保存
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setShowComment(false);
              }}
            >
              返回
            </Button>
          </Space>
        </div>
      </CenterPopup>
    </>
  );
};
