import { LockOutlined, MobileOutlined } from '@ant-design/icons';
import {
  CaptFieldRef,
  LoginForm,
  ProConfigProvider,
  ProFormCaptcha,
  ProFormText,
} from '@ant-design/pro-components';
import { Button, List, Modal, message } from 'antd';
import { useRequest, useTitle } from 'ahooks';
import {
  companyController,
  CompanyListItem,
  loginController,
  systemController,
  UserDTO,
} from '../../api';
import { useRef, useState } from 'react';
import '../../styles/common.scss';
import SuperAdminCompany from '../../components/SuperAdminCompany';

const ChoiceCompany = () => {
  const { loading, data } = useRequest(() => {
    return systemController.listCompany();
  });
  return (
    <List loading={loading}>
      {data?.map((company) => {
        return (
          <List.Item
            key={company.id}
            extra={
              <Button
                type="primary"
                onClick={() => {
                  window.location.href = `/${company.id}/welcome`;
                }}
              >
                选择
              </Button>
            }
          >
            <div>{company.name}</div>
          </List.Item>
        );
      })}
    </List>
  );
};

export default () => {
  const [token, setToken] = useState<string>();

  const { loading, runAsync } = useRequest(
    ({
      username,
      password,
      code,
    }: {
      username: string;
      password: string;
      code: string;
    }) => {
      return loginController.login(username, password, code, token);
    },
    {
      manual: true,
    },
  );

  const captchaRef = useRef<CaptFieldRef>();

  const [modal, contextHolder] = Modal.useModal();

  useTitle('登录');
  const { hostname } = window.location;
  const localArr = [
    { title: '沪ICP备2023022809号', url: 'mxcztech.com' },
    { title: '沪ICP备2023021583号', url: 'anmeijuxin.com' },
    { title: '沪ICP备2024058909号', url: 'hjrongzi88cn.com' },
    { title: '沪ICP备2024063186号', url: 'jr092019.com' },
    { title: '沪ICP备2023021583号', url: 'localhost' },
  ];
  let title = '';
  localArr.find((res) => {
    if (hostname.endsWith(res.url)) {
      title = res.title;
    }
  });
  return (
    <ProConfigProvider hashed={false}>
      {contextHolder}
      <div
        className="login-page"
        style={{ backgroundColor: 'white', marginTop: '20vh' }}
      >
        <LoginForm<{ username: string; password: string; code: string }>
          loading={loading}
          onFinish={async (values) => {
            return runAsync(values)
              .then(async (result) => {
                sessionStorage.setItem('token', result.token);
                if (result.user.isSuperAdmin || !result.user.companyId) {
                  // superadmin 弹出选择公司
                  await modal.confirm({
                    title: '请选择公司',
                    type: 'info',
                    content:
                      result.user.isSuperAdmin &&
                      process.env.NODE_ENV !== 'development' ? (
                        <SuperAdminCompany showAll />
                      ) : (
                        <ChoiceCompany />
                      ),
                    footer: null,
                  });
                  return true;
                }
                window.location.href = `/${result.user.companyId || 1}/welcome`;
                return true;
              })
              .catch((e) => message.error(e.message));
          }}
        >
          <div style={{ marginTop: '20px' }}>
            <ProFormText
              fieldProps={{
                size: 'large',
                prefix: <MobileOutlined className={'prefixIcon'} />,
              }}
              name="username"
              placeholder={'手机号'}
              rules={[
                {
                  required: true,
                  message: '请输入手机号！',
                },
                {
                  pattern: /^1\d{10}$/,
                  message: '手机号格式错误！',
                },
              ]}
            />
            <ProFormText.Password
              name="password"
              fieldProps={{
                size: 'large',
                prefix: <LockOutlined className={'prefixIcon'} />,
              }}
              placeholder={'密码'}
              rules={[
                {
                  required: true,
                  message: '请输入密码！',
                },
              ]}
            />
            <ProFormCaptcha
              phoneName="username"
              fieldProps={{
                size: 'large',
                prefix: <LockOutlined className={'prefixIcon'} />,
              }}
              captchaProps={{
                size: 'large',
              }}
              placeholder={'请输入验证码'}
              captchaTextRender={(timing, count) => {
                if (timing) {
                  return `${count} ${'获取验证码'}`;
                }
                return '获取验证码';
              }}
              name="code"
              rules={[
                {
                  required: true,
                  message: '请输入验证码！',
                },
              ]}
              fieldRef={captchaRef}
              onGetCaptcha={async (mobile: string) => {
                loginController
                  .sendCode(mobile)
                  .then((codeToken) => {
                    setToken(codeToken);
                    message.success('获取验证码成功！');
                  })
                  .catch((e) => {
                    message.error(e.message).then(() => {
                      captchaRef.current?.endTiming?.();
                    });
                  });
              }}
            />
          </div>
        </LoginForm>
        <div className="login_bg"></div>
        <div className="bottom_box">
          <a href="https://beian.miit.gov.cn/" target="_blank">
            {title}
          </a>
        </div>
      </div>
    </ProConfigProvider>
  );
};
