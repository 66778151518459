import { useAntdTable, useDebounce, useRequest } from 'ahooks';
import { useCompany } from '../../hooks/company';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Popconfirm,
  Popover,
  Row,
  Select,
  SelectProps,
  Space,
  Statistic,
  Table,
  Tooltip,
  message,
} from 'antd';
import {
  BaseDepartmentDTO,
  CustomerLoanDTO,
  ListCustomerLoanParamsDTO,
  Long,
  loanController,
} from '../../api';
import { ColumnsType } from 'antd/es/table';
import CustomerMobile from '../../components/CustomerMobile';
import dayjs from 'dayjs';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import DepartmentSelect from '../../components/DepartmentSelect';
import CustomerChannelSelect from '../../components/CustomerChannelSelect';
import { ActionType, ProColumns, ProTable } from '@ant-design/pro-components';
import '../../styles/common.scss';
import { formatEndTime, formatStartTime } from '../../utils';
import { useLocation, useOutletContext } from 'react-router-dom';
import UserSelect from '../../components/UserSelect';

export type LoanListSessionQuery = Partial<ListCustomerLoanParamsDTO>;

export function setLoainListSessionQuery(v: LoanListSessionQuery) {
  (window as any).__$$loanListSessionQuery = v;
}

const getLoanListSessionQuery = () => {
  const v = (window as any).__$$loanListSessionQuery as LoanListSessionQuery;
  return v;
};

const recfreshContext = createContext<{ refresh: () => void }>({
  refresh: () => {},
});

const BankSelect = (props: SelectProps) => {
  const { id } = useCompany();
  const [banks, setBanks] = useState<string[]>();
  const { data, loading } = useRequest(
    () => {
      return loanController.listBank(id);
    },
    {
      refreshDeps: [id],
    },
  );

  useEffect(() => {
    setBanks(data);
  }, [data]);
  return (
    <Select
      placeholder="放款银行"
      {...props}
      loading={loading}
      showSearch
      onSearch={(v) => {
        if (!v) {
          setBanks(data);
        } else {
          setBanks(data?.filter((bank) => bank.startsWith(v)));
        }
      }}
      options={banks?.map((value) => ({ label: value, value }))}
    />
  );
};

const flatDepartment = (
  departments: BaseDepartmentDTO[],
  depId?: Long,
): BaseDepartmentDTO[] => {
  if (!depId) {
    return [];
  }
  const dep = departments.find((item) => item.id === depId);
  if (!dep) {
    return [];
  }

  return [dep, ...flatDepartment(departments, dep.parentId)];
};

const UserInfo = ({ userName, userId, userDepartmentId }: CustomerLoanDTO) => {
  const { departments } = useCompany();

  const userDepartments = flatDepartment(
    departments,
    userDepartmentId,
  ).reverse();

  return (
    <Space direction="vertical">
      <span>
        {userName}（{userId}）
      </span>
      <span>{userDepartments.map((item) => item.name).join('/')}</span>
    </Space>
  );
};

const RemoveButton = ({ id }: { id: Long }) => {
  const { id: companyId, hasPermission } = useCompany();

  const { refresh } = useContext(recfreshContext);

  const { loading, run: remove } = useRequest(
    () => {
      return loanController.remove(companyId, id);
    },
    {
      refreshDeps: [id, companyId],
      onError: (e) => message.error(e.message),
      manual: true,
      onSuccess: () => {
        message.success('删除成功');
        refresh();
      },
    },
  );

  if (!hasPermission('page:report:loan:remove')) {
    return <>-</>;
  }

  return (
    <Popconfirm title="确认删除" onConfirm={() => remove()}>
      <Button danger type="primary" loading={loading}>
        删除
      </Button>
    </Popconfirm>
  );
};

const columns: ProColumns<CustomerLoanDTO>[] = [
  {
    dataIndex: 'id',
    search: {
      transform: (loanId) => {
        return {
          loanId,
        };
      },
    },
    title: '放款 ID',
  },
  {
    title: '区域',
    hideInTable: true,
    dataIndex: 'departmentId',
    order: 1000,
    renderFormItem: (_, {}) => {
      return <DepartmentSelect />;
    },
  },
  {
    hideInTable: false,
    title: '业务员',
    order: 800,
    renderFormItem: (_, {}) => {
      return <UserSelect allowClear mode="multiple" />;
    },
    render: (_, dto) => {
      return <UserInfo {...dto} />;
    },
    search: {
      transform: (userIdList) => ({ userIdList }),
    },
  },
  {
    title: '客户',
    renderFormItem: (_, {}) => {
      return <Input placeholder="客户姓名/电话" />;
    },
    search: {
      transform: (customerKeyword) => ({ customerKeyword }),
    },
    render: (_, record) => {
      return (
        <Space direction="vertical">
          <span>{record.customerName}</span>
          <CustomerMobile mobile={record.customerMobile} />
        </Space>
      );
    },
  },
  {
    hideInForm: true,
    hideInSearch: true,
    title: '放款',
    render: (_, record) => {
      return (
        <Space direction="vertical">
          <Space>
            <span>金额：</span>
            <Statistic
              value={Number(record.loanAmount) / 100}
              prefix="¥"
              valueStyle={{ fontSize: '14px' }}
            />
          </Space>
          <Space>
            <span>佣金：</span>
            <Statistic
              value={Number(record.brokerage) / 100}
              prefix="¥"
              valueStyle={{ fontSize: '14px' }}
            />
          </Space>
        </Space>
      );
    },
  },
  {
    title: '银行',
    dataIndex: 'bank',
    renderFormItem: () => {
      return <BankSelect />;
    },
    render: (_, record) => {
      return (
        <Space direction="vertical">
          <span>{record.bank}</span>
          <span>
            <Tooltip title="放款时间">
              {dayjs(record.loanedAt).format('YYYY-MM-DD')}
            </Tooltip>
          </span>
        </Space>
      );
    },
  },
  {
    title: '渠道',
    dataIndex: 'channel',
    order: 999,
    render: (_, dto) => {
      return (
        <Space direction="vertical">
          <span>{dto.channel}</span>
          <span>
            <Tooltip title="客户创建时间">
              {dto.customerCreatedAt
                ? dayjs(dto.customerCreatedAt).format('YYYY-MM-DD HH:mm')
                : '-'}
            </Tooltip>
          </span>
          <span>
            <Tooltip title="放款创建时间">
              {dto.customerCreatedAt
                ? dayjs(dto.createdAt).format('YYYY-MM-DD HH:mm')
                : '-'}
            </Tooltip>
          </span>
        </Space>
      );
    },
    renderFormItem: () => <CustomerChannelSelect />,
  },
  {
    title: '备注',
    hideInForm: true,
    hideInSearch: true,
    width: '20%',
    dataIndex: 'remark',
  },
  {
    title: '提交',
    hideInSearch: true,
    hideInForm: true,
    dataIndex: 'creatorName',
  },
  {
    title: '操作',
    hideInForm: true,
    hideInSearch: true,
    render: (_, { id }) => {
      return <RemoveButton id={id} />;
    },
  },
  {
    title: '放款时间',
    hideInTable: true,
    search: {
      transform: (value) => {
        return {
          loanedAtBegin: formatStartTime(dayjs(value[0])),
          loanedAtEnd: formatEndTime(dayjs(value[1])),
        };
      },
    },
    valueType: 'dateRange',
  },
  {
    title: '创建时间',
    hideInTable: true,
    search: {
      transform: (value) => {
        return {
          createdAtBegin: formatStartTime(dayjs(value[0])),
          createdAtEnd: formatEndTime(dayjs(value[1])),
        };
      },
    },
    valueType: 'dateRange',
  },
];

export default () => {
  const { id: companyId } = useCompany();

  const ref = useRef<ActionType>();

  const location = useLocation();

  const query = useMemo(() => {
    return new URLSearchParams(location.hash.slice(1));
  }, [location.hash]);

  return (
    <recfreshContext.Provider
      value={{ refresh: () => ref.current?.reload?.() }}
    >
      <ProTable<
        CustomerLoanDTO,
        NonNullable<Parameters<typeof loanController.list>[3]> & {
          query: URLSearchParams;
        }
      >
        className="pro-table"
        pagination={{
          pageSizeOptions: [20, 50, 100, 200, 500, 1000],
        }}
        actionRef={ref}
        columns={columns}
        options={false}
        params={{
          query: query,
        }}
        bordered
        rowKey="id"
        size="small"
        cardBordered={{ search: true }}
        dateFormatter="number"
        request={async (
          { current, pageSize, query, ...params },
          sort,
          filter,
        ) => {
          const sessionQuery = getLoanListSessionQuery();
          const v = Object.assign({}, sessionQuery, params);
          return loanController
            .list(companyId, current, pageSize, {
              ...v,
            })
            .then((data) => {
              return { data: data.list, success: true, total: data.total };
            });
        }}
      />
    </recfreshContext.Provider>
  );
};
