import { useRequest } from 'ahooks';
import { useCompany } from '../../hooks/company';
import { BaseUserDTO, VisitorListItemDTO, visitorController } from '../../api';
import dayjs from 'dayjs';
import { Button, Col, DatePicker, Row, Space, Table, Tag } from 'antd';
import { ColumnType } from 'antd/es/table';
import UserInfoModal from '../../components/UserInfoModal';
import { useMemo, useState } from 'react';
import { flatDepartment, sortName } from '../../utils';
import DepartmentSelect from '../../components/DepartmentSelect';
import DateRangePicker from '../../components/DateRangePicker';
import '../../styles/common.scss';

const Department = ({ user }: { user: BaseUserDTO }) => {
  const { allDepartments: departments } = useCompany();
  const department = useMemo(() => {
    return flatDepartment(departments, user.departmentId)
      .reverse()
      .map(({ name }) => name);
  }, [user]);
  return <>{department.join('/') || '-'}</>;
};

interface Data {
  user: BaseUserDTO;
  visitCount: number;
  visitSignCount: number;
  agentVisitCount: number;
  agentSignCount: number;
  totalVisitCount: number;
}

const columns: ColumnType<Data>[] = [
  {
    dataIndex: 'user',
    title: '区域',
    render: (user: BaseUserDTO) => {
      return <Department user={user} />;
    },
    sorter: (a, b) => {
      return sortName(a.user.name, b.user.name);
    },
  },
  {
    dataIndex: 'user',
    align: 'right',
    title: '业务员',
    render: (user: BaseUserDTO) => {
      return <UserInfoModal id={user.id} name={user.name} />;
    },
    sorter: (a, b) => (a.user.id > b.user.id ? 1 : -1),
  },
  {
    align: 'right',
    title: '上门',
    dataIndex: 'visitCount',
    sorter: (a, b) => (a.visitCount > b.visitCount ? 1 : -1),
  },
  {
    title: '签约数',
    align: 'right',
    dataIndex: 'visitSignCount',
    render: (value: number, { visitCount }) => {
      const percentage = (value * 100) / visitCount;
      return (
        <Space>
          <span>{value}</span>
          <Tag color="rgb(98 169 245)">{percentage.toFixed(2)}%</Tag>
        </Space>
      );
    },
    sorter: (a, b) => (a.visitSignCount > b.visitSignCount ? 1 : -1),
  },
  {
    title: '拓展上门',
    align: 'right',
    dataIndex: 'agentVisitCount',
    sorter: (a, b) => (a.agentVisitCount > b.agentVisitCount ? 1 : -1),
  },
  {
    title: '拓展签约',
    align: 'right',
    dataIndex: 'agentSignCount',
    render: (value: number, { agentVisitCount }) => {
      const percentage = (value * 100) / agentVisitCount;
      return (
        <Space>
          <span>{value}</span>
          <Tag color="rgb(98 169 245)">
            {isNaN(percentage) ? 0 : percentage.toFixed(2)}%
          </Tag>
        </Space>
      );
    },
    sorter: (a, b) => (a.agentSignCount > b.agentSignCount ? 1 : -1),
  },
  {
    title: '总上门',
    dataIndex: 'totalVisitCount',
    align: 'right',
    sorter: (a, b) => {
      return a.totalVisitCount > b.totalVisitCount ? 1 : -1;
    },
  },
];

export default () => {
  const { id: companyId, allDepartments: departments } = useCompany();
  const [departmentId, setDepartmentId] = useState<I.ID | undefined>();
  const defaultCreatedAt: [dayjs.Dayjs, dayjs.Dayjs] = [
    dayjs().add(-1, 'week'),
    dayjs(),
  ];
  const [createdAt, setCreatedAt] =
    useState<[dayjs.Dayjs, dayjs.Dayjs]>(defaultCreatedAt);

  const { data, loading, refresh } = useRequest(
    () => {
      return visitorController
        .getData(
          companyId,
          createdAt[0].format('YYYY-MM-DD[T]00:00:00+00:00'),
          createdAt[1].format('YYYY-MM-DD[T]23:59:59+00:00'),
          departmentId,
        )
        .then((data) => {
          const depIds = departments.map((item) => item.id);
          const result = data
            .filter((item) => depIds.includes(item.user.departmentId || 0))
            .reduce<Record<I.ID, VisitorListItemDTO[]>>((pre, cur) => {
              const key = cur?.user.id;
              const list = pre[key] || [];
              return {
                ...pre,
                [key]: [...list, cur],
              };
            }, {});
          return Object.entries(result).map(([key, value]) => {
            const visitCount = value.filter((dto) => !dto.agentMobile).length;
            const visitSignCount = value.filter((dto) => dto.isSign).length;
            const agentVisitCount = value.filter(
              (dto) => dto.agentMobile,
            ).length;
            const agentSignCount = value.filter(
              (dto) => dto.agentMobile && dto.isSign,
            ).length;
            return {
              user: value[0].user,
              visitCount,
              visitSignCount,
              agentSignCount,
              agentVisitCount,
              totalVisitCount: visitCount + agentVisitCount,
            } as Data;
          });
        });
    },
    {
      refreshDeps: [companyId, departmentId, departments, createdAt],
    },
  );
  return (
    <Row>
      <Col span={24}>
        <Space>
          <DepartmentSelect
            style={{ width: '300px' }}
            value={departmentId}
            onChange={(v) => {
              setDepartmentId(v);
              refresh();
            }}
          />
          <DateRangePicker
            value={createdAt}
            onChange={(v) => {
              setCreatedAt(
                (v as [dayjs.Dayjs, dayjs.Dayjs]) || defaultCreatedAt,
              );
              refresh();
            }}
          />
          <Button type="primary" loading={loading} onClick={refresh}>
            查询
          </Button>
        </Space>
      </Col>
      <Col span={24} style={{ marginTop: '20px' }}>
        <Table
          className="normal-table"
          bordered
          size="small"
          summary={(data) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell key={0} index={0}>
                    合计
                  </Table.Summary.Cell>
                  <Table.Summary.Cell key={1} index={1} align="right">
                    {data.length}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell key={2} index={2} align="right">
                    {data.reduce((pre, cur) => pre + cur.visitCount, 0)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell key={3} index={3} align="right">
                    <Space>
                      <span>
                        {data.reduce((pre, cur) => pre + cur.visitSignCount, 0)}
                      </span>
                      <Tag color="rgb(98 169 245)">
                        {(
                          (data?.reduce(
                            (prev, cur) => prev + cur.visitSignCount,
                            0,
                          ) /
                            data?.reduce(
                              (prev, cur) => prev + cur.visitCount,
                              0,
                            )) *
                          100.0
                        ).toFixed(2)}
                        %
                      </Tag>
                    </Space>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell key={4} index={4} align="right">
                    {data.reduce((pre, cur) => pre + cur.agentVisitCount, 0)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell key={5} index={5} align="right">
                    <Space>
                      <span>
                        {' '}
                        {data.reduce((pre, cur) => pre + cur.agentSignCount, 0)}
                      </span>

                      <Tag color="rgb(98 169 245)">
                        {(
                          (data?.reduce(
                            (prev, cur) => prev + cur.agentSignCount,
                            0,
                          ) /
                            data?.reduce(
                              (prev, cur) => prev + cur.agentVisitCount,
                              0,
                            )) *
                          100.0
                        ).toFixed(2)}
                        %
                      </Tag>
                    </Space>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell key={6} index={6} align="right">
                    {data.reduce((pre, cur) => pre + cur.totalVisitCount, 0)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
          rowKey={(dto) => dto.user.id}
          dataSource={data}
          columns={columns}
          loading={loading}
          pagination={false}
        />
      </Col>
    </Row>
  );
};
