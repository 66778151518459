import { useRequest } from 'ahooks';
import {
  HandleAnalyseDTO,
  HandleAnalyseParams,
  UserStateEnum,
  dataAnalyzeController,
} from '../../api';
import { useCompany } from '../../hooks/company';
import dayjs from 'dayjs';
import { ExpandAltOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Radio,
  Row,
  Segmented,
  Space,
  Statistic,
  Table,
} from 'antd';
import { createContext, useContext, useMemo, useState } from 'react';
import ResizeTable from '../../components/ResizeTable';

import { ColumnType } from 'antd/es/table';
import DepartmentSelect from '../../components/DepartmentSelect';
import DateRangePicker from '../../components/DateRangePicker';
import {
  findAllDepartmentId,
  findChildrenDepartment,
  flatDepartment,
  formatEndTime,
  formatStartTime,
  sortName,
} from '../../utils';
import '../../styles/common.scss';
import { setLoainListSessionQuery } from '../customer/LoanList';
import { useAliveController } from 'react-activation';
import { useNavigate } from 'react-router-dom';
import {
  VisitorQuerySession,
  setVisitorQuerySession,
} from '../customer/VisitorList';

type Data = HandleAnalyseDTO & { name?: string; ix: number };

type Type = 'person' | 'department' | 'group';

const queryContext = createContext<{
  createdAt?: [dayjs.Dayjs, dayjs.Dayjs];
  type: Type;
}>({ type: 'department' });

const LoanExpandLink = ({ data }: { data: Data }) => {
  const { drop } = useAliveController();

  const { createdAt: date, type } = useContext(queryContext);

  const { id: companyId } = useCompany();
  const departmentId = useMemo(() => {
    if (type === 'department' || type === 'group') {
      return data.departmentId;
    }
    return undefined;
  }, [type, data.departmentId]);

  const userId = useMemo(() => {
    if (type === 'person') {
      return data.userId;
    }
    return undefined;
  }, [type, data.userId]);

  const navigate = useNavigate();
  return (
    <ExpandAltOutlined
      onClick={() => {
        setLoainListSessionQuery({
          loanedAtBegin: date?.[0] && formatStartTime(date[0]),
          loanedAtEnd: date?.[1] && formatEndTime(date[1]),
          departmentId,
          userId,
        });
        drop('loan-list').then(() => {
          navigate(`/${companyId}/analyse/loan-list`);
        });
      }}
    />
  );
};

const CallDuration = ({
  count,
  duration,
}: {
  count: number;
  duration: number;
}) => {
  const durationStr = useMemo(() => {
    if (duration >= 3600) {
      return `${Math.floor(duration / 3600)} 小时`;
    }
    if (duration >= 60) {
      return `${Math.floor(duration / 60)} 分钟`;
    }
    if (!duration) {
      return '-';
    }
    return `${duration} 秒`;
  }, [duration]);
  return (
    <Space>
      <span>{count || '-'}</span>
      <span>/</span>
      <span>{durationStr}</span>
    </Space>
  );
};

const VisitorExpandLink = ({
  data,
  params,
}: {
  data: Data;
  params: VisitorQuerySession;
}) => {
  const { drop } = useAliveController();

  const { createdAt: date, type } = useContext(queryContext);

  const { id: companyId } = useCompany();
  const departmentId = useMemo(() => {
    if (type === 'department' || type === 'group') {
      return data.departmentId;
    }
    return undefined;
  }, [type, data.departmentId]);

  const userId = useMemo(() => {
    if (type === 'person') {
      return data.userId;
    }
    return undefined;
  }, [type, data.userId]);

  const navigate = useNavigate();

  const createdAt = useMemo(() => {
    if (date?.[0] && date?.[1]) {
      return [formatStartTime(date[0]), formatEndTime(date[1])];
    }
    return undefined;
  }, [date]);
  return (
    <ExpandAltOutlined
      onClick={() => {
        console.log(params);
        setVisitorQuerySession({
          ...params,
          createdAt,
          departmentId,
          userId,
        });
        drop('visit-manage').then((res) => {
          navigate(`/${companyId}/analyse/visit-manage`);
        });
      }}
    />
  );
};

const LoanStatistic = ({ data }: { data: Data }) => {
  const { loanCount, loanAmount } = data;
  return (
    <Space>
      <span>{loanCount}</span>
      <span>
        （
        <Statistic
          style={{ display: 'inline-block' }}
          value={Number(loanAmount) / 100}
          prefix="¥"
          valueStyle={{ fontSize: '14px' }}
        />
        ）
      </span>
      <LoanExpandLink data={data} />
    </Space>
  );
};
const columns: ColumnType<Data>[] = [
  // {
  //   dataIndex: 'ix',
  // },
  {
    dataIndex: 'name',
    align: 'left',
    title: '名称',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => sortName(a.name, b.name),
    width: 80,
  },
  {
    title: '跟进次数/人数',
    sorter: (a, b) => (a.comment > b.comment ? 1 : -1),
    render: (_, { comment, distinctComment }) => {
      return (
        <Space>
          <span>{comment}</span>
          <span>/</span>
          <span>{distinctComment}</span>
        </Space>
      );
    },
    width: 120,
  },
  {
    title: '总上门量/月度',
    sorter: (a, b) => (a.visitor > b.visitor ? 1 : -1),
    render: (_, data) => {
      return (
        <Space>
          <span>{data.visitor}</span>
          <span>/</span>
          <span>{data.visitorCurMonth}</span>
          <VisitorExpandLink data={data} params={{}} />
        </Space>
      );
    },
    width: 120,
  },
  {
    title: '签约量',
    sorter: (a, b) => (a.sign > b.sign ? 1 : -1),
    render: (_, data) => {
      const { sign, visitor } = data;
      if (visitor == 0) {
        return <span>0</span>;
      }
      const percentage = (sign * 100.0) / visitor;
      return (
        <Space>
          <span>
            {sign}（{percentage.toFixed(2)}%）
          </span>
          <VisitorExpandLink data={data} params={{ isSign: true }} />
        </Space>
      );
    },
    width: 80,
  },
  {
    title: '拓展上门量/月度',
    sorter: (a, b) => (a.withAgent > b.withAgent ? 1 : -1),
    render: (_, data) => {
      const { withAgent, withAgentCurMonth } = data;
      return (
        <Space>
          <span>{withAgent}</span>
          <span>/</span>
          <span>{withAgentCurMonth}</span>
          <VisitorExpandLink data={data} params={{ withAgent: true }} />
        </Space>
      );
    },
    width: 120,
  },
  {
    title: '拓展成功量',
    sorter: (a, b) => (a.withAgentSign > b.withAgentSign ? 1 : -1),
    render: (_, data) => {
      const { withAgentSign, withAgent } = data;
      if (withAgent === 0) {
        return <span>0</span>;
      }
      const percentage = (withAgentSign * 100.0) / withAgent;
      return (
        <Space>
          <span>
            {withAgentSign}（{percentage.toFixed(2)}%）
          </span>
          <VisitorExpandLink
            data={data}
            params={{ withAgent: true, isSign: true }}
          />
        </Space>
      );
    },
    width: 100,
  },
  {
    title: '放款成功',
    sorter: (a, b) => (a.loanCount > b.loanCount ? 1 : -1),
    dataIndex: 'loanCount',
    render: (_, data) => {
      return <LoanStatistic data={data} />;
    },
    width: 100,
  },
  {
    title: '创收',
    sorter: (a, b, order) => {
      return Number(a.brokerage || 0) > Number(b.brokerage || 0) ? 1 : -1;
    },
    showSorterTooltip: true,
    dataIndex: 'brokerage',
    render: (_, { brokerage }) => {
      return (
        <Statistic
          value={Number(brokerage) / 100}
          prefix="¥"
          valueStyle={{ fontSize: '14px' }}
        />
      );
    },
    width: 80,
  },
  {
    title: '平均点位',
    render: (_, { brokerage, loanAmount }) => {
      if (loanAmount == 0) {
        return <span>0</span>;
      }
      const percentage = (Number(brokerage) * 100.0) / Number(loanAmount);
      return <span>{percentage.toFixed(2)}%</span>;
    },
    width: 100,
  },
  {
    title: '呼出',
    render: (_, { callOutCount, callOutDuration }) => {
      return <CallDuration count={callOutCount} duration={callOutDuration} />;
    },
    width: 100,
  },
  {
    title: '呼入',
    render: (_, { callInCount, callInDuration }) => {
      return <CallDuration count={callInCount} duration={callInDuration} />;
    },
    width: 100,
  },
];

export default () => {
  const { id: companyId, departments } = useCompany();

  const [departmentId, setDepartmentId] = useState<I.ID | null>(null);

  const [type, setType] = useState<Type>('department');

  const defaultCreatedAt: [dayjs.Dayjs, dayjs.Dayjs] = [
    dayjs().startOf('month'),
    dayjs(),
  ];

  const [dateRange, setDateRange] =
    useState<[dayjs.Dayjs, dayjs.Dayjs]>(defaultCreatedAt);

  const {
    data: rawData,
    loading,
    refresh,
  } = useRequest(
    () => {
      const params = {
        start: formatStartTime(dateRange[0]),
        end: formatEndTime(dateRange[1]),
        currentMonthStart: formatStartTime(
          dayjs().startOf('month').hour(0).minute(0).second(0),
        ),
        currentMonthEnd: formatEndTime(dayjs().endOf('month')),
      };
      return dataAnalyzeController.handleAnalyse(companyId, params);
    },
    {
      refreshDeps: [companyId, dateRange],
    },
  );

  const data: Data[] = useMemo(() => {
    if (!rawData) {
      return [];
    }
    const depIds = departments.map((item) => item.id);

    const validData = rawData.filter(({ departmentId }) =>
      depIds.includes(departmentId || 0),
    );

    switch (type) {
      case 'department': {
        // 根据所选的区域 group by
        // 先找到当前所有的区域
        const directChildrent = findChildrenDepartment(
          departments,
          departmentId || null,
        );

        return directChildrent.map((item, ix) => {
          const name = `${flatDepartment(departments, item.id)
            .filter((v) => v.id !== item.id)
            .map((item) => item.name)
            .reverse()
            .join('/')} ${item.name}`;
          const children = findAllDepartmentId(departments, item.id).map(
            (item) => item.id,
          );
          const value = validData
            .filter((item) => children.includes(item.departmentId || 0))
            .reduce<HandleAnalyseDTO>(
              (acc, item) => ({
                ...item,
                loanAmount: (
                  BigInt(acc.loanAmount) + BigInt(item.loanAmount)
                ).toString(),
                brokerage: (
                  BigInt(acc.brokerage) + BigInt(item.brokerage)
                ).toString(),
                comment: acc.comment + item.comment,
                distinctComment: acc.distinctComment + item.distinctComment,
                loanCount: acc.loanCount + item.loanCount,
                sign: acc.sign + item.sign,
                visitor: acc.visitor + item.visitor,
                visitorCurMonth: acc.visitorCurMonth + item.visitorCurMonth,
                withAgent: acc.withAgent + item.withAgent,
                withAgentCurMonth:
                  acc.withAgentCurMonth + item.withAgentCurMonth,
                withAgentSign: acc.withAgentSign + item.withAgentSign,
                callInCount: acc.callInCount + item.callInCount,
                callInDuration: acc.callInDuration + item.callInDuration,
                callOutCount: acc.callOutCount + item.callOutCount,
                callOutDuration: acc.callOutDuration + item.callOutDuration,
              }),
              {
                brokerage: 0,
                comment: 0,
                distinctComment: 0,
                loanAmount: 0,
                loanCount: 0,
                sign: 0,
                userId: 0,
                userName: '',
                userState: UserStateEnum.ACTIVE,
                visitor: 0,
                visitorCurMonth: 0,
                withAgent: 0,
                withAgentCurMonth: 0,
                withAgentSign: 0,
                callInCount: 0,
                callInDuration: 0,
                callOutCount: 0,
                callOutDuration: 0,
              },
            );
          return {
            ...value,
            departmentId: item.id,
            name: name,
            ix: ix + 1,
          };
        });
      }
      case 'person':
        const children = findAllDepartmentId(departments, departmentId).map(
          (item) => item.id,
        );
        return validData
          ?.filter(
            (item) =>
              children.includes(item.departmentId || 0) &&
              item.userState === UserStateEnum.ACTIVE,
          )
          .map((item, ix) => {
            const name = `${flatDepartment(departments, item.departmentId)
              .map((item) => item.name)
              .reverse()
              .join('/')} - ${item.userName}`;
            return {
              ...item,
              ix: ix + 1,
              name,
            };
          });
      case 'group': {
        // 找到所有的一级小组
        const selectedDepartments = findAllDepartmentId(
          departments,
          departmentId || null,
        );
        const allDeps = selectedDepartments
          .filter((item) => {
            return findChildrenDepartment(departments, item.id).length === 0;
          })
          .map((item) => item.id);

        return allDeps.map((depId, ix) => {
          const name = `${flatDepartment(departments, depId)
            .map((item) => item.name)
            .reverse()
            .join('/')}`;

          const value = validData
            .filter((item) => item.departmentId === depId)
            .reduce<HandleAnalyseDTO>(
              (acc, item) => {
                return {
                  ...item,
                  loanAmount: (
                    BigInt(acc.loanAmount) + BigInt(item.loanAmount)
                  ).toString(),
                  brokerage: (
                    BigInt(acc.brokerage) + BigInt(item.brokerage)
                  ).toString(),
                  comment: acc.comment + item.comment,
                  distinctComment: acc.distinctComment + item.distinctComment,
                  loanCount: acc.loanCount + item.loanCount,
                  sign: acc.sign + item.sign,
                  visitor: acc.visitor + item.visitor,
                  visitorCurMonth: acc.visitorCurMonth + item.visitorCurMonth,
                  withAgent: acc.withAgent + item.withAgent,
                  withAgentCurMonth:
                    acc.withAgentCurMonth + item.withAgentCurMonth,
                  withAgentSign: acc.withAgentSign + item.withAgentSign,
                  callInCount: acc.callInCount + item.callInCount,
                  callInDuration: acc.callInDuration + item.callInDuration,
                  callOutCount: acc.callOutCount + item.callOutCount,
                  callOutDuration: acc.callOutDuration + item.callOutDuration,
                };
              },
              {
                brokerage: 0,
                comment: 0,
                distinctComment: 0,
                loanAmount: 0,
                loanCount: 0,
                sign: 0,
                userId: 0,
                userName: '',
                userState: UserStateEnum.ACTIVE,
                visitor: 0,
                visitorCurMonth: 0,
                withAgent: 0,
                withAgentCurMonth: 0,
                withAgentSign: 0,
                callInCount: 0,
                callInDuration: 0,
                callOutCount: 0,
                callOutDuration: 0,
              },
            );

          return {
            ...value,
            name,
            ix: ix + 1,
          };
        });
      }
    }

    return validData?.map((item, ix) => ({
      ...item,
      name: item.userName,
      ix: ix + 1,
    }));
  }, [rawData, type, departments, departmentId]);
  return (
    <Row>
      <Col span={24}>
        <Space>
          <Segmented
            options={[
              {
                label: '个人',
                value: 'person',
              },
              {
                label: '小组',
                value: 'group',
              },
              {
                label: '区域',
                value: 'department',
              },
            ]}
            value={type}
            onChange={(v) => setType(v as Type)}
            disabled={loading}
          />
          <DepartmentSelect
            value={departmentId}
            onChange={(v) => setDepartmentId(v)}
            style={{ width: '300px' }}
            disabled={loading}
          />
          <DateRangePicker
            disabled={loading}
            value={dateRange}
            onChange={(v) =>
              setDateRange(
                (v as [dayjs.Dayjs, dayjs.Dayjs]) || defaultCreatedAt,
              )
            }
          />
          <Button type="primary" loading={loading} onClick={() => refresh()}>
            查询
          </Button>
        </Space>
      </Col>
      <Col span={24} style={{ marginTop: '20px' }}>
        <queryContext.Provider
          value={{
            createdAt: dateRange,
            type,
          }}
        >
          <ResizeTable
            className="normal-table"
            summary={(data) => {
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} align="left">
                      合计
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="right">
                      <Space>
                        <span>
                          {data?.reduce((acc, cur) => acc + cur.comment, 0)}
                        </span>
                        <span>/</span>
                        <span>
                          {data?.reduce(
                            (acc, cur) => acc + cur.distinctComment,
                            0,
                          )}
                        </span>
                      </Space>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align="right">
                      <Space>
                        <span>
                          {data?.reduce((acc, cur) => acc + cur.visitor, 0)}
                        </span>
                        <span>/</span>
                        <span>
                          {data?.reduce(
                            (acc, cur) => acc + cur.visitorCurMonth,
                            0,
                          )}
                        </span>
                      </Space>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="right">
                      <span>
                        {data?.reduce((acc, cur) => acc + cur.sign, 0)}（
                        {(
                          (data?.reduce((prev, { sign, visitor }) => {
                            return prev + sign;
                          }, 0) *
                            100.0) /
                          data?.reduce((prev, { sign, visitor }) => {
                            return prev + visitor;
                          }, 0)
                        ).toFixed(2)}
                        %）
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="right">
                      <Space>
                        <span>
                          {data?.reduce((acc, cur) => acc + cur.withAgent, 0)}
                        </span>
                        <span>/</span>
                        <span>
                          {data?.reduce(
                            (acc, cur) => acc + cur.withAgentCurMonth,
                            0,
                          )}
                        </span>
                      </Space>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align="right">
                      <Space>
                        <span>
                          {data?.reduce(
                            (acc, cur) => acc + cur.withAgentSign,
                            0,
                          )}
                        </span>
                        <span>（</span>
                        <span>
                          {(
                            (data?.reduce(
                              (acc, cur) => acc + cur.withAgentSign,
                              0,
                            ) /
                              data?.reduce(
                                (acc, cur) => acc + cur.withAgent,
                                0,
                              )) *
                            100.0
                          ).toFixed(2)}
                          %
                        </span>
                        <span>）</span>
                      </Space>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} align="right">
                      <Space>
                        <span>
                          {data?.reduce((acc, cur) => acc + cur.loanCount, 0)}
                        </span>
                        <span>（</span>
                        <Statistic
                          value={
                            data?.reduce(
                              (acc, cur) => acc + Number(cur.loanAmount),
                              0,
                            ) / 100
                          }
                          precision={2}
                          prefix="¥"
                          valueStyle={{ fontSize: '14px' }}
                        />
                        <span>）</span>
                      </Space>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} align="right">
                      <Statistic
                        prefix="¥"
                        valueStyle={{ fontSize: '14px' }}
                        value={
                          data?.reduce(
                            (acc, cur) =>
                              acc + parseInt(String(cur.brokerage), 10),
                            0,
                          ) / 100
                        }
                      />
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align="right">
                      {(
                        (data?.reduce(
                          (prev, cur) => prev + Number(cur.brokerage || 0),
                          0,
                        ) /
                          data?.reduce(
                            (prev, cur) => prev + Number(cur.loanAmount),
                            0,
                          ) || 0) * 100
                      ).toFixed(2)}
                      %
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
            loading={loading}
            columns={columns.map((item) => {
              const align = item.align || 'right';
              return {
                ...item,
                align,
              };
            })}
            dataSource={data}
            size="small"
            bordered
            pagination={false}
            rowKey={'ix'}
          />
        </queryContext.Provider>
      </Col>
    </Row>
  );
};
