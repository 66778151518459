import {
  ProCard,
  StatisticCard,
  StatisticCardProps,
} from '@ant-design/pro-components';
import { useRequest } from 'ahooks';
import {
  Button,
  Card,
  Col,
  List,
  Row,
  Space,
  Table,
  Avatar,
  Popover,
  Tooltip,
} from 'antd';
import { useCompany } from '../../hooks/company';
import {
  HomeAnalyseItemDTO,
  Long,
  UserStateEnum,
  dataAnalyzeController,
  roleController,
  userController,
} from '../../api';
import { Line, Column, Pie } from '@ant-design/plots';
import '../../styles/welcome.scss';
import '../../styles/common.scss';
import img01 from '../../../src/assets/img01.png';
import img02 from '../../../src/assets/img02.png';
import img03 from '../../../src/assets/img03.png';
import img04 from '../../../src/assets/img04.png';
import img05 from '../../../src/assets/img05.png';
import img06 from '../../../src/assets/img06.png';
import { useMemo, useState } from 'react';
import { ColumnType } from 'antd/es/table';
import DepartmentSelect from '../../components/DepartmentSelect';
import {
  findAllDepartmentId,
  findChildrenDepartment,
  flatDepartment,
} from '../../utils';
import {
  DownSquareOutlined,
  UpSquareOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import award01 from '../../assets/award01.png';
import award02 from '../../assets/award02.png';
import award03 from '../../assets/award03.png';
import team01 from '../../assets/team01.png';
import team02 from '../../assets/team02.png';
import team03 from '../../assets/team03.png';
import { AvatarImg } from '../../components/OSSImage';
import dayjs from 'dayjs';

const { Statistic } = StatisticCard;

const ValueStatistic = ({
  current,
  prev,
  title,
  isMoney,
  ...props
}: { current?: Long; prev?: Long; isMoney?: boolean } & StatisticCardProps) => {
  const p = Number(prev || 0);
  const c = Number(current || 0);

  const percentage = `${
    p === 0 ? 100 : ((Math.abs(p - c) / p) * 100.0).toFixed(2)
  }%`;
  const trend = c > p ? 'up' : 'down';

  return (
    <StatisticCard
      className="number-card"
      {...props}
      footer={
        <Space direction="vertical">
          <Statistic
            title="环比"
            value={percentage}
            trend={trend}
            tip="相比昨日"
          />
        </Space>
      }
      statistic={{
        title,
        prefix: isMoney ? '￥' : undefined,
        value: `${current}`,
      }}
    />
  );
};

const Indicators = () => {
  const { id: companyId, departments } = useCompany();

  const { data, loading } = useRequest(
    () => {
      return dataAnalyzeController.welcomeCoreHeader(
        companyId,
        departments.map((item) => item.id),
      );
    },
    {
      refreshDeps: [companyId, departments],
    },
  );
  return (
    <div className="welcome-top-box">
      <Col span={24} style={{ padding: '20px' }}>
        <Row className="top-box-row01">
          <Col span={8} className="inner-card border-right-bottom">
            <ProCard>
              <ValueStatistic
                title="放款总额"
                isMoney
                prev={Number(data?.prevMonth?.loanAmount || 0) / 100}
                current={Number(data?.currentMonth.loanAmount || 0) / 100}
              />
              <span className="img-box">
                <img src={img01} alt="" />
              </span>
            </ProCard>
          </Col>
          <Col span={8} className="inner-card border-right-bottom">
            <ProCard>
              <ValueStatistic
                title="放款次数"
                prev={data?.prevMonth?.loanCount}
                current={data?.currentMonth.loanCount}
              />
              <span className="img-box">
                <img src={img02} alt="" />
              </span>
            </ProCard>
          </Col>
          <Col span={8} className="inner-card border-bottom">
            <ProCard>
              <ValueStatistic
                title="佣金总额"
                isMoney
                prev={Number(data?.prevMonth?.brokerage || 0) / 100}
                current={Number(data?.currentMonth.brokerage || 0) / 100}
              />
              <span className="img-box">
                <img src={img03} alt="" />
              </span>
            </ProCard>
          </Col>
        </Row>
        <Row className="top-box-row02">
          <Col span={8} className="inner-card border-right">
            <ProCard>
              <ValueStatistic
                title="上门次数"
                prev={data?.prevMonth?.visitorCount}
                current={data?.currentMonth.visitorCount}
              />
              <span className="img-box">
                <img src={img04} alt="" />
              </span>
            </ProCard>
          </Col>
          <Col span={8} className="inner-card border-right">
            <ProCard>
              <ValueStatistic
                title="客户数量"
                prev={data?.prevMonth?.customerCount}
                current={data?.currentMonth.customerCount}
              />
              <span className="img-box">
                <img src={img05} alt="" />
              </span>
            </ProCard>
          </Col>
          <Col span={8} className="inner-card">
            <ProCard>
              <ValueStatistic
                title="签约数量"
                prev={data?.prevMonth?.signCount}
                current={data?.currentMonth.signCount}
              />
              <span className="img-box">
                <img src={img06} alt="" />
              </span>
            </ProCard>
          </Col>
        </Row>
      </Col>
    </div>
  );
};
const Header = () => {
  const { id: companyId, departments } = useCompany();

  const { data, loading } = useRequest(
    () => {
      return dataAnalyzeController.welcomeCoreHeader(
        companyId,
        departments.map((item) => item.id),
      );
    },
    {
      refreshDeps: [companyId, departments],
    },
  );

  return (
    <Row className="welcome-top-box">
      <Col span={24}>
        <Top />
      </Col>
    </Row>
    // <ProCard.Group
    //   title="核心指标"
    //   tooltip="今日数据"
    //   direction="row"
    //   loading={loading}
    //   bordered
    // >

    // </ProCard.Group>
  );
};
{
  /* <Col span={8}>
          <ProCard>
            <Pie
              height={300}
              data={[
                { type: '0星', value: data?.currentMonth.star0 || 0 },
                { type: '1星', value: data?.currentMonth.star1 || 0 },
                { type: '2星', value: data?.currentMonth.star2 || 0 },
                { type: '3星', value: data?.currentMonth.star3 || 0 },
                { type: '4星', value: data?.currentMonth.star4 || 0 },
                { type: '5星', value: data?.currentMonth.star5 || 0 },
              ]}
              appendPadding={10}
              angleField="value"
              colorField="type"
              radius={1}
              label={{
                type: 'outer',
                offset: '-30%',
                content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
                style: {
                  fontSize: 14,
                  textAlign: 'center',
                },
              }}
              interactions={[{ type: 'element-active' }]}
            />
          </ProCard>
        </Col> */
}

const MonthlyChart = () => {
  const { id: companyId, departments } = useCompany();

  const { data, loading } = useRequest(
    () => {
      return dataAnalyzeController
        .listMonthlyData(
          companyId,
          departments.map((item) => item.id),
        )
        .then((v) => v.reverse());
    },
    {
      refreshDeps: [companyId, departments],
    },
  );

  return (
    <ProCard className="welcome-bottom-box" bordered title="月度趋势">
      <Row>
        <Col span={6}>
          <ProCard title="放款金额">
            <Column
              height={200}
              loading={loading}
              xField="x"
              yField="loanAmount"
              padding="auto"
              tooltip={{
                formatter: (v) => {
                  return {
                    name: '放款金额',
                    value: `${v.loanAmount.toFixed(2)}万`,
                  };
                },
              }}
              yAxis={{
                label: {
                  formatter(text, item, index) {
                    return `${text}万`;
                  },
                },
              }}
              xAxis={{
                tickCount: 12,
              }}
              data={
                data?.map((item) => ({
                  ...item,
                  x: `${item.year}-${item.month}`,
                  loanAmount: Number(item.loanAmount || 0) / 10000 / 100,
                })) || []
              }
            />
          </ProCard>
        </Col>
        <Col span={6}>
          <ProCard title="佣金金额">
            <Column
              height={200}
              loading={loading}
              xField="x"
              yField="brokerage"
              padding="auto"
              tooltip={{
                formatter: (v) => {
                  return {
                    name: '佣金金额',
                    value: `${v.brokerage.toFixed(2)}万`,
                  };
                },
              }}
              yAxis={{
                label: {
                  formatter(text, item, index) {
                    return `${text}万`;
                  },
                },
              }}
              xAxis={{
                tickCount: 12,
              }}
              data={
                data?.map((item) => ({
                  ...item,
                  x: `${item.year}-${item.month}`,
                  brokerage: Number(item.brokerage || 0) / 10000 / 100,
                })) || []
              }
            />
          </ProCard>
        </Col>
        <Col span={6}>
          <ProCard title="放款次数">
            <Line
              height={200}
              loading={loading}
              xField="x"
              yField="loanCount"
              padding="auto"
              tooltip={{
                formatter: (v) => {
                  return {
                    name: '放款次数',
                    value: `${v.loanCount}`,
                  };
                },
              }}
              yAxis={{
                label: {
                  formatter(text, item, index) {
                    return `${text}`;
                  },
                },
              }}
              xAxis={{
                tickCount: 12,
              }}
              data={
                data?.map((item) => ({
                  ...item,
                  x: `${item.year}-${item.month}`,
                })) || []
              }
            />
          </ProCard>
        </Col>
        <Col span={6}>
          <ProCard title="签约数量">
            <Line
              height={200}
              loading={loading}
              xField="x"
              yField="signCount"
              padding="auto"
              tooltip={{
                formatter: (v) => {
                  return {
                    name: '签约数量',
                    value: `${v.signCount}`,
                  };
                },
              }}
              yAxis={{
                label: {
                  formatter(text, item, index) {
                    return `${text}`;
                  },
                },
              }}
              xAxis={{
                tickCount: 12,
              }}
              data={
                data?.map((item) => ({
                  ...item,
                  x: `${item.year}-${item.month}`,
                })) || []
              }
            />
          </ProCard>
        </Col>
      </Row>
    </ProCard>
  );
};

const column: ColumnType<HomeAnalyseItemDTO>[] = [
  {
    title: '',
    dataIndex: 'name',
  },
  {
    title: '推送客户',
    dataIndex: 'rmsCustomer',
  },
  {
    title: '跟进次数/人数',
    render: (dto: HomeAnalyseItemDTO) => {
      return (
        <span>
          {dto.comment || 0}/{dto.distinctComment || 0}
        </span>
      );
    },
  },
  {
    title: '放款次数/总额',
    render: (dto: HomeAnalyseItemDTO) => {
      return (
        <span>
          {dto.loanCount || 0}/
          {((Number(dto.loanAmount) || 0) / 100).toFixed(2)}
        </span>
      );
    },
  },
  {
    title: '上门量',
    dataIndex: 'visitor',
  },
];

const Summary = () => {
  const {
    id: companyId,
    departments,
    hasPermission,
    currentUser,
  } = useCompany();

  const [selectedDep, setSelectedDep] = useState<I.ID>();

  const showSelf = useMemo(() => {
    if (currentUser.isSuperAdmin) {
      return false;
    }
    return hasPermission('page:visitor:showSelf');
  }, [hasPermission]);

  const {
    data: rawData,
    loading,
    refresh,
  } = useRequest(
    () => {
      if (showSelf) {
        return dataAnalyzeController.newDataAnalyzeSummary(companyId);
      }
      const deps = findAllDepartmentId(departments, selectedDep || null);
      return dataAnalyzeController.newDataAnalyzeSummary(
        companyId,
        deps.map((item) => item.id),
      );
    },
    {
      refreshDeps: [companyId, departments, selectedDep, showSelf],
    },
  );

  const data = useMemo(() => {
    return [
      {
        name: '今日',
        ...rawData?.today,
      },
      {
        name: '昨日',
        ...rawData?.yesterday,
      },
      {
        name: '本周',
        ...rawData?.week,
      },
      {
        name: '本月',
        ...rawData?.month,
      },
    ];
  }, [rawData]);
  return (
    <Row
      className="shadow-box-normal welcome-select-box"
      style={{ height: '311px' }}
    >
      {!showSelf && (
        <Col span={24}>
          <DepartmentSelect
            style={{ width: '100%', borderRadius: '1px' }}
            value={selectedDep}
            onSelect={setSelectedDep}
            onClear={() => setSelectedDep(undefined)}
          />
        </Col>
      )}
      <Col span={24} style={{ height: `${!showSelf ? '270px' : '311px'}` }}>
        <Table
          // className="normal-table"
          scroll={{ y: !showSelf ? 160 : 200 }}
          size="small"
          loading={loading}
          dataSource={data}
          columns={column}
          pagination={false}
        />
      </Col>
    </Row>
  );
};

const Top = () => {
  const {
    id: companyId,
    allDepartments,
    allCompanyDepartments,
    config: companyConfig,
    currentUser: { isSuperAdmin },
  } = useCompany();

  const [withCompany, setWithCompany] = useState<boolean>(true);

  const enableGroupSwitch = useMemo(() => {
    if (!companyConfig?.enableGroupHomeTop) {
      return false;
    }
    const dayOfWeek = dayjs().day();
    return dayOfWeek === 1 || isSuperAdmin;
  }, [companyConfig?.enableGroupHomeTop]);

  const { data: result, loading } = useRequest(
    () => {
      return Promise.all([
        dataAnalyzeController.homeTop(companyId, !withCompany),
        userController.listAllUser(companyId),
        roleController.listAll(companyId),
      ]).then(([homeTop, allUsers, roles]) => ({
        data: homeTop,
        allUsers,
        roles,
      }));
    },
    {
      refreshDeps: [companyId, withCompany],
    },
  );

  const { data, allUsers, roles } = result || {};

  const [expand, setExpan] = useState<{
    personal?: boolean;
    group?: boolean;
  }>();

  const departments = useMemo(() => {
    if (withCompany) {
      return allDepartments;
    }
    return allCompanyDepartments;
  }, [allCompanyDepartments, allDepartments, withCompany]);

  const onExpand = (type: 'p' | 'g') => {
    setExpan((prev) => {
      if (type === 'p') {
        return {
          ...prev,
          personal: !prev?.personal,
        };
      } else {
        return {
          ...prev,
          group: !prev?.group,
        };
      }
    });
  };

  const personal = useMemo(() => {
    if (companyConfig?.disableIndexRank) {
      return null;
    }
    return (
      data
        ?.sort((a, b) => (a.brokerage > b.brokerage ? -1 : 1))
        .slice(0, expand?.personal ? 10 : 3) || []
    ).map((item) => ({
      depName: flatDepartment(allCompanyDepartments, item.departmentId)
        .reverse()
        .map((dep) => dep.name)
        .join('/'),
      ...item,
    }));
  }, [
    data,
    expand?.personal,
    allCompanyDepartments,
    companyConfig?.disableIndexRank,
  ]);

  const group = useMemo(() => {
    if (companyConfig?.disableIndexRank) {
      return null;
    }
    // 找到所有的小组，也就是没有 children 的部门
    const allDeps = departments
      .filter((item) => {
        return findChildrenDepartment(departments, item.id).length === 0;
      })
      .map((item) => item.id);

    return (
      allDeps
        .map((depId) => {
          const users =
            data?.filter((item) => item.departmentId === depId) || [];
          // 找到属于这个部门的第一个人，然后用他的 ID 作为头像

          const findUser = allUsers?.find((item) => {
            if (item.state !== UserStateEnum.ACTIVE) {
              return false;
            }
            if (item.departmentId !== depId) {
              return false;
            }

            const role = roles?.find((role) => role.id === item.roleId);
            if (!role) {
              return false;
            }

            if (
              !role.permissions?.find((p) => p.code === 'system:team:avatar')
            ) {
              return false;
            }

            return true;
          });
          return {
            userId: findUser?.id,
            depId,
            depName: flatDepartment(departments, depId)
              .reverse()
              .map((d) => d.name)
              .join('/'),
            brokerage: users.reduce(
              (prev, cur) => prev + Number(cur.brokerage),
              0,
            ),
          };
        })
        .sort((a, b) => (a.brokerage > b.brokerage ? -1 : 1))
        .slice(0, expand?.group ? 10 : 3) || []
    );
  }, [
    data,
    expand?.group,
    departments,
    allUsers,
    companyConfig?.disableIndexRank,
    roles,
  ]);

  const getAvator = (index: number, type: string) => {
    if (type === 'person') {
      if (index === 0) {
        return <Avatar src={award01}></Avatar>;
      }
      if (index === 1) {
        return <Avatar src={award02}></Avatar>;
      }
      if (index === 2) {
        return <Avatar src={award03}></Avatar>;
      }
    }
    if (type === 'team') {
      if (index === 0) {
        return <Avatar src={team01}></Avatar>;
      }
      if (index === 1) {
        return <Avatar src={team02}></Avatar>;
      }
      if (index === 2) {
        return <Avatar src={team03}></Avatar>;
      }
    }

    return <span></span>;
  };

  return (
    <Row gutter={20}>
      <Col span={12}>
        <Card
          title={`本月个人排行榜${withCompany ? '' : '（集团）'}`}
          className="shadow-box-normal welcome-card-box"
          extra={
            <Space>
              {enableGroupSwitch && (
                <SwapOutlined onClick={() => setWithCompany((p) => !p)} />
              )}
              {/* {expand?.personal ? (
                <UpSquareOutlined onClick={() => onExpand('p')} />
              ) : (
                <DownSquareOutlined onClick={() => onExpand('p')} />
              )} */}
            </Space>
          }
        >
          <List
            className="card-box-person-box card-box-list-box"
            style={{ height: '180px', overflow: 'auto' }}
          >
            {personal?.map((item, index) => (
              <List.Item
                key={item.userId}
                extra={
                  <Statistic value={item.brokerage} precision={2} prefix="$" />
                }
              >
                <List.Item.Meta
                  // avatar={getAvator(index, 'team')}
                  avatar={
                    <Space>
                      {getAvator(index, 'team')}
                      <Popover
                        placement="right"
                        content={<AvatarImg userId={item.userId} size={200} />}
                      >
                        <AvatarImg userId={item.userId} />
                      </Popover>
                    </Space>
                  }
                  title={item.userName}
                  description={item.depName}
                />
              </List.Item>
            ))}
          </List>
        </Card>
      </Col>
      <Col span={12}>
        <Card
          className="shadow-box-normal welcome-card-box"
          title={`本月团队排行榜${withCompany ? '' : '（集团）'}`}
          extra={
            <Space>
              {enableGroupSwitch && (
                <SwapOutlined onClick={() => setWithCompany((p) => !p)} />
              )}
              {/* {expand?.group ? (
                <UpSquareOutlined onClick={() => onExpand('g')} />
              ) : (
                <DownSquareOutlined onClick={() => onExpand('g')} />
              )} */}
            </Space>
          }
        >
          <List
            className="card-box-team-box card-box-list-box"
            style={{ height: '180px', overflow: 'auto' }}
          >
            {group?.map((item, index) => (
              <List.Item
                key={item.depName}
                extra={
                  <Statistic value={item.brokerage} precision={2} prefix="$" />
                }
              >
                <List.Item.Meta
                  // avatar={getAvator(index, 'team')}
                  avatar={
                    <Space>
                      {getAvator(index, 'team')}
                      {/* {item.userId}-{item.depId} */}
                      {item.userId ? (
                        <Popover
                          placement="right"
                          content={
                            <AvatarImg userId={item.userId} size={200} />
                          }
                        >
                          <AvatarImg userId={item.userId} />
                        </Popover>
                      ) : (
                        <Avatar />
                      )}
                    </Space>
                  }
                  title={item.depName}
                />
              </List.Item>
            ))}
          </List>
        </Card>
      </Col>
    </Row>
  );
};

export default () => {
  return (
    <Row style={{ minWidth: '880px' }}>
      <Col span={24}>
        <Header />
      </Col>
      <Col span={24} style={{ padding: '20px 0px' }}>
        <Row gutter={20}>
          <Col span={8}>
            <Summary />
          </Col>
          <Col span={16}>
            <Indicators />
          </Col>
        </Row>
      </Col>
      <Col span={24} className="shadow-box-normal">
        <MonthlyChart />
      </Col>
    </Row>
  );
};
