import { Button, Dropdown, Input, MenuProps, Modal, Spin, message } from 'antd';
import { useContext, useEffect, useMemo, useState } from 'react';
import CustomerDetail, { userRefreshContext } from './CustomerDetail';
import { useRequest } from 'ahooks';
import {
  CustomerEventTypeEnum,
  CustomerPoolEnum,
  blackListController,
  customerController,
} from '../api';
import { useCompany } from '../hooks/company';
import AddLoan from './AddLoan';
import CustomerTransfer from './CustomerTransfer';

const Comment = ({
  id,
  open,
  onCancel,
}: {
  id: I.ID;
  open?: boolean;
  onCancel: () => void;
}) => {
  const { id: companyId } = useCompany();

  const { refresh } = userRefreshContext();

  const {
    data,
    loading: getLoading,
    run: getInfo,
  } = useRequest(
    () => {
      return customerController.getDetail(companyId, id);
    },
    {
      refreshDeps: [id, companyId],
      manual: true,
    },
  );

  const { loading: addCommentLoading, run: onSubmit } = useRequest(
    (comment: string) => {
      return customerController.addLeaderEvent(companyId, id, comment);
    },
    {
      manual: true,
      onSuccess: () => {
        message.success('点评成功');
        refresh?.();
        onCancel();
      },
      onError: (e) => message.error(`操作失败：${e.message}`),
      refreshDeps: [id, companyId],
    },
  );

  const [comment, setComment] = useState<string>();

  useEffect(() => {
    setComment(undefined);
    if (open) {
      getInfo();
    }
  }, [id, open, getInfo]);

  const loading = getLoading || addCommentLoading;

  return (
    <Modal
      confirmLoading={loading}
      open={open}
      onOk={() => {
        if (comment) {
          onSubmit(comment);
        }
      }}
      onCancel={onCancel}
      title={`点评 ${data?.name}（${data?.mobile}）`}
    >
      <Spin spinning={loading}>
        <Input.TextArea
          rows={5}
          value={comment}
          onChange={(v) => setComment(v.target.value)}
        />
      </Spin>
    </Modal>
  );
};

const AddCustomerToBlackList = ({
  id,
  open,
  onCancel,
}: {
  id: I.ID;
  open?: boolean;
  onCancel: () => void;
}) => {
  const [remark, setRemark] = useState<string>('');
  const { id: companyId } = useCompany();

  const { refresh } = userRefreshContext();

  const {
    data,
    loading: getLoading,
    run: getInfo,
  } = useRequest(
    () => {
      return customerController.getDetail(companyId, id);
    },
    {
      manual: true,
    },
  );

  const { loading: addBlackListLoading, run: addBlackList } = useRequest(
    (id: I.ID, remark: string) => {
      return blackListController.add(companyId, id, remark);
    },
    {
      manual: true,
      onSuccess: () => {
        message.success('加入黑名单成功');
        onCancel();
        refresh?.();
      },
      onError: (e) => message.error(`操作失败：${e.message}`),
    },
  );

  useEffect(() => {
    if (open) {
      getInfo();
      setRemark('');
    }
  }, [open]);

  const loading = getLoading || addBlackListLoading;

  return (
    <Modal
      confirmLoading={loading}
      open={open}
      onOk={() => {
        if (data?.id) {
          addBlackList(data.id, remark);
        }
      }}
      onCancel={onCancel}
      title={`将${data?.name}（${data?.mobile}）加入黑名单`}
    >
      <Input.TextArea
        rows={4}
        value={remark}
        onChange={(v) => setRemark(v.target.value)}
      />
    </Modal>
  );
};

export default ({
  id,
  pool,
  name,
  canDropToBlackList,
  canUpdateCustomer,
  canEditCustomerName,
  canAddLoan,
  canUpdateScore,
  showMobile,
  score,
}: {
  id: I.ID;
  pool: CustomerPoolEnum;
  name: string;
  score?: number;
  canDropToBlackList?: boolean;
  canUpdateCustomer?: boolean;
  canEditCustomerName?: boolean;
  canUpdateScore: boolean;
  canAddLoan?: boolean;
  showMobile?: boolean;
}) => {
  const [detailOpen, setDetailOpen] = useState<boolean>(false);
  const [commentOpen, setCommentOpen] = useState<boolean>(false);
  const [addLoanOpen, setAddLoanOpen] = useState<boolean>(false);
  const [blackListOpen, setBalckListOpen] = useState<boolean>(false);
  const [transferOpen, setTransferOpen] = useState<boolean>(false);

  const { currentUser, id: companyId } = useCompany();
  const { refresh } = userRefreshContext();

  const { run: updateScore } = useRequest(
    (score: number) => {
      return customerController.updateScore(companyId, id, score);
    },
    {
      manual: true,
      onError: (e) => message.error(`操作失败：${e.message}`),
      refreshDeps: [id, companyId],
      onSuccess: () => {
        message.success('操作成功');
        refresh?.();
      },
    },
  );

  const items = useMemo(() => {
    const fullItems = [
      {
        label: '修改客户资料',
        key: 'updateCustomer',
        hide: !canUpdateCustomer,
      },
      {
        label: '点评',
        key: 'comment',
        hide: pool === CustomerPoolEnum.PUBLIC,
      },
      {
        label: '加入黑名单',
        key: 'dropToBlackList',
        hide: !canDropToBlackList,
      },
      {
        label: '添加放款',
        key: 'addLoan',
        hide: !canAddLoan,
      },
      {
        label: '转移',
        key: 'transfer',
        hide: !currentUser.isSuperAdmin || pool !== CustomerPoolEnum.BIZ,
      },
      {
        label: (score || 0) >= 90 ? '取消优质' : '设为优质',
        key: 'update_score',
        hide: !canUpdateScore,
      },
    ];

    return fullItems
      .filter((item) => !item.hide)
      .map(({ label, key }) => {
        return {
          label,
          key,
        };
      });
  }, [
    pool,
    currentUser.isSuperAdmin,
    canUpdateCustomer,
    canDropToBlackList,
    canAddLoan,
    score,
  ]);

  return (
    <>
      {!!items.length ? (
        <Dropdown
          menu={{
            items,
            onClick: ({ key }) => {
              switch (key) {
                case 'updateCustomer':
                  setDetailOpen(true);
                  break;
                case 'comment':
                  setCommentOpen(true);
                  break;
                case 'dropToBlackList':
                  setBalckListOpen(true);
                  break;
                case 'addLoan':
                  setAddLoanOpen(true);
                  break;
                case 'transfer':
                  setTransferOpen(true);
                  break;
                case 'update_score':
                  updateScore((score || 0) >= 90 ? 0 : 90);
                  break;
              }
            },
          }}
        >
          <Button type="primary">操作</Button>
        </Dropdown>
      ) : (
        '-'
      )}
      <CustomerDetail
        id={id}
        open={detailOpen}
        onCancel={() => setDetailOpen(false)}
        showMobile={showMobile}
        updateCustomerNamePermission={canEditCustomerName}
        withComment={false}
      />
      <Comment
        open={commentOpen}
        onCancel={() => setCommentOpen(false)}
        id={id}
      />
      <AddLoan
        name={name}
        open={addLoanOpen}
        id={id}
        onCancel={() => setAddLoanOpen(false)}
      />
      <AddCustomerToBlackList
        id={id}
        open={blackListOpen}
        onCancel={() => setBalckListOpen(false)}
      />
      <CustomerTransfer
        customerId={id}
        open={transferOpen}
        onCancel={() => setTransferOpen(false)}
      />
    </>
  );
};
