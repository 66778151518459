import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CustomerGroupEnum,
  CustomerListItemDTO,
  CustomerPoolEnum,
  ListCustomerType,
  customerController,
} from '../../api';
import { useRequest } from 'ahooks';
import { useH5Company } from './Layout';
import {
  InfiniteScroll,
  List,
  Loading,
  Mask,
  Rate,
  SpinLoading,
  Tag,
} from 'antd-mobile';
import CustomerMobile from '../../components/CustomerMobile';
import { customerStateMapping } from '../../constants';
import { useLocation, useNavigate } from 'react-router-dom';

const pageSize = 50;

export default ({ group }: { group: CustomerGroupEnum }) => {
  const { company, setTitle } = useH5Company();

  const location = useLocation();

  useEffect(() => {
    setTitle('我的客户');
  }, [group, setTitle]);

  const [list, setList] = useState<CustomerListItemDTO[]>([]);

  const navigate = useNavigate();

  const [nextPage, setNextPage] = useState<number>();

  const {
    data,
    loading,
    runAsync: load,
  } = useRequest(
    (page?: number) => {
      return customerController.listCustomer(
        company.id,
        {
          pool: CustomerPoolEnum.BIZ,
        },
        ListCustomerType.MY,
        group,
        page || 1,
        pageSize,
      );
    },
    {
      ready: !!company.id,
      manual: true,
      onSuccess: (v) => {
        setNextPage((prev) => {
          const current = prev || 0 + 1;
          if (current * v.size < (v.total || 0)) {
            return current + 1;
          }
          return undefined;
        });
      },
    },
  );

  useEffect(() => {
    if (company.id) {
      load();
    }
  }, [group, company.id]);

  useEffect(() => {
    setList((prev) => {
      return [...prev, ...(data?.list || [])];
    });
  }, [data, setList]);

  const hasMore = useMemo(() => {
    return !!nextPage;
  }, [nextPage]);

  const loadMore = useCallback(
    (retry: boolean) => {
      console.log(nextPage);
      return load(nextPage).then(() => {});
    },
    [load, nextPage],
  );
  return (
    <>
      <Mask visible={loading}>
        <div className="mobile-loading">
          <SpinLoading style={{ '--size': '48px' }} />
        </div>
      </Mask>
      <List>
        {list?.map((customer) => {
          return (
            <List.Item
              key={customer.id}
              onClick={() => {
                navigate(
                  `/h5/customer/${customer.id}?prePath=${encodeURIComponent(
                    `${location.pathname}?group=${group}`,
                  )}`,
                );
              }}
              extra={<Tag>{customerStateMapping[customer.state]}</Tag>}
              title={<CustomerMobile mobile={customer.mobile} />}
              description={<Rate readOnly defaultValue={customer.star} />}
            >
              {customer.name}
            </List.Item>
          );
        })}
      </List>
      <InfiniteScroll hasMore={hasMore} loadMore={loadMore} />
    </>
  );
};
