import { useBoolean, useRequest } from 'ahooks';
import { useCompany } from '../hooks/company';
import { EventDTO, customerController } from '../api';
import { Button, Drawer, Space, Spin, Tag, Timeline } from 'antd';
import { customerEventMapping } from '../constants';
import UserInfoModal from './UserInfoModal';
import dayjs from 'dayjs';
import '../styles/common.scss';
import { useEffect } from 'react';

export const LastEventDrawer = ({
  customerId,
  customerName,
  open,
  onCancel,
}: {
  customerId: I.ID;
  customerName: string;
  open?: boolean;
  onCancel: () => void;
}) => {
  const { id: companyId } = useCompany();

  const { data, loading, run } = useRequest(
    () => {
      return customerController.listEvent(companyId, customerId);
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (open) {
      run();
    }
  }, [open]);

  const events = data?.sort((a, b) =>
    (a?.createdAt || '') > (b?.createdAt || '') ? -1 : 1,
  );

  return (
    <Drawer
      className="drawer-box"
      open={open}
      zIndex={99999}
      onClose={onCancel}
      title={customerName}
    >
      <Spin spinning={loading}>
        <Timeline
          items={events?.map((item) => ({
            children: (
              <Space direction="vertical">
                <span>
                  {item.userId && (
                    <UserInfoModal id={item.userId} name={item.userName} />
                  )}
                  <Tag>{customerEventMapping[item.type!]}</Tag>
                  <span>
                    {dayjs(item?.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                  </span>
                </span>
                <span
                  style={{
                    whiteSpace: 'pre-line',
                    fontWeight: '500',
                  }}
                >
                  {item?.comment}
                </span>
                {/* <span>{item?.remark}</span> */}
              </Space>
            ),
          }))}
        />
      </Spin>
    </Drawer>
  );
};

export default ({
  event,
  customerId,
  customerName,
  highlight,
}: {
  event?: EventDTO;
  customerId: I.ID;
  customerName: string;
  highlight?: boolean;
}) => {
  const { id: companyId } = useCompany();

  const [open, { toggle }] = useBoolean();

  const { data, loading, run } = useRequest(
    () => {
      return customerController.listEvent(companyId, customerId);
    },
    {
      manual: true,
    },
  );

  const events = data?.sort((a, b) =>
    (a?.createdAt || '') > (b?.createdAt || '') ? -1 : 1,
  );

  if (!event?.userId && event?.userId !== 0) {
    return null;
  }

  return (
    <>
      <LastEventDrawer
        open={open}
        onCancel={toggle}
        customerName={customerName}
        customerId={customerId}
      />
      <Space direction="vertical">
        <Button
          style={{ color: highlight ? 'red' : undefined, padding: 0 }}
          size="small"
          type="link"
          onClick={() => {
            toggle();
            run();
          }}
        >
          {event?.userName || '系统'}@
          {dayjs(event?.createdAt).format('YYYY-MM-DD HH:mm:ss')}
        </Button>
        <span style={{ whiteSpace: 'pre-line' }}>{event?.comment}</span>
        {/* <span>{event?.remark}</span> */}
      </Space>
    </>
  );
};
