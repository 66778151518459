import { UserDTO } from '../../api';
import CRMApi, { apiHost } from '../request';

const request = new CRMApi();

export const getCompany = (id: I.ID) =>
  request.get<I.Company>(`/user/company/${id}`);

export const getCurrentUser = () => {
  return request.get<I.User>(`/user/current`);
};

export const getSystemConfig = () => {
  return request.get<I.SystemConfig>('/user/system/config');
};

export const login = (
  companyId: I.ID,
  data: { username: string; password: string; code: string },
) => {
  const form = new FormData();
  form.set('companyId', companyId.toString());
  Object.entries(data).forEach(([key, value]) => {
    form.set(key, value);
  });
  return fetch(`${apiHost}/user/login`, {
    method: 'POST',
    credentials: 'include',
    body: form,
  }).then((res) => {
    if (res.status !== 200) {
      return res.json().then((data) => {
        throw new Error(data.message || '登录失败');
      });
    }
  });
};

export const listSystemUser = (
  companyId: I.ID,
  data?: {
    name?: string;
    roleId?: I.ID;
    departmentId?: I.ID;
    userStates?: I.UserState;
  } & I.PageParams,
) => {
  return request.get<I.PageResult<UserDTO>>(`/company/${companyId}/user`, {
    data,
  });
};

export const listRole = (companyId: I.ID) => {
  return request.get<I.Role[]>(`/company/${companyId}/role`);
};

export const addRole = (
  companyId: I.ID,
  data: { name: string; description?: string },
) => {
  return request.post(`/company/${companyId}/role`, { data });
};

export const updateRole = (
  companyId: I.ID,
  roleId: I.ID,
  data: { name: string; description?: string },
) => {
  return request.put(`/company/${companyId}/role/${roleId}`, { data });
};

export const listRolePermission = (companyId: I.ID, roleId: I.ID) => {
  return request.get<I.RolePermission[]>(
    `/company/${companyId}/role/${roleId}/permission`,
  );
};

export const updateRolePermission = (
  companyId: I.ID,
  roleId: I.ID,
  data: string[],
) => {
  return request.post(`/company/${companyId}/role/${roleId}/permission`, {
    data,
  });
};

export const listDepartment = (companyId: I.ID) => {
  return request.get<I.Department[]>(`/company/${companyId}/department`);
};

export const addUser = (
  companyId: I.ID,
  data: { name: string; mobile: string; roleId: I.ID; departmentId?: I.ID },
) => {
  return request.post(`/company/${companyId}/user`, { data });
};

export const updateUser = (
  companyId: I.ID,
  id: I.ID,
  data: {
    name: string;
    mobile: string;
    reoleId?: I.ID;
    departmentId?: I.ID;
    state: I.UserState;
  },
) => {
  return request.put(`/company/${companyId}/user/${id}`, { data });
};

export const addDepartment = (
  companyId: I.ID,
  data: {
    name: string;
    descripton?: string;
    parentId?: I.ID;
  },
) => {
  return request.post(`/company/${companyId}/department`, {
    data,
  });
};

export const updateDepartment = (
  companyId: I.ID,
  depId: I.ID,
  data: {
    name: string;
    descriptuon?: string;
    parentId?: string;
  },
) => {
  return request.put(`/company/${companyId}/department/${depId}`, {
    data,
  });
};
