import { Space, theme } from 'antd';
import { CloseOutlined, CloseCircleOutlined } from '@ant-design/icons';
import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAliveController } from 'react-activation';
import { MenuRouter } from './layouts/router';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import '../styles/tabNav.scss';
import { useRequest } from 'ahooks';
import {
  CustomerGroupEnum,
  CustomerPoolEnum,
  ListCustomerType,
  customerController,
} from '../api';
import { useCompany } from '../hooks/company';

const CloseButton = ({
  onClick,
  disabled,
  style,
}: {
  onClick?: MouseEventHandler<HTMLElement> | undefined;
  style?: React.CSSProperties;
  disabled?: boolean;
}) => {
  const [visible, setVisible] = useState(false);
  return (
    <span
      className="close-btn"
      onClick={onClick}
      style={{ cursor: disabled ? 'default' : 'pointer', ...style }}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {/* {visible && !disabled ? <CloseCircleOutlined /> : <CloseOutlined />} */}
      <CloseOutlined />
    </span>
  );
};

interface TabItemProps {
  name: string;
  active?: boolean;
  onClick?: () => void;
  onClose?: () => void;
  node: object;
}

const TabItem = ({ name, active, onClick, onClose, node }: TabItemProps) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const id = (node as { id: string }).id;
  const showCloseBtn = id !== 'welcome';

  const group = useMemo(() => {
    switch ((node as any).id) {
      case 'new-customer':
        return CustomerGroupEnum.NEW_CUSTOMER;
      case 'new_allot':
        return CustomerGroupEnum.NEW_ALLOT;
      case 'expand':
        return CustomerGroupEnum.EXPAND;
      case 'mortgage':
        return CustomerGroupEnum.MORTGAGE;
      case 'locked':
        return CustomerGroupEnum.LOCKED;
      case 'a':
        return CustomerGroupEnum.A;
      case 'b':
        return CustomerGroupEnum.B;
      case 'c':
        return CustomerGroupEnum.C;
      case 'd':
        return CustomerGroupEnum.D;
      case 'e':
        return CustomerGroupEnum.E;
      default:
        return undefined;
    }
  }, [node]);

  const { id: companyId } = useCompany();

  const { data: total, loading } = useRequest(
    () => {
      return customerController
        .listCustomerV2(
          companyId,
          {
            pool: CustomerPoolEnum.BIZ,
          },
          ListCustomerType.MY,
          group,
          1,
          10,
        )
        .then((res) => res.total);
    },
    {
      ready: !!group,
      refreshDeps: [group],
    },
  );

  return (
    <div
      onClick={onClick}
      className={`nav-items ${active ? 'nav-items-active' : ''}`}
      style={{
        cursor: active ? 'default' : 'pointer',
        // background: active ? colorBgContainer : undefined,
      }}
    >
      <span style={{ flexDirection: 'column' }}>{name}</span>
      {!!total && <span style={{ flexDirection: 'column' }}>({total})</span>}
      {showCloseBtn && (
        <CloseButton
          disabled={active}
          onClick={(e) => {
            onClose?.();
            e.stopPropagation();
          }}
        />
      )}
    </div>
  );
};

export default () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const { getCachingNodes, drop, refresh } = useAliveController();
  const cachingNodes = getCachingNodes();

  const location = useLocation();
  const params = useParams();

  const navigate = useNavigate();

  const isActive = useCallback(
    (fullpath: string) => {
      return (
        location.pathname.replace(`/${params.companyId}/`, '') === fullpath
      );
    },
    [location, params],
  );

  // tab 导航

  useEffect(() => {
    const unActived = cachingNodes.filter(
      (node) => !isActive(node.menuRoute.fullpath),
    );
    if (unActived.length > 10 && unActived[0].name) {
      drop(unActived[0].name);
    }
  }, [location, isActive, cachingNodes]);

  // 欢迎页置顶
  const mapData = cachingNodes.map((obj) => ({ ...obj }));
  const wel_index = mapData.findIndex((obj) => obj.id === 'welcome');
  if (wel_index !== -1) {
    const titleObj = mapData.splice(wel_index, 1)[0];
    mapData.splice(0, 0, titleObj);
  }
  // 删除页面
  const dropPage = (
    name: string,
    node: {
      [x: string]: any;
      createTime: number;
      updateTime: number;
      name?: string | undefined;
      id: string;
    },
  ) => {
    drop(name);
    console.log(name);
    if (isActive(node.menuRoute.fullpath)) {
      navigate(`/${params.companyId}/welcome`);
    }
  };
  return (
    <div className="tab-box">
      {mapData.map((node) => {
        const route = node.menuRoute as MenuRouter & { fullpath: string };
        return (
          <TabItem
            active={isActive(route.fullpath)}
            key={node.id}
            node={node}
            name={route.label}
            onClose={() => {
              node.name && dropPage(node.name, node);
            }}
            onClick={() => {
              navigate(`/${params.companyId}/${route.fullpath}`);
            }}
          />
        );
      })}
    </div>
  );
};
