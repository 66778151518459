import {
  ActionType,
  ProColumnType,
  ProColumns,
  ProTable,
} from '@ant-design/pro-components';
import { useCompany } from '../hooks/company';
import {
  VerticalAlignTopOutlined,
  DeleteOutlined,
  ToTopOutlined,
  EyeOutlined,
  CopyOutlined,
  VerticalAlignBottomOutlined,
  PushpinOutlined,
} from '@ant-design/icons';
import {
  CompanyMessageListItemDTO,
  companyController,
  companyMessageController,
  oSSController,
  systemController,
} from '../api';
import {
  Alert,
  Badge,
  Button,
  Checkbox,
  Form,
  Image,
  Input,
  Modal,
  ModalProps,
  Popconfirm,
  Space,
  Tag,
  Tooltip,
  Upload,
  message,
} from 'antd';
import { useCountDown, useRequest, useTimeout } from 'ahooks';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import '../styles/common.scss';

const refreshContext = createContext<{
  refresh: () => void;
  showMessage: (dto: CompanyMessageListItemDTO) => void;
}>({ refresh() {}, showMessage() {} });

const TransferMessage = ({ id }: { id: I.ID }) => {
  const [open, setOpen] = useState<boolean>(false);
  const { id: companyId } = useCompany();

  const [targetCompanyIdList, setTargetCompanyIdList] = useState<I.ID[]>();

  const {
    data: companies,
    run,
    loading: companyListLoading,
  } = useRequest(
    () => {
      return systemController.listCompany();
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (open) {
      run();
      setTargetCompanyIdList(undefined);
    }
  }, [open, setTargetCompanyIdList, run]);

  const { run: transfer, loading: transferLoading } = useRequest(
    (targets: I.ID[]) => {
      return companyMessageController.transferMessage(companyId, id, targets);
    },
    {
      manual: true,
      onSuccess: () => {
        message.success('复制成功');
        setOpen(false);
      },
      onError: (e) => message.error(`操作失败：${e.message}`),
    },
  );

  const loading = companyListLoading || transferLoading;

  const onSubmit = () => {
    if (targetCompanyIdList) {
      transfer(targetCompanyIdList);
    }
  };

  return (
    <>
      <Button
        size="small"
        icon={<CopyOutlined />}
        shape="circle"
        onClick={() => setOpen(true)}
      />
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title="复制公告"
        onOk={onSubmit}
      >
        <Checkbox.Group
          onChange={(v) => setTargetCompanyIdList(v.map((i: any) => i))}
        >
          {companies?.map((item) => (
            <Checkbox
              disabled={item.id === companyId}
              checked={targetCompanyIdList?.includes(item.id)}
              value={item.id}
            >
              {item.name}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Modal>
    </>
  );
};

const RemoveButton = ({ id }: { id: I.ID }) => {
  const { id: companyId } = useCompany();
  const { refresh } = useContext(refreshContext);

  const { run, loading } = useRequest(
    () => {
      return companyMessageController.removeMessage(companyId, id);
    },
    {
      manual: true,
      onSuccess: () => {
        refresh();
      },
    },
  );
  return (
    <Popconfirm title="确认删除" onConfirm={() => run()} disabled={loading}>
      <Button
        shape="circle"
        type="primary"
        danger
        size="small"
        loading={loading}
        icon={<DeleteOutlined />}
      />
    </Popconfirm>
  );
};

const SetCompanyTopButton = ({ id }: { id: I.ID }) => {
  const { id: companyId, refresh, config } = useCompany();
  const { run, loading } = useRequest(
    () => {
      return companyController.updateCompanyConfig(companyId, {
        ...config,
        globalCompanyMessageId: id,
      });
    },
    {
      manual: true,
      onError: (e) => {
        message.error(e.message);
      },
      onSuccess: () => {
        refresh();
        message.success('设置成功');
      },
    },
  );

  if (config?.globalCompanyMessageId === id) {
    return null;
  }

  return (
    <Popconfirm title={`超级置顶`} onConfirm={() => run()} disabled={loading}>
      <Button
        shape="circle"
        type="primary"
        size="small"
        loading={loading}
        icon={<PushpinOutlined />}
      />
    </Popconfirm>
  );
};

const SetTopButton = ({ id, top }: { id: I.ID; top?: boolean }) => {
  const { id: companyId } = useCompany();
  const { refresh } = useContext(refreshContext);

  const { run, loading } = useRequest(
    () => {
      return companyMessageController.setTopMessage(companyId, id, !top);
    },
    {
      manual: true,
      onSuccess: () => {
        refresh();
      },
    },
  );
  return (
    <Popconfirm
      title={`确认${top ? '取消' : ''}置顶`}
      onConfirm={() => run()}
      disabled={loading}
    >
      {top ? (
        <Button
          shape="circle"
          type="primary"
          size="small"
          loading={loading}
          icon={<VerticalAlignBottomOutlined />}
        />
      ) : (
        <Button
          shape="circle"
          type="primary"
          size="small"
          loading={loading}
          icon={<VerticalAlignTopOutlined />}
        />
      )}
    </Popconfirm>
  );
};

const columns: ProColumns<CompanyMessageListItemDTO>[] = [
  {
    dataIndex: 'id',
    search: false,
    title: 'id',
    render: (_, dto) => {
      return (
        <Space>
          <div>{dto.id}</div>
          {dto.top && (
            <Tooltip title="置顶的公告将在网页顶部滚动展示">
              <Badge count={'TOP'} />
            </Tooltip>
          )}
        </Space>
      );
    },
    width: '10%',
  },
  {
    dataIndex: 'title',
    search: false,
    width: '60%',
    title: '标题',
    render: (title, dto) => {
      const { showMessage } = useContext(refreshContext);
      return (
        <Button
          type="link"
          onClick={() => {
            showMessage(dto);
          }}
        >
          {title}
        </Button>
      );
    },
  },
  {
    dataIndex: 'createdAt',
    search: false,
    width: '10%',
    title: '时间',
    render: (_, dto) => {
      return <span>{dayjs(dto.updatedAt).fromNow()}</span>;
    },
  },
  {
    title: '创建人',
    search: false,
    width: '10%',
    dataIndex: 'userName',
  },
  {
    title: '操作',
    search: false,
    width: '10%',
    render: (_, dto) => {
      const { showMessage } = useContext(refreshContext);
      const { hasPermission, currentUser } = useCompany();
      return (
        <Space>
          <Button
            size="small"
            shape="circle"
            onClick={() => {
              showMessage(dto);
            }}
            icon={<EyeOutlined />}
          />
          {hasPermission('page:company-message:edit') && (
            <RemoveButton id={dto.id} />
          )}
          {hasPermission('page:company-message:edit') && (
            <SetTopButton id={dto.id} top={dto.top} />
          )}
          {hasPermission('page:company-message:edit') && (
            <SetCompanyTopButton id={dto.id} />
          )}
          {currentUser.isSuperAdmin && <TransferMessage id={dto.id} />}
        </Space>
      );
    },
  },
];

const AddModal = () => {
  const { id: companyId } = useCompany();
  const [open, setOpen] = useState<boolean>(false);

  const { refresh } = useContext(refreshContext);

  const { runAsync: createMessage, loading } = useRequest(
    companyMessageController.createMessage,
    { manual: true },
  );

  const [form] = Form.useForm<{ title: string; body?: string; image: any }>();

  useEffect(() => {
    form.resetFields();
  }, [open]);

  const onSumbit = () => {
    return form
      .validateFields()
      .then((values) => {
        return createMessage(
          companyId,
          values.title,
          values.body,
          values.image?.file,
        ).then(() => {
          refresh();
          setOpen(false);
        });
      })
      .catch((e) => {});
  };

  return (
    <>
      <Modal
        confirmLoading={loading}
        open={open}
        onCancel={() => setOpen(false)}
        title={<div className="message-list-modal-title">新增系统公告</div>}
        onOk={() => onSumbit()}
      >
        <Form
          form={form}
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 21 }}
          labelAlign="right"
          className="messagelist-form"
        >
          <Form.Item label="标题" name="title" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="正文" name="body">
            <Input.TextArea rows={6} />
          </Form.Item>
          <Form.Item label="配图 " name="image">
            <Upload
              beforeUpload={(file) => false}
              accept="image/*"
              multiple={false}
              maxCount={1}
            >
              <Button>选择图片</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
      <Button type="primary" loading={loading} onClick={() => setOpen(true)}>
        新增
      </Button>
    </>
  );
};

const DisplayMessage = ({
  dto,
  open,
  onCancel,
  delayClose,
}: {
  dto: CompanyMessageListItemDTO;
  open: boolean;
  delayClose?: number;
  onCancel: () => void;
}) => {
  const { id: companyId, currentUser } = useCompany();
  const [canClose, setCanClose] = useState<boolean>(
    delayClose === undefined || currentUser.isSuperAdmin,
  );

  const [_, { seconds }] = useCountDown({
    leftTime: delayClose || 0,
    onEnd: () => {
      setCanClose(true);
    },
  });

  const { data: imageUrl, loading } = useRequest(
    () => {
      return oSSController.getOSSUrl(
        companyId,
        `company_message/${dto.id}/image`,
      );
    },
    {
      ready: dto.image,
    },
  );
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title="系统公告"
      footer={false}
      closable={canClose}
      maskClosable={canClose}
    >
      <div>
        <h1>{dto.title}</h1>
        {!canClose && (
          <Alert message={`${seconds} 秒后可关闭`} type="warning" />
        )}
        <p>
          <span>{dayjs(dto.updatedAt).fromNow()}</span>
          <span>-</span>
          <span>{dto.userName}</span>
        </p>
        <p>{dto.body}</p>
        {dto.image && <Image src={imageUrl} />}
      </div>
    </Modal>
  );
};

export { DisplayMessage as CompanyMessage };

export default () => {
  const { id: companyId, hasPermission } = useCompany();
  const ref = useRef<ActionType>();

  const [currentSelectedMessage, setCurrentSelectedMessage] =
    useState<CompanyMessageListItemDTO>();

  return (
    <refreshContext.Provider
      value={{
        refresh: () => ref.current?.reloadAndRest?.(),
        showMessage: (dto) => {
          setCurrentSelectedMessage(dto);
        },
      }}
    >
      {currentSelectedMessage && (
        <DisplayMessage
          open={!!currentSelectedMessage}
          onCancel={() => setCurrentSelectedMessage(undefined)}
          dto={currentSelectedMessage}
        />
      )}
      <ProTable
        className="pro-table"
        actionRef={ref}
        columns={columns}
        params={{ companyId }}
        toolBarRender={() => {
          if (!hasPermission('page:company-message:edit')) {
            return [];
          }
          return [<AddModal />];
        }}
        search={false}
        rowKey={'id'}
        request={({ current, pageSize, companyId }) => {
          return companyMessageController
            .listMessage(companyId, current, pageSize)
            .then((resp) => ({
              data: resp.list,
              success: true,
              total: resp.total,
            }));
        }}
      />
    </refreshContext.Provider>
  );
};
