import { useRequest } from 'ahooks';
import { Button, DatePicker, Popconfirm, Space, message } from 'antd';
import { customerController } from '../api';
import { useCompany } from '../hooks/company';
import { ClockCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';
import dayjs from 'dayjs';

export const CustomerAlarmClock = ({ customerId }: { customerId: I.ID }) => {
  const [date, setDate] = useState<dayjs.Dayjs | null>();
  const { id: companyId } = useCompany();
  const { run, loading } = useRequest(
    (minute: number) => {
      return customerController.addAlarm(
        companyId,
        customerId,
        minute,
        '处理客户',
      );
    },
    {
      manual: true,
      onError: (e) => message.error(`操作失败：${e.message}`),
      onSuccess: () => {
        message.success('设置成功');
        setDate(null);
      },
    },
  );
  return (
    <Space>
      <DatePicker
        size="small"
        showTime
        allowClear
        value={date}
        onChange={(v) => setDate(v)}
        placeholder="自定义提醒时间"
        disabled={loading}
        disabledDate={(current) => {
          if (!current) {
            return false;
          }

          const now = dayjs();
          // 不允许选择过去的时间
          if (now.isBefore(current)) {
            return false;
          }

          return true;
        }}
      />
      <Button
        loading={loading}
        onClick={() => {
          if (date) {
            run(date.diff(dayjs(), 'minute'));
          }
        }}
        type="primary"
        size="small"
        disabled={!date}
        icon={<ClockCircleOutlined />}
      />
    </Space>
  );
};

export default function CustomerAlarm({
  customerId,
  minute,
  msg,
}: {
  customerId: I.ID;
  minute: number;
  msg: string;
}) {
  const { id: companyId } = useCompany();
  const { run, loading } = useRequest(
    () => {
      return customerController.addAlarm(companyId, customerId, minute, msg);
    },
    {
      manual: true,
      onError: (e) => message.error(`操作失败：${e.message}`),
      onSuccess: () => message.success('设置成功'),
    },
  );
  return (
    <Popconfirm title="设置提醒" onConfirm={run}>
      <Button type="link" loading={loading}>
        {minute}
      </Button>
    </Popconfirm>
  );
}
