import { ProColumns, ProTable } from '@ant-design/pro-components';
import { UserFeedbackListItemDTO, userFeedbackController } from '../../api';
import { useCompany } from '../../hooks/company';
import dayjs from 'dayjs';
import UserInfoModal from '../../components/UserInfoModal';
import { useEffect, useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';

const ViewFeedback = ({ id }: { id: I.ID }) => {
  const [open, setOpen] = useState<boolean>();

  const { id: companyId } = useCompany();

  const { loading, run, data } = useRequest(
    () => {
      return userFeedbackController.get(companyId, id);
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (open) {
      run();
    }
  }, [open, run]);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        shape="circle"
        size="small"
        icon={<EyeOutlined />}
      />
      <Modal
        open={open}
        title={'员工反馈'}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <Spin spinning={loading}>
          <pre>{data?.body}</pre>
        </Spin>
      </Modal>
    </>
  );
};

const columns: ProColumns<UserFeedbackListItemDTO>[] = [
  {
    dataIndex: 'id',
  },
  {
    dataIndex: 'body',
    title: '内容',
    render: (_, record) => {
      return <span>{record.body.slice(0, 20)}...</span>;
    },
  },
  {
    title: '反馈人',
    render: (_, record) => {
      if (record.anon) {
        return '匿名';
      }
      return <UserInfoModal id={record.userId} name={record.userName} />;
    },
  },
  {
    dataIndex: 'createdAt',
    title: '创建时间',
    render: (_, record) => {
      return <span>{dayjs(record.createdAt).fromNow()}</span>;
    },
  },
  {
    title: '操作',
    render: (_, record) => {
      return <ViewFeedback id={record.id} />;
    },
  },
];

export default function UserFeedback() {
  const { id: companyId, hasPermission } = useCompany();

  return (
    <ProTable
      className="pro-table"
      columns={columns}
      params={{ companyId }}
      search={false}
      rowKey={'id'}
      request={({ current, pageSize, companyId }) => {
        return userFeedbackController
          .list(companyId, current, pageSize)
          .then((resp) => ({
            data: resp.list,
            success: true,
            total: resp.total,
          }));
      }}
    />
  );
}
