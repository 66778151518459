import { Button, Checkbox, Modal, Tooltip, message } from 'antd';
import { useEffect, useState } from 'react';
import { useCompany } from '../../hooks/company';
import { CopyOutlined } from '@ant-design/icons';
import { companyStudyController, systemController } from '../../api';
import { useRequest } from 'ahooks';

export default ({ id }: { id: I.ID }) => {
  const { id: companyId, currentUser } = useCompany();

  const [open, setOpen] = useState<boolean>(false);
  const [targetCompanies, setTargetCompanies] = useState<I.ID[]>();

  const {
    data: companies,
    run,
    loading: companyListLoading,
  } = useRequest(
    () => {
      return systemController.listCompany();
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (open) {
      setTargetCompanies(undefined);
      run();
    }
  }, [open, setTargetCompanies, run]);

  const { run: transfer, loading: transferLoading } = useRequest(
    (targetIds: I.ID[]) => {
      return companyStudyController.transfer(companyId, id, targetIds);
    },
    {
      manual: true,
      onSuccess: () => {
        message.success('复制成功');
        setOpen(false);
      },
      onError: (e) => message.error(`操作失败：${e.message}`),
    },
  );

  const onSubmit = () => {
    if (targetCompanies) {
      transfer(targetCompanies);
    }
  };

  if (!currentUser?.isSuperAdmin) {
    return null;
  }

  return (
    <>
      <Button
        loading={companyListLoading}
        onClick={() => setOpen(true)}
        icon={<CopyOutlined />}
        shape="circle"
        size="small"
      />
      <Modal
        confirmLoading={companyListLoading || transferLoading}
        title="复制文件"
        open={open}
        onCancel={() => setOpen(false)}
        onOk={onSubmit}
      >
        <Checkbox.Group
          onChange={(v) => setTargetCompanies(v.map((i: any) => i))}
        >
          {companies?.map((item) => (
            <Checkbox
              disabled={item.id === companyId}
              checked={targetCompanies?.includes(item.id)}
              value={item.id}
            >
              {item.name}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Modal>
    </>
  );
};
