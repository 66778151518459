import { useRequest } from 'ahooks';
import { useCompany } from '../../hooks/company';
import {
  BaseDepartmentDTO,
  CustomerAnalyzeDTO,
  dataAnalyzeController,
} from '../../api';
import {
  Button,
  Col,
  Radio,
  Rate,
  Row,
  Segmented,
  Space,
  Switch,
  Table,
} from 'antd';
import { ColumnType } from 'antd/es/table';
import { useMemo, useState } from 'react';
import {
  findAllDepartmentId,
  findChildrenDepartment,
  flatDepartment,
  sortName,
} from '../../utils';
import DepartmentSelect from '../../components/DepartmentSelect';
import '../../styles/common.scss';

type Data = Exclude<CustomerAnalyzeDTO, 'user'> & { name?: string };

const stateColumns: ColumnType<Data>[] = [
  {
    dataIndex: 'name',
    title: '',
    align: 'left',
  },
  {
    dataIndex: 'cnt',
    title: '总数',
    sorter: (a, b) => (a.cnt > b.cnt ? 1 : -1),
  },
  // {
  //   dataIndex: 'stateUnknown',
  //   title: '未知',
  // },
  {
    dataIndex: 'stateWaitFollowUp',
    sorter: (a, b) => (a.stateWaitFollowUp > b.stateWaitFollowUp ? 1 : -1),
    title: '待跟进',
  },
  {
    dataIndex: 'stateUnqualified',
    sorter: (a, b) => (a.stateUnqualified > b.stateUnqualified ? 1 : -1),
    title: '资质不符',
  },
  {
    dataIndex: 'stateWaitSign',
    sorter: (a, b) => (a.stateWaitSign > b.stateWaitSign ? 1 : -1),
    title: '待签约',
  },
  {
    dataIndex: 'stateSigned',
    sorter: (a, b) => (a.stateSigned > b.stateSigned ? 1 : -1),
    title: '已签约',
  },
  {
    dataIndex: 'stateVisited',
    sorter: (a, b) => (a.stateVisited > b.stateVisited ? 1 : -1),
    title: '已上门',
  },
  {
    dataIndex: 'stateApproval',
    sorter: (a, b) => (a.stateApproval > b.stateApproval ? 1 : -1),
    title: '审核中',
  },
  {
    dataIndex: 'stateLoan',
    sorter: (a, b) => (a.stateLoan > b.stateLoan ? 1 : -1),
    title: '已放款',
  },
];

const starColumns: ColumnType<Data>[] = [
  {
    dataIndex: 'name',
    align: 'left',
  },
  {
    dataIndex: 'cnt',
    title: '总数',
    sorter: (a, b) => (a.cnt > b.cnt ? 1 : -1),
  },
  {
    dataIndex: 'star5',
    sorter: (a, b) => (a.star5 > b.star5 ? 1 : -1),
    title: <Rate disabled value={5} count={5} style={{ fontSize: '12px' }} />,
  },
  {
    dataIndex: 'star4',
    sorter: (a, b) => (a.star4 > b.star4 ? 1 : -1),
    title: <Rate disabled value={4} count={4} style={{ fontSize: '12px' }} />,
  },
  {
    dataIndex: 'star3',
    sorter: (a, b) => (a.star3 > b.star3 ? 1 : -1),
    title: <Rate disabled value={3} count={3} style={{ fontSize: '12px' }} />,
  },
  {
    dataIndex: 'star2',
    sorter: (a, b) => (a.star2 > b.star2 ? 1 : -1),
    title: <Rate disabled value={2} count={2} style={{ fontSize: '12px' }} />,
  },
  {
    dataIndex: 'star1',
    sorter: (a, b) => (a.star1 > b.star1 ? 1 : -1),
    title: <Rate disabled value={1} count={1} style={{ fontSize: '12px' }} />,
  },
  {
    dataIndex: 'star0',
    sorter: (a, b) => (a.star0 > b.star0 ? 1 : -1),
    title: <Rate disabled value={0} count={0} style={{ fontSize: '12px' }} />,
  },
];

const groupColumns: ColumnType<Data>[] = [
  {
    dataIndex: 'name',
    align: 'left',
  },
  {
    dataIndex: 'cnt',
    title: '总数',
    sorter: (a, b) => (a.cnt > b.cnt ? 1 : -1),
  },
  {
    dataIndex: 'groupNewCustomer',
    sorter: (a, b) => (a.groupNewCustomer > b.groupNewCustomer ? 1 : -1),
    title: '新客户',
  },
  {
    dataIndex: 'groupNewAllot',
    sorter: (a, b) => (a.groupNewAllot > b.groupNewAllot ? 1 : -1),
    title: '新分派',
  },
  {
    dataIndex: 'groupExpand',
    sorter: (a, b) => (a.groupExpand > b.groupExpand ? 1 : -1),
    title: '拓展',
  },
  {
    dataIndex: 'groupMortgage',
    sorter: (a, b) => (a.groupMortgage > b.groupMortgage ? 1 : -1),
    title: '抵押',
  },
  {
    dataIndex: 'groupLocked',
    sorter: (a, b) => (a.groupLocked > b.groupLocked ? 1 : -1),
    title: '锁定',
  },
  ...['A', 'B', 'C', 'D', 'E'].map((item) => ({
    dataIndex: `group${item}`,
    sorter: (a: any, b: any) =>
      a[`group${item}`] > b[`group${item}`] ? 1 : -1,
    title: `${item} 类`,
  })),
];

type DisplayType = 'state' | 'star' | 'group';

export default () => {
  const { id: companyId, departments } = useCompany();

  const [departmentId, setDepartmentId] = useState<I.ID | null>(null);

  const [type, setType] = useState<'person' | 'department'>('department');

  const [columnType, setColumnType] = useState<DisplayType>('state');

  const {
    data: rawData,
    loading,
    refresh,
  } = useRequest(
    () => {
      return dataAnalyzeController.customerAnalyze(companyId);
    },
    {
      refreshDeps: [companyId],
    },
  );

  const data = useMemo(() => {
    if (type === 'person') {
      // 只能看到自己区域下的
      const depIds = departments.map((item) => item.id);
      return rawData
        ?.filter((item) => depIds.includes(item.user?.departmentId || 0))
        .filter((item) => {
          if (departmentId == null) {
            return true;
          }
          return item.user?.departmentId === departmentId;
        })
        .map((item) => {
          return {
            ...item,
            name: item.user?.name,
          };
        });
    }

    // 查看所有一级区域下的数据

    // 先找到当前 departmentId 的直接子区域

    const children = findChildrenDepartment(departments, departmentId);

    // 按照 children 维度进行聚合
    return children
      .map((dep) => {
        // 找到 dep 下的所有的数据,
        const depIds = findAllDepartmentId(departments, dep.id).map(
          (dep) => dep.id,
        );
        const rowData = rawData?.filter((item) =>
          depIds.includes(item.user?.departmentId || 0),
        );
        const base: Data = {
          name: dep.name,
          cnt: 0,
          groupNewAllot: 0,
          groupNewCustomer: 0,
          groupA: 0,
          groupB: 0,
          groupC: 0,
          groupD: 0,
          groupE: 0,
          groupExpand: 0,
          groupLocked: 0,
          groupMortgage: 0,
          stateApproval: 0,
          stateBlackList: 0,
          stateExamining: 0,
          stateInvalid: 0,
          stateLoan: 0,
          stateSigned: 0,
          stateUnqualified: 0,
          stateUnknown: 0,
          stateVisited: 0,
          stateWaitFollowUp: 0,
          stateWaitSign: 0,
          star0: 0,
          star1: 0,
          star2: 0,
          star3: 0,
          star4: 0,
          star5: 0,
        };
        if (!rowData) {
          return base;
        }
        return rowData?.reduce<Data>((acc, cur) => {
          return {
            name: dep.name,
            stateBlackList:
              Number(acc.stateBlackList) + Number(cur.stateBlackList),
            stateExamining:
              Number(acc.stateExamining) + Number(cur.stateExamining),
            stateInvalid: Number(acc.stateInvalid) + Number(cur.stateInvalid),
            cnt: Number(acc.cnt) + Number(cur.cnt),
            stateUnknown: Number(acc.stateUnknown) + Number(cur.stateUnknown),
            stateWaitFollowUp:
              Number(acc.stateWaitFollowUp) + Number(cur.stateWaitFollowUp),
            stateUnqualified:
              Number(acc.stateUnqualified) + Number(cur.stateUnqualified),
            stateWaitSign:
              Number(acc.stateWaitSign) + Number(cur.stateWaitSign),
            stateSigned: Number(acc.stateSigned) + Number(cur.stateSigned),
            stateVisited: Number(acc.stateVisited) + Number(cur.stateVisited),
            stateApproval:
              Number(acc.stateApproval) + Number(cur.stateApproval),
            stateLoan: Number(acc.stateLoan) + Number(cur.stateLoan),
            star0: Number(acc.star0) + Number(cur.star0),
            star1: Number(acc.star1) + Number(cur.star1),
            star2: Number(acc.star2) + Number(cur.star2),
            star3: Number(acc.star3) + Number(cur.star3),
            star4: Number(acc.star4) + Number(cur.star4),
            star5: Number(acc.star5) + Number(cur.star5),
            groupNewCustomer:
              Number(acc.groupNewCustomer) + Number(cur.groupNewCustomer),
            groupNewAllot:
              Number(acc.groupNewAllot) + Number(cur.groupNewAllot),
            groupA: Number(acc.groupA) + Number(cur.groupA),
            groupB: Number(acc.groupB) + Number(cur.groupB),
            groupC: Number(acc.groupC) + Number(cur.groupC),
            groupD: Number(acc.groupD) + Number(cur.groupD),
            groupE: Number(acc.groupE) + Number(cur.groupE),
            groupExpand: Number(acc.groupExpand) + Number(cur.groupExpand),
            groupLocked: Number(acc.groupLocked) + Number(cur.groupLocked),
            groupMortgage:
              Number(acc.groupMortgage) + Number(cur.groupMortgage),
          };
        }, base);
      })
      .sort((a, b) => sortName(a.name, b.name));
  }, [type, rawData, departmentId, departments]);

  const columns = useMemo(() => {
    switch (columnType) {
      case 'group':
        return groupColumns;
      case 'star':
        return starColumns;
      case 'state':
        return stateColumns;
      default:
    }
  }, [columnType]);

  return (
    <Row>
      <Col span={24}>
        <Space>
          <span>展示方式：</span>
          <Segmented
            disabled={loading}
            value={columnType}
            onChange={(v) => setColumnType(v as DisplayType)}
            options={[
              {
                label: '状态',
                value: 'state',
              },
              {
                label: '星级',
                value: 'star',
              },
              {
                label: '分组',
                value: 'group',
              },
            ]}
          />
          <span>汇总方式：</span>
          <Radio.Group value={type} onChange={(v) => setType(v.target.value)}>
            <Radio value="person">个人</Radio>
            <Radio value="department">区域</Radio>
          </Radio.Group>
          <DepartmentSelect
            value={departmentId}
            style={{ width: '300px' }}
            onSelect={(v) => setDepartmentId(v)}
            onClear={() => setDepartmentId(null)}
          />
          <Button type="primary" loading={loading} onClick={refresh}>
            查询
          </Button>
        </Space>
      </Col>
      <Col span={24} style={{ marginTop: '20px' }}>
        <Table
          className="normal-table"
          size="small"
          bordered
          dataSource={data}
          rowKey="name"
          columns={columns?.map((item) => {
            const align = item.align || 'right';
            return {
              ...item,
              align,
            };
          })}
          pagination={false}
          loading={loading}
        />
      </Col>
    </Row>
  );
};
