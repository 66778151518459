import { ActionType, ProColumns, ProTable } from '@ant-design/pro-components';
import { useCompany } from '../../hooks/company';
import {
  BlackListItemDTO,
  ListBlackListParams,
  blackListController,
} from '../../api';
import CustomerMobile from '../../components/CustomerMobile';
import CustomerChannelSelect from '../../components/CustomerChannelSelect';
import UserInfoModal from '../../components/UserInfoModal';
import dayjs from 'dayjs';
import '../../styles/common.scss';
import { Button, Popconfirm, message } from 'antd';
import { createContext, useContext, useRef } from 'react';
import { useRequest } from 'ahooks';

const context = createContext<ActionType | undefined>(undefined);

const Remove = ({ id }: { id: I.ID }) => {
  const actionRef = useContext(context);
  const { id: companyId, hasPermission } = useCompany();

  const { run: remove, loading } = useRequest(
    () => {
      return blackListController.remove(companyId, id);
    },
    {
      manual: true,
      onSuccess: () => {
        message.success('移除成功');
        actionRef?.reload();
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );
  return (
    <Popconfirm title="确认删除" onConfirm={remove}>
      <Button
        disabled={!hasPermission('page:customer-list:blacklist:delete')}
        type="primary"
        size="small"
        danger
        loading={loading}
      >
        移除
      </Button>
    </Popconfirm>
  );
};

const Clear = () => {
  const actionRef = useContext(context);
  const { id: companyId, hasPermission } = useCompany();
  const { run: clear, loading } = useRequest(
    () => {
      return blackListController.empty(companyId);
    },
    {
      manual: true,
      onSuccess: () => {
        message.success('清空成功');
        actionRef?.reload();
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );
  return (
    <Popconfirm title="确认清空，清空后无法恢复" onConfirm={clear}>
      <Button
        disabled={!hasPermission('page:customer-list:blacklist:delete')}
        type="primary"
        size="small"
        danger
        loading={loading}
      >
        清空
      </Button>
    </Popconfirm>
  );
};

const columns: ProColumns<BlackListItemDTO>[] = [
  {
    dataIndex: 'id',
    search: false,
    title: 'id',
  },
  {
    dataIndex: 'name',
    search: false,
    title: '姓名',
  },
  {
    dataIndex: 'mobile',
    title: '手机号码',
    search: {
      transform: (keyword) => {
        return {
          keyword,
        };
      },
    },
    render: (_, { mobile }) => {
      return <CustomerMobile mobile={mobile} />;
    },
  },
  {
    dataIndex: 'channel',
    renderFormItem(schema, config, form, action) {
      return <CustomerChannelSelect />;
    },
    title: '渠道',
  },
  {
    dataIndex: 'remark',
    search: false,
    title: '备注',
  },
  {
    title: '操作人',
    dataIndex: 'userId',
    search: false,
    render: (_, { remark, user }) => {
      if (user) {
        return <UserInfoModal id={user.id} name={user.name} />;
      }

      return <span>{remark}</span>;
    },
  },
  {
    title: '操作时间',
    dataIndex: 'createdAt',
    valueType: 'dateRange',
    search: {
      transform: (value) => {
        return {
          createdAt: [dayjs(value[0]), dayjs(value[1])],
        };
      },
    },
    render: (_, { createdAt }) =>
      dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    title: '操作',
    render: (_, { id }) => {
      return <Remove id={id} />;
    },
  },
];

export default () => {
  const { id: companyId } = useCompany();
  const ref = useRef<ActionType>();
  return (
    <context.Provider value={ref.current}>
      <ProTable<BlackListItemDTO, ListBlackListParams & { companyId: I.ID }>
        className="pro-table"
        actionRef={ref}
        rowKey="id"
        columns={columns}
        params={{
          companyId,
        }}
        pagination={{
          pageSizeOptions: [20, 50, 100, 200, 500, 1000],
        }}
        size="small"
        bordered
        toolBarRender={() => [<Clear key="clear" />]}
        cardBordered={{ search: true }}
        dateFormatter="number"
        request={async (
          { current, pageSize, companyId, ...params },
          sort,
          filter,
        ) => {
          return blackListController
            .list(companyId, current, pageSize, params)
            .then((resp) => ({
              data: resp.list,
              success: true,
              total: resp.total,
            }));
        }}
      />
    </context.Provider>
  );
};
