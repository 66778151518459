import './App.css';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import { ConfigProvider } from 'antd';
import 'dayjs/locale/zh-cn';
import zhCN from 'antd/locale/zh_CN';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import zhCn from 'dayjs/locale/zh-cn';

dayjs.locale(zhCn);
dayjs.extend(relativeTime);

function App() {
  return (
    <ConfigProvider
      locale={zhCN}
      theme={{
        components: {
          Message: {
            zIndexPopup: 9999,
          },
        },
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  );
}

export default App;
