import { Button, Layout } from 'antd';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

function isWeChat() {
  //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
  var ua = window.navigator.userAgent.toLowerCase();
  //通过正则表达式匹配ua中是否含有MicroMessenger字符串
  // @ts-ignore
  if (ua.match(/MicroMessenger/i) === 'micromessenger') {
    return true;
  } else {
    return false;
  }
}

const cdnURL = ((w: Window) => {
  return `https://${w.location.hostname}/app`;
})(window);

const apkMapping = [
  { domain: 'anmeijuxin.com', apk: 'anmeijuxin-2024-04-28.apk' },
  { domain: 'mxcztech.com', apk: 'mxcztech-2024-04-28.apk' },
  { domain: 'hjrongzi88cn.com', apk: 'hjrongzi88cn-2024-04-28.apk' },
  { domain: 'jr092019.com', apk: 'jr092019-2024-04-28.apk' },
  { domain: 'am.crmweb.app', apk: 'am.crmweb.app-20240518.apk' },
  { domain: 'am2.crmweb.app', apk: 'am2.crmweb.app-20240610.apk' },
  { domain: 'www.erong.org', apk: 'www.erong.org-20240828.apk' },
  { domain: 'shs.erong.org', apk: 'shs.erong.org-20240828.apk' },
  { domain: 'shw.erong.org', apk: 'shw.erong.org-20240828.apk' },
  { domain: 'shx.erong.org', apk: 'shx.erong.org-20240828.apk' },
  { domain: 'sxh.erong.org', apk: 'sxh.erong.org-20240828.apk' },
  { domain: 'wxx.erong.org', apk: 'wxx.erong.org-20240828.apk' },
];

export default function AppDownload() {
  const inWechat = isWeChat();

  const downloadUrl = useMemo(() => {
    const value = apkMapping.find((item) =>
      window.location.hostname.endsWith(item.domain),
    );
    return value?.apk;
  }, []);
  return (
    <Layout>
      <Layout.Content style={{ height: '100vh' }}>
        <div style={{ textAlign: 'center', marginTop: 100 }}>
          <h1>下载APP</h1>
          {inWechat && <p>请使用手机浏览器打开</p>}
          <Button type="link" href={`${cdnURL}/${downloadUrl}`}>
            点击下载 app
          </Button>
        </div>
      </Layout.Content>
    </Layout>
  );
}
