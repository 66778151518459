import { Button, Modal, Popconfirm, message } from 'antd';
import { useEffect, useState } from 'react';
import Allocate from './Allocate';
import {
  CustomerPoolEnum,
  ReAllocateCustomerParams,
  customerController,
} from '../api';
import { useRequest } from 'ahooks';
import { useCompany } from '../hooks/company';

interface Props {
  targets: { name: string; id: I.ID }[];
  onSuccess: () => void;
  pool: CustomerPoolEnum;
}

export default ({ targets, onSuccess, pool }: Props) => {
  const { id: companyId } = useCompany();
  const [open, setOpen] = useState<boolean>();
  const [allocation, setAllocation] = useState<ReAllocateCustomerParams[]>();
  const [remark, setRemark] = useState<string>();

  useEffect(() => {
    if (open) {
      setRemark(undefined);
      setAllocation(undefined);
    }
  }, [open]);

  const { loading, run } = useRequest(
    () => {
      if (allocation?.length) {
        return customerController.reAllocate(companyId, allocation, pool);
      }
      return Promise.reject();
    },
    {
      manual: true,
      onSuccess: () => {
        message.success('重新分派成功');
        onSuccess();
        setOpen(false);
      },
      onError: (e) => {
        message.error(e.message || '重新分派失败');
      },
    },
  );

  return (
    <>
      <Modal
        title={`重新分派${pool === CustomerPoolEnum.BIZ ? '业务池' : '公共池'}`}
        confirmLoading={loading}
        open={open}
        destroyOnClose
        closeIcon={false}
        footer={[
          <Button key="cancel" loading={loading} onClick={() => setOpen(false)}>
            取消
          </Button>,
          <Popconfirm
            key="ok"
            title={`确认分配${
              pool === CustomerPoolEnum.BIZ ? '业务池' : '公共池'
            }`}
            onConfirm={() => {
              run();
            }}
          >
            <Button
              type="primary"
              disabled={!targets?.length}
              loading={loading}
            >
              确定
            </Button>
          </Popconfirm>,
        ]}
      >
        <Allocate
          targets={targets}
          remark={remark}
          onRemarkChange={(v) => {
            setRemark(v);
          }}
          onChange={(v) => {
            setAllocation(
              v.map((item) => ({
                userId: item.userId,
                customerIds: item.targets,
              })),
            );
          }}
        />
      </Modal>
      <Button
        type="primary"
        disabled={!targets?.length}
        onClick={() => setOpen(true)}
      >
        重新分派
      </Button>
    </>
  );
};
