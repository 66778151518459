import React, { RefObject, useEffect, useMemo, useState } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  DashboardOutlined,
  UserOutlined,
  CloseOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import {
  Layout,
  Menu,
  Button,
  theme,
  MenuProps,
  Row,
  Col,
  Tabs,
  Space,
  Divider,
  Breadcrumb,
  Carousel,
} from 'antd';
import router, { MenuRouter } from './router';
import { useCompany } from '../../hooks/company';
import { useRequest, useTitle } from 'ahooks';
import { getCurrentUser } from '../../apis/api';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AliveScope } from 'react-activation';
import TabNav from '../TabNav';
import HeaderUserInfo from './HeaderUserInfo';
import CompanyMessage from './CompanyMessage';
import { WaterMark } from '@ant-design/pro-components';
import '../../styles/layout.scss';
import OSSImage from '../OSSImage';
import AddUserFeedback from '../AddUserFeedback';
import { CompanyConfig } from '../../api';

const { Header, Sider, Content } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

const displayMenu = (menuCode?: string, codes?: string[]) => {
  if (!menuCode) {
    return true;
  }
  if (!codes?.length) {
    return false;
  }
  if (!codes?.includes(menuCode)) {
    return false;
  }
  return true;
};

const renderMenuItem = ({
  key,
  label,
  icon,
  children,
  permissionCode,
  codes,
  isSuperAdmin,
  config,
}: MenuRouter & {
  codes?: string[];
  isSuperAdmin?: boolean;
  config?: CompanyConfig;
}): MenuItem | undefined => {
  if (!isSuperAdmin && !displayMenu(permissionCode, codes)) {
    return undefined;
  }
  if (config?.hideReport && key === 'analyse') {
    return undefined;
  }
  if (config?.hideOutsiderSource && key === 'outsider-pool') {
    return undefined;
  }
  const childrenMenu: MenuItem[] = [];
  for (const item of children || []) {
    const child = renderMenuItem({ ...item, codes, isSuperAdmin, config });
    if (child) {
      childrenMenu.push(child);
    }
  }
  return {
    key,
    label,
    icon,
    children: childrenMenu.length ? childrenMenu : undefined,
  };
};

const buildMenuFullPath = (
  menu: MenuRouter[],
  parent?: MenuItem,
): Record<string, { name: string; isLink?: boolean }> => {
  const result = menu
    .map((item) => {
      const fullpath = parent ? `${parent.key}/${item.key}` : item.key;
      if (item.children) {
        return {
          ...buildMenuFullPath(item.children, item),
          [fullpath]: { name: item.label },
        };
      }
      return {
        [fullpath]: { name: item.label, isLink: true },
      };
    })
    .reduce<Record<string, { name: string; isLink?: boolean }>>(
      (prev, curr) => {
        return {
          ...prev,
          ...curr,
        };
      },
      {},
    );
  return result;
};
const menuFullPathMapping = buildMenuFullPath(router);

const HeaderBreadcrumb = () => {
  const location = useLocation();
  const pathSnippets = location.pathname
    .split('/')
    .filter((i) => i)
    .slice(1);
  const { id: companyId } = useCompany();

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const key = `${pathSnippets.slice(0, index + 1).join('/')}`;
    const info = menuFullPathMapping[key];
    return {
      key,
      title: info?.isLink ? (
        <Link to={`/${companyId}/${key}`}>{info.name}</Link>
      ) : (
        info.name
      ),
    };
  });

  return <Breadcrumb items={extraBreadcrumbItems} />;
};

const App: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [selectKeys, setSelectKeys] = useState<string[]>();
  const [openKeys, setOpenKeys] = useState<string[]>();

  const company = useCompany();
  const currentUser = company.currentUser;
  const navigate = useNavigate();

  useTitle(company.name);

  const location = useLocation();

  useEffect(() => {
    const keys = location.pathname.replace(`/${company.id}/`, '').split('/');
    setOpenKeys((prev) => {
      return Array.from(new Set([...(prev || []), keys[0]]));
    });
    setSelectKeys(keys.reverse());
  }, [location]);

  const menuItems: MenuProps['items'] = useMemo(() => {
    const items: MenuProps['items'] = [];
    const codes = currentUser.role?.permissions?.map((item) => item.code);
    for (const item of router) {
      const route = renderMenuItem({
        ...item,
        codes,
        isSuperAdmin: company.currentUser.isSuperAdmin,
        config: company.config,
      });
      if (route) {
        items.push(route);
      }
    }
    return items;
  }, [company.currentUser, company.config]);

  return (
    <Layout className="layout-box" style={{ height: '100vh' }}>
      <Sider
        className="sider-box"
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{ height: '100vh' }}
      >
        <div
          style={{
            height: '65px',
            // margin: '16px',
            display: 'flex',
            padding: '15px',
            justifyContent: 'center',
            borderRadius: '0px',
            borderBottom: '1px solid rgb(16 55 96)',
            // rgb(16 55 96)
            // background: !collapsed ? 'rgba(255, 255, 255, 0.3)' : undefined,
          }}
        >
          {company.logoOss ? (
            <OSSImage
              preview={false}
              style={{ width: '32px', display: 'inline-block' }}
              path={`logo/${company.id}`}
            />
          ) : (
            <img
              style={{ display: 'inline-block', width: '32px', height: '32px' }}
              src={company.logoUrl}
            />
          )}
          {!collapsed && (
            <h1
              style={{
                display: 'inline-block',
                color: 'white',
                height: '32px',
                marginBlock: 0,
                marginInlineEnd: 0,
                marginInlineStart: '6px',
                fontWeight: 600,
                fontSize: '20px',
                lineHeight: '32px',
                verticalAlign: 'middle',
                overflow: 'hidden',
              }}
            >
              {company.name}
            </h1>
          )}
        </div>
        <Menu
          className="menu-box"
          theme="dark"
          mode="inline"
          style={{
            height: 'calc(100vh - 42px - 16px - 8px)',
            overflowY: 'auto',
          }}
          onClick={(menu) => {
            navigate(`/${company.id}/${menu.keyPath.reverse().join('/')}`);
          }}
          defaultSelectedKeys={['welcome']}
          onOpenChange={(openKeys) => {
            setOpenKeys(openKeys);
          }}
          selectedKeys={selectKeys}
          openKeys={openKeys}
          items={menuItems}
        />
      </Sider>
      <Layout className="layout-menu-box" style={{ height: '100vh' }}>
        <Header
          className="banner-header"
          style={{ padding: 0, background: colorBgContainer }}
        >
          <Row justify={'space-between'}>
            <Col span={6}>
              <Space>
                <Button
                  type="text"
                  icon={
                    collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
                  }
                  onClick={() => setCollapsed(!collapsed)}
                  style={{
                    fontSize: '16px',
                    width: 64,
                    height: 64,
                  }}
                />
              </Space>
            </Col>
            <Col span={12}>
              <CompanyMessage />
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}>
              <HeaderUserInfo />
            </Col>
          </Row>
        </Header>
        <AliveScope>
          <Content>
            <Layout>
              <Header
                style={{
                  // margin: '0px 16px 0px 16px',
                  padding: '0 24px',
                  height: '50px',
                  lineHeight: '50px',
                  background: '#fff',
                  borderTop: '1px solid #ddd',
                  borderBottom: '1px solid #ddd',
                  // paddingLeft: '20px',
                  // borderBottom: '1px solid rgba(5, 5, 5, 0.06)',
                }}
              >
                <TabNav />
              </Header>
              <WaterMark
                content={[currentUser.name, currentUser.mobile.substring(7)]}
                zIndex={9999}
                gapX={100}
                gapY={100}
                fontSize={12}
                fontColor="rgba(0,0,0,.05)"
              >
                <Content
                  style={{
                    // margin: '0px',
                    height: 'calc(100vh - 64px - 50px)',
                    overflowY: 'auto',
                    padding: 24,
                    minHeight: 280,
                    background: colorBgContainer,
                  }}
                >
                  <Outlet />
                </Content>
              </WaterMark>
            </Layout>
          </Content>
        </AliveScope>
      </Layout>
      <AddUserFeedback />
    </Layout>
  );
};

export default App;
