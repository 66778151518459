import {
  CustomerDTO,
  CustomerEventTypeEnum,
  CustomerGroupEnum,
  CustomerStateEnum,
} from './api';

import dayjs from 'dayjs';

const customerStateMapping: Record<CustomerStateEnum, string> = {
  [CustomerStateEnum.UNKNOWN]: '未知',
  [CustomerStateEnum.BLACK_LIST]: '黑名单',
  [CustomerStateEnum.WAIT_FOLLOW_UP]: '待跟进',
  [CustomerStateEnum.UNQUALIFIED]: '资质不符合',
  [CustomerStateEnum.WAIT_SIGN]: '待签约',
  [CustomerStateEnum.SIGNED]: '已签约',
  [CustomerStateEnum.VISITED]: '已上门',
  [CustomerStateEnum.APPROVAL]: '审核中',
  [CustomerStateEnum.LOAN]: '已放款',
  [CustomerStateEnum.EXAMINING]: '在审件',
  [CustomerStateEnum.INVALID]: '捣乱申请',
};

const customerGroupMapping: Record<CustomerGroupEnum, string> = {
  [CustomerGroupEnum.NEW_CUSTOMER]: '新客户',
  [CustomerGroupEnum.NEW_ALLOT]: '新分派',
  [CustomerGroupEnum.EXPAND]: '拓展',
  [CustomerGroupEnum.MORTGAGE]: '抵押',
  [CustomerGroupEnum.LOCKED]: '锁定',
  [CustomerGroupEnum.A]: 'A 类',
  [CustomerGroupEnum.B]: 'B 类',
  [CustomerGroupEnum.C]: 'C 类',
  [CustomerGroupEnum.D]: 'D 类',
  [CustomerGroupEnum.E]: 'E 类',
};

const customerEventMapping: Record<CustomerEventTypeEnum, string> = {
  [CustomerEventTypeEnum.COMMENT]: '备注',
  [CustomerEventTypeEnum.DROP_PUBLIC_POOL]: '抛入公共池',
  [CustomerEventTypeEnum.BLOCK_CALL]: '未接通',
  [CustomerEventTypeEnum.CALL]: '打电话',
  [CustomerEventTypeEnum.REASSIGN]: '重新分派',
  [CustomerEventTypeEnum.VISIT]: '上门',
  [CustomerEventTypeEnum.LOAN]: '放款',
  [CustomerEventTypeEnum.REDUPLICATE]: '重复申请',
  [CustomerEventTypeEnum.REMARK]: '领导点评',
};

const extraKeys = {
  isNative: '本地人',
  hasCar: '车',
  hasHouse: '房',
  hasCreditCard: '信用卡',
  hasInsurance: '寿险保单',
  hasProvidentFund: '公积金',
  hasSocialSecurity: '社保',
  hasWages: '代发工资',
  hasBusinessLicense: '营业执照',
};

const generateExtra = (
  keys?: Pick<
    CustomerDTO,
    | 'hasBusinessLicense'
    | 'isNative'
    | 'hasCar'
    | 'hasCreditCard'
    | 'hasHouse'
    | 'hasInsurance'
    | 'hasProvidentFund'
    | 'hasSocialSecurity'
    | 'hasWages'
  >,
) => {
  const extra: string[] = [];
  Object.keys(extraKeys).forEach((key) => {
    // @ts-ignore
    if (keys && key in keys && keys[key]) {
      extra.push(key);
    }
  });
  return extra;
};

const flatDepartment = (department?: I.Department) => {
  if (!department) {
    return [];
  }
  const result = [department];
  if (department.parent) {
    result.push(...flatDepartment(department.parent));
  }
  return result;
};

export {
  customerStateMapping,
  customerGroupMapping,
  customerEventMapping,
  flatDepartment,
  generateExtra,
  extraKeys,
};
