import { useRequest } from 'ahooks';
import {
  Button,
  Col,
  DatePicker,
  Radio,
  Row,
  Segmented,
  Space,
  Statistic,
  Table,
} from 'antd';
import { useCompany } from '../../hooks/company';
import { LoanAnalyzeDTO, Long, dataAnalyzeController } from '../../api';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { ColumnType } from 'antd/es/table';
import DepartmentSelect from '../../components/DepartmentSelect';
import {
  findAllDepartmentId,
  findChildrenDepartment,
  flatDepartment,
  formatEndTime,
  formatStartTime,
  sortName,
} from '../../utils';
import DateRangePicker from '../../components/DateRangePicker';
import '../../styles/common.scss';

interface TableData {
  name: string;
  loanAmount: number;
  brokerage: number;
}

const columns: ColumnType<TableData>[] = [
  {
    dataIndex: 'name',
  },
  {
    dataIndex: 'loanAmount',
    sorter: (a, b) => (a.loanAmount > b.loanAmount ? 1 : -1),
    title: '放款',
    render: (value: number) => {
      return (
        <Statistic
          value={value / 100}
          prefix="¥"
          precision={2}
          valueStyle={{ fontSize: '14px' }}
        />
      );
    },
  },
  {
    dataIndex: 'brokerage',
    sorter: (a, b) => (a.brokerage > b.brokerage ? 1 : -1),
    title: '创收',
    render: (value: number) => {
      return (
        <Statistic
          value={value / 100}
          precision={2}
          prefix="¥"
          valueStyle={{ fontSize: '14px' }}
        />
      );
    },
  },
];

type Type = 'channel' | 'department' | 'depTop10' | 'userTop10';

export default () => {
  const { id: companyId, departments } = useCompany();

  const defaultCreatedAt: [dayjs.Dayjs, dayjs.Dayjs] = [
    dayjs().startOf('month'),
    dayjs().endOf('month'),
  ];

  const [departmentId, setDepartmentId] = useState<I.ID | null>(null);

  const departmentIds = useMemo(() => {
    return findAllDepartmentId(departments, departmentId).map(
      (item) => item.id,
    );
  }, [departmentId, departments]);

  const [loanedAt, setLoanedAt] =
    useState<[dayjs.Dayjs, dayjs.Dayjs]>(defaultCreatedAt);

  const [type, setType] = useState<Type>('channel');

  const {
    loading,
    data: rawAllData,
    refresh,
  } = useRequest(
    () => {
      return dataAnalyzeController
        .loanAnalyze(companyId, {
          start: formatStartTime(loanedAt[0]),
          end: formatEndTime(loanedAt[1]),
        })
        .then((res) =>
          res.map((item) => ({
            ...item,
            loanAmount: Number(item.loanAmount),
            brokerage: Number(item.brokerage),
          })),
        );
    },
    {
      refreshDeps: [companyId, loanedAt],
    },
  );

  const data = useMemo<TableData[] | undefined>(() => {
    const depIds = departments.map((item) => item.id);

    const rawData = rawAllData?.filter((item) => {
      // 只能看到自己区域的
      return depIds.includes(item.user?.departmentId || 0);
    });
    switch (type) {
      case 'channel':
        const values = rawData
          ?.filter((item) => {
            if (departmentId == null) {
              return true;
            }
            return item.user?.departmentId === departmentId;
          })
          ?.reduce<Record<string, (typeof rawData)[0]>>((prev, cur) => {
            const key = cur.channel;
            const value = prev[key];
            return {
              ...prev,
              [key]: {
                ...value,
                ...cur,
                loanAmount: (value?.loanAmount || 0) + cur.loanAmount,
                brokerage: (value?.brokerage || 0) + cur.brokerage,
              },
            };
          }, {});
        return Object.entries(values || {})
          .map(([_, value]) => ({
            name: value.channel,
            loanAmount: value.loanAmount,
            brokerage: value.brokerage,
          }))
          .sort((a, b) => b.loanAmount - a.loanAmount);
      case 'department': {
        // 先找到所选的 departmentId 所有的直接子区域
        const deps = findChildrenDepartment(departments, departmentId);
        if (deps.length === 0) {
          return [];
        }
        const value = deps.map((dep) => {
          // 找到 dep 下所有的区域
          const children = findAllDepartmentId(departments, dep.id).map(
            (item) => item.id,
          );
          // 从 rawData 过滤出 children 的数据
          return {
            name: dep.name,
            loanAmount:
              rawData
                ?.filter((item) =>
                  children.includes(item.user?.departmentId || 0),
                )
                .reduce((prev, cur) => prev + cur.loanAmount, 0) || 0,
            brokerage:
              rawData
                ?.filter((item) =>
                  children.includes(item.user?.departmentId || 0),
                )
                .reduce((prev, cur) => prev + cur.brokerage, 0) || 0,
          };
        });
        const parent = rawData?.filter(
          (item) => item.user?.departmentId === departmentId,
        );
        return [
          {
            name: '区域领导',
            loanAmount:
              parent?.reduce((prev, cur) => prev + cur.loanAmount, 0) || 0,
            brokerage:
              parent?.reduce((prev, cur) => prev + cur.brokerage, 0) || 0,
          },
          ...value.sort((a, b) => sortName(a.name, b.name)),
        ];
      }
      case 'depTop10': {
        // 找到指定区域的所有的一级区域
        const deps = departments.filter((dep) => {
          // dep.id 不作为其他的区域的 parentId，说明它是一级区域
          return departments.every((item) => item.parentId !== dep.id);
        });
        return deps
          .map((dep) => {
            return {
              name: flatDepartment(departments, dep.id)
                .map((item) => item.name)
                .reverse()
                .join('/'),
              loanAmount:
                rawData
                  ?.filter((item) => item.user?.departmentId === dep.id)
                  .reduce((prev, cur) => prev + cur.loanAmount, 0) || 0,
              brokerage:
                rawData
                  ?.filter((item) => item.user?.departmentId === dep.id)
                  .reduce((prev, cur) => prev + cur.brokerage, 0) || 0,
            };
          })
          .sort((a, b) => b.loanAmount - a.loanAmount)
          .slice(0, 10);
      }
      case 'userTop10': {
        // 找到区域的所有的子区域
        const deps = findAllDepartmentId(departments, departmentId).map(
          (item) => item.id,
        );
        if (deps.length === 0) {
          return [];
        }
        const values = rawData
          ?.filter((item) => deps.includes(item.user?.departmentId || 0))
          .reduce<Record<Long, (typeof rawData)[0]>>((prev, cur) => {
            const key = cur.user.id;
            const value = prev[key];
            return {
              ...prev,
              [key]: {
                ...value,
                ...cur,
                loanAmount: (value?.loanAmount || 0) + cur.loanAmount,
                brokerage: (value?.brokerage || 0) + cur.brokerage,
              },
            };
          }, {});
        return Object.entries(values || {})
          .map(([_, value]) => ({
            name: `${flatDepartment(departments, value.user?.departmentId)
              .map((item) => item.name)
              .reverse()
              .join('/')} - ${value.user.name}`,
            loanAmount: value.loanAmount,
            brokerage: value.brokerage,
          }))
          .sort((a, b) => b.loanAmount - a.loanAmount)
          .slice(0, 10);
      }
    }
    return rawData?.map((item) => ({
      name: item.channel,
      loanAmount: Number(item.loanAmount),
      brokerage: Number(item.brokerage),
    }));
  }, [rawAllData, type, departmentIds]);

  return (
    <Row>
      <Col span={24}>
        <Space>
          <Segmented
            options={[
              {
                label: '渠道',
                value: 'channel',
              },
              {
                label: '区域',
                value: 'department',
              },
              {
                label: '区域 Top10',
                value: 'depTop10',
              },
              {
                label: '员工 Top10',
                value: 'userTop10',
              },
            ]}
            value={type}
            onChange={(v) => setType(v as Type)}
            disabled={loading}
          />
          <DepartmentSelect
            style={{ width: '300px' }}
            value={departmentId}
            onSelect={(v) => {
              setDepartmentId(v);
            }}
            onClear={() => setDepartmentId(null)}
          />
          <DateRangePicker
            disabled={loading}
            value={loanedAt}
            onChange={(v) => {
              setLoanedAt(
                (v as [dayjs.Dayjs, dayjs.Dayjs]) || defaultCreatedAt,
              );
            }}
          />
          <Button type="primary" loading={loading} onClick={refresh}>
            查询
          </Button>
        </Space>
      </Col>
      <Col span={24} style={{ marginTop: '20px' }}>
        <Table
          className="normal-table"
          size="small"
          bordered
          rowKey="name"
          dataSource={data}
          loading={loading}
          pagination={false}
          columns={columns}
          summary={(row) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>总计</Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <Statistic
                      value={
                        row.reduce((prev, cur) => prev + cur.loanAmount, 0) /
                        100
                      }
                      valueStyle={{ fontSize: '14px' }}
                      precision={2}
                      prefix="¥"
                    />
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <Statistic
                      valueStyle={{ fontSize: '14px' }}
                      value={
                        row.reduce((prev, cur) => prev + cur.brokerage, 0) / 100
                      }
                      precision={2}
                      prefix="¥"
                    />
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Col>
    </Row>
  );
};
