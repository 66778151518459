import {
  APPLICATION_JSON,
  CONTENT_TYPE,
  InvokeParams,
  interceptors,
  request,
} from '@huolala-tech/request';
import { RequestFailed } from './RequestFailed';
import { Runtime } from '../api';
import * as Sentry from '@sentry/react';
import { message } from 'antd';

export interface Options extends Omit<InvokeParams, 'method' | 'url' | 'data'> {
  data?: unknown;
  params?: Record<string, any>;
  prefix?: string;
}

const removeUndefiendValues = (params: unknown) => {
  if (!params) return {};
  if (params instanceof URLSearchParams) {
    return params;
  }
  return JSON.parse(JSON.stringify(params));
};

export abstract class BaseApi {
  protected abstract defaultPrefix: string;

  public static async request<T = unknown>(
    options: InvokeParams & { params?: Record<string, any> },
  ) {
    const { url, params, headers = {}, ...rest } = options;
    const qs = new URLSearchParams(removeUndefiendValues(params))
      .toString()
      // Prepends '?' character if not empty.
      .replace(/^./, '?$&');
    const res = await request<T>({
      url: `${url}${qs}`,
      headers: {
        [CONTENT_TYPE]: APPLICATION_JSON,
        ...headers,
      },
      ...rest,
    });
    const { statusCode } = res;
    if (statusCode >= 200 && statusCode < 300) {
      return res.data;
    }
    throw new RequestFailed(res);
  }

  public async request<T = unknown>(
    method: string,
    path: string,
    options: Options = {},
  ) {
    const { prefix = this.defaultPrefix, data, ...rest } = options;
    const url = `${prefix}${path}`;
    return BaseApi.request<T>({
      method,
      url,
      data: data as any,
      ...rest,
    });
  }

  public get<T>(url: string, options?: Options) {
    return this.request<T>('GET', url, options);
  }

  public post<T>(url: string, options?: Options) {
    return this.request<T>('POST', url, options);
  }

  public patch<T>(url: string, options?: Options) {
    return this.request<T>('PATCH', url, options);
  }

  public delete<T>(url: string, options?: Options) {
    return this.request<T>('DELETE', url, options);
  }

  public put<T>(url: string, options?: Options) {
    return this.request<T>('PUT', url, options);
  }
}

function getApiHost() {
  if (process.env.NODE_ENV === 'development') {
    // return 'http://47.101.46.16:8080/api';
    return 'https://dev-api.home.hui.lu';
  }

  return process.env.CRM_API_HOST || '/api';
}

function getPushHost() {
  if (process.env.NODE_ENV === 'development') {
    return 'wss://dev-push.home.hui.lu';
  }

  const url = process.env.CRM_PUSH_HOST || '/ws';

  if (url.startsWith('ws://') || url.startsWith('wss://')) {
    return url;
  }

  const protocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';

  return `${protocol}${window.location.host}${
    url.startsWith('/') ? url : `/${url}`
  }`;
}

export const pushHost = getPushHost();

export const apiHost = getApiHost();

Runtime.base = apiHost;

interceptors.request.use((req) => {
  let token = sessionStorage.getItem('token');
  if (window.location.pathname.startsWith('/h5')) {
    const h5Info = localStorage.getItem('h5_info');
    if (h5Info) {
      const info = JSON.parse(h5Info) as { token: string };
      token = info.token;
    }
  }
  req.headers = {
    ...Object(req.headers),
    Authorization: token,
  };
});

interceptors.response.use((resp) => {
  if (resp.statusCode >= 400) {
    if (resp.statusCode === 401) {
      message.error('登录已过期，请重新登录');
      window.location.href = '/login';
      return;
    }
    const e = new RequestFailed(resp);
    Sentry.captureException(e, {
      extra: {
        code: resp.statusCode,
      },
    });
    throw e;
  }
});

export default class CRMApi extends BaseApi {
  protected defaultPrefix = apiHost;
}
