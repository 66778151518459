import {
  Button,
  Col,
  Descriptions,
  Form,
  Image,
  Input,
  Modal,
  QRCode,
  Row,
  Space,
  Tooltip,
  Upload,
  message,
} from 'antd';
import { useCompany } from '../hooks/company';
import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useRequest } from 'ahooks';
import { userController } from '../api';
import '../styles/userinfo.scss';
import OSSImage, { AvatarImg, fallback } from '../components/OSSImage';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import SelfPauseSwitcher from '../components/SelfPauseSwitcher';

const UpdatePassword = () => {
  const [open, setOpen] = useState<boolean>();
  const [form] = Form.useForm();
  const { id: companyId } = useCompany();

  const { runAsync: updatePassword, loading } = useRequest(
    (oldPassword: string, newPassword: string) => {
      return userController.updatePassword(companyId, oldPassword, newPassword);
    },
    {
      manual: true,
      onSuccess: () => {
        message.success('修改成功，请重新登录');
        window.location.href = '/login';
      },
      onError: (e) => {
        message.error(e.message);
      },
    },
  );

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
  }, [open]);

  const onSubmit = () => {
    return form
      .validateFields()
      .then((values) => {
        return updatePassword(values.oldPassword, values.newPassword);
      })
      .catch((e) => {});
  };
  return (
    <>
      <Modal
        open={open}
        title="修改密码"
        onCancel={() => setOpen(false)}
        confirmLoading={loading}
        onOk={onSubmit}
      >
        <Form form={form}>
          <Form.Item
            label="原始密码"
            name="oldPassword"
            rules={[{ required: true }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="新的密码"
            name="newPassword"
            rules={[
              ({ getFieldValue }) => ({
                required: true,
                min: 6,
                max: 128,
                validator(rule, value, callback) {
                  const oldPassword = getFieldValue('oldPassword');
                  return oldPassword !== value
                    ? Promise.resolve()
                    : Promise.reject('新密码不能与原始密码相同');
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="重复密码"
            name="newPassword2"
            rules={[
              ({ getFieldValue }) => {
                return {
                  required: true,
                  validator(rule, value, callback) {
                    return getFieldValue('newPassword') === value
                      ? Promise.resolve()
                      : Promise.reject('两次密码不一致');
                  },
                };
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
      <Button type="link" onClick={() => setOpen(true)}>
        修改密码
      </Button>
    </>
  );
};

const Avatar = () => {
  const { id: companyId, currentUser, refresh } = useCompany();
  const [open, setOpen] = useState<boolean>();

  const [c, setC] = useState<number>(0);

  const [form] = Form.useForm();

  const { run: updateAvatar, loading } = useRequest(
    userController.updateAvatar,
    {
      manual: true,
      onError: (e) => {
        message.error(e.message || '更新头像失败');
      },
      onSuccess: () => {
        message.success('更新头像成功');
        setOpen(false);
        refresh();
        setC((p) => p + 1);
      },
    },
  );

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
  }, [form, open]);

  const onSubmit = () => {
    return form
      .validateFields()
      .then((values) => {
        return updateAvatar(companyId, currentUser.id, values.avatar.file);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <Space>
      <Tooltip title="点击修改头像">
        <AvatarImg
          userId={currentUser.id}
          onClick={() => setOpen(true)}
          reload={c}
        />
      </Tooltip>
      <Modal
        title="更新头像"
        open={open}
        onCancel={() => setOpen(false)}
        confirmLoading={loading}
        onOk={onSubmit}
      >
        <Form form={form}>
          <Row>
            <Col span={12}>
              <Form.Item
                name="avatar"
                valuePropName="valueList"
                rules={[{ required: true }]}
              >
                <Upload
                  beforeUpload={(file) => false}
                  accept="image/*"
                  multiple={false}
                  maxCount={1}
                  name="avatar"
                  listType="picture-card"
                  showUploadList={true}
                  // beforeUpload={beforeUpload}
                  // onChange={handleChange}
                >
                  <div>
                    {loading ? <LoadingOutlined /> : <PlusOutlined />}
                    <div style={{ marginTop: 8 }}>选择图片</div>
                  </div>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Space>
  );
};

export default () => {
  const {
    id: companyId,
    name: companyName,
    currentUser,
    departments,
  } = useCompany();

  const departmentName = useMemo(() => {
    if (!currentUser || !departments) {
      return '';
    }
    const department = departments.find(
      (d) => d.id === currentUser.departmentId,
    );
    return department?.name || '';
  }, [currentUser, departments]);

  const qrcodeURL = useMemo(() => {
    return `${window.location.origin}/app-download`;
  }, []);
  return (
    <Row className="userinfo-box" style={{ minWidth: '880px' }} gutter={20}>
      <Col span={12}>
        <div>
          <Descriptions column={1} title={'个人信息'}>
            <Descriptions.Item label={'公司名称'}>
              {companyName}
            </Descriptions.Item>
            <Descriptions.Item label={'id'}>{currentUser.id}</Descriptions.Item>
            <Descriptions.Item label={'账号'}>
              {currentUser.mobile}
            </Descriptions.Item>
            <Descriptions.Item label={'头像'}>
              <Avatar />
            </Descriptions.Item>
            <Descriptions.Item label={'密码'}>
              <UpdatePassword />
            </Descriptions.Item>
            <Descriptions.Item label={'姓名'}>
              {currentUser.name}
            </Descriptions.Item>
            <Descriptions.Item label={'角色'}>
              {currentUser.role?.name}
            </Descriptions.Item>
            <Descriptions.Item label={'区域'}>
              {departmentName}
            </Descriptions.Item>
            <Descriptions.Item label={'注册时间'}>
              {dayjs(currentUser.createdAt).format('YYYY-MM-DD')}
            </Descriptions.Item>
            <Descriptions.Item label={'RMS 推送'}>
              <SelfPauseSwitcher />
            </Descriptions.Item>
          </Descriptions>
        </div>
      </Col>
      <Col span={12}>
        <div>
          <Space direction="vertical">
            <h1>扫码下载手机客户端</h1>
            <QRCode value={qrcodeURL} size={250} />
          </Space>
        </div>
      </Col>
    </Row>
  );
};
