import { Modal, Space, TreeSelect, message } from 'antd';
import CompanySelect from './CompanySelect';
import { useRequest } from 'ahooks';
import { companyController, userController } from '../api';
import { useEffect, useMemo, useState } from 'react';
import { useCompany } from '../hooks/company';
import { buildTree } from './DepartmentSelect';
import { sortName } from '../utils';

const DepartmentSelect = ({
  id,
  loading,
  onSelect,
}: {
  id?: I.ID;
  loading?: boolean;
  onSelect: (value: I.ID) => void;
}) => {
  const rootDepartmentId = null;

  const { data: departments, loading: departmentListLoading } = useRequest(
    () => {
      if (!id) {
        return Promise.resolve([]);
      }
      return companyController.listDepartment(id);
    },
    {
      refreshDeps: [id],
    },
  );

  const allDepartments = departments || [];

  const tree = useMemo(() => {
    const children = buildTree(allDepartments, null)?.sort((a, b) =>
      sortName(a.title, b.title),
    );
    // 说明它是根区域
    const dep = allDepartments.find((item) => item.id === rootDepartmentId);
    if (dep) {
      return [
        {
          key: dep.id,
          title: dep.name,
          value: dep.id,
          children,
        },
      ];
    }
    return children;
  }, [allDepartments, rootDepartmentId]);

  const treeData = useMemo(() => {
    if (!!tree?.length) {
      return tree;
    }

    // 如果当前区域为空，就把自己当前的区域给加上去
    const currentDepartment = departments?.find(
      (item) => item.id === rootDepartmentId,
    );
    if (!currentDepartment) {
      return [];
    }

    return [
      {
        key: currentDepartment?.id,
        title: currentDepartment.name,
        value: currentDepartment.id,
      },
    ];
  }, [tree, departments, rootDepartmentId]);
  return (
    <TreeSelect
      style={{ width: 200 }}
      showSearch
      allowClear
      loading={loading || departmentListLoading}
      disabled={!tree?.length}
      onSelect={(value) => {
        onSelect(value);
      }}
      treeDefaultExpandAll
      placeholder="选择区域"
      treeData={treeData}
    />
  );
};

export default ({
  id,
  name,
  open,
  onCancel,
}: {
  id: I.ID;
  name: string;
  open?: boolean;
  onCancel: () => void;
}) => {
  const [targetCompanyId, setTargetCompanyId] = useState<I.ID>();

  const [targetDepId, setTargetDepId] = useState<I.ID>();

  const { id: companyId } = useCompany();

  useEffect(() => {
    if (open) {
      setTargetCompanyId(undefined);
    }
  }, [open, setTargetCompanyId]);

  const { run: transfer, loading: transferLoading } = useRequest(
    (targetId: I.ID) => {
      return userController.transfer(companyId, id, targetId);
    },
    {
      manual: true,
      onSuccess: () => {
        message.success('转移成功');
        onCancel();
      },
      onError: (e) => message.error(`操作失败：${e.message}`),
    },
  );

  const onSubmit = () => {
    if (targetDepId) {
      transfer(targetDepId);
    }
  };
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title={`转移员工【${name}】`}
      onOk={onSubmit}
      confirmLoading={transferLoading}
    >
      <Space direction="vertical">
        <Space>
          <span>目标公司</span>
          <CompanySelect
            style={{ width: 200 }}
            onSelect={(value) => setTargetCompanyId(value)}
          />
        </Space>
        <Space>
          <span>目标区域</span>
          <DepartmentSelect
            id={targetCompanyId}
            onSelect={(v) => setTargetDepId(v)}
          />
        </Space>
      </Space>
    </Modal>
  );
};
