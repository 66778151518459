import { Space, Tooltip } from 'antd';

import { PhoneOutlined } from '@ant-design/icons';
import { useMemo } from 'react';

export default ({
  mobile,
  withTips,
  showMobile,
}: {
  mobile?: string;
  showMobile?: boolean;
  withTips?: boolean;
}) => {
  const maskMobile = useMemo(() => {
    if (showMobile) {
      return mobile;
    }
    if (!mobile) {
      return '-';
    }

    if (mobile?.length === 11) {
      return mobile.replace(/(\d{4})\d{4}(\d{3})/, '$1****$2');
    }
  }, [mobile, showMobile]);

  return (
    <Space>
      <PhoneOutlined />
      <Tooltip title={withTips && mobile}>
        <span>{maskMobile}</span>
      </Tooltip>
    </Space>
  );
};
