import { useRequest } from 'ahooks';
import {
  addDepartment,
  listDepartment,
  updateDepartment,
} from '../../apis/api';
import { useCompany } from '../../hooks/company';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Tree,
  message,
} from 'antd';
import type { DataNode } from 'antd/es/tree';
import { useEffect, useMemo, useState } from 'react';
import { BaseDepartmentDTO, companyController } from '../../api';
import { sortName } from '../../utils';
import { PlusCircleOutlined } from '@ant-design/icons';

const buildTree = (
  rootID?: I.ID,
  list?: BaseDepartmentDTO[],
): DataNode[] | undefined => {
  if (!list?.length) {
    return undefined;
  }
  const root = list
    .filter(({ parentId }) => {
      if (rootID === undefined) {
        return !parentId;
      }
      return parentId === rootID;
    })
    .sort((a, b) => sortName(a.name, b.name));

  return root.map((item) => {
    return {
      key: item.id,
      title: item.name,
      children: buildTree(item.id, list),
    };
  });
};

const AddDepartmentInfo = ({
  parentId,
  refresh,
}: {
  parentId: I.ID;
  refresh: () => void;
}) => {
  const [form] = Form.useForm();
  const { id: companyId } = useCompany();

  useEffect(() => {
    form.resetFields();
  }, [parentId]);

  const { runAsync: update, loading } = useRequest(
    (data: Parameters<typeof addDepartment>[1]) => {
      return companyController.createDepartment(companyId, data);
    },
    {
      manual: true,
      refreshDeps: [companyId, parentId],
      onSuccess: () => {
        message.success('更新成功');
        refresh();
      },
      onError: (e) => {
        message.error(e.message);
      },
    },
  );

  const onSubmit = () => {
    return form
      .validateFields()
      .then((values) => {
        return update({ ...values, parentId });
      })
      .catch((e) => {});
  };

  return (
    <Card
      loading={loading}
      title={'新增子区域信息'}
      actions={[
        <Button loading={loading} onClick={onSubmit} type="primary" key="save">
          新增
        </Button>,
      ]}
    >
      <Form form={form}>
        <Form.Item name="name" label="区域名称" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="description" label="区域描述">
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Card>
  );
};

const DepartmentInfo = ({
  department,
  refresh,
  unSelect,
}: {
  department: BaseDepartmentDTO;
  refresh: () => void;
  unSelect: () => void;
}) => {
  const [form] = Form.useForm();
  const { id: companyId } = useCompany();

  useEffect(() => {
    form.resetFields();
  }, [department]);

  const { runAsync: update, loading } = useRequest(
    (data: Parameters<typeof updateDepartment>[2]) => {
      return updateDepartment(companyId, department.id, data);
    },
    {
      manual: true,
      refreshDeps: [companyId, department],
      onSuccess: () => {
        message.success('更新成功');
        refresh();
      },
      onError: (e) => {
        message.error(e.message);
      },
    },
  );

  const { run: deleteDepartment, loading: deleteLoading } = useRequest(
    () => {
      return companyController.removeDepartment(companyId, department.id);
    },
    {
      manual: true,
      onError: (e) => {
        message.error(e.message);
      },
      onSuccess: () => {
        message.success('删除成功');
        unSelect();
        refresh();
      },
    },
  );

  const onSubmit = () => {
    return form
      .validateFields()
      .then((values) => {
        return update({ ...values });
      })
      .catch((e) => {});
  };

  return (
    <Card
      loading={loading}
      title={'修改区域信息'}
      actions={[
        <Button
          loading={deleteLoading}
          onClick={() => deleteDepartment()}
          type="primary"
          danger
          key="delete"
        >
          删除
        </Button>,
        <Button loading={loading} onClick={onSubmit} type="primary" key="save">
          保存
        </Button>,
      ]}
    >
      <Form form={form} initialValues={{ ...department }}>
        <Form.Item name="name" label="区域名称" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="description" label="区域描述">
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Card>
  );
};

export default () => {
  const { id: companyId, hasPermission, name: companyName } = useCompany();

  const [expandedKeys, setExpanedKeys] = useState<I.ID[]>([]);

  const [selectedDepartment, setSelectedDepartment] =
    useState<BaseDepartmentDTO>();

  const { data, loading, refresh } = useRequest(
    () => {
      return companyController.listDepartment(companyId);
    },
    {
      refreshDeps: [companyId],
    },
  );

  useEffect(() => {
    setExpanedKeys([...(data?.map(({ id }) => id) || []), 'company']);
  }, [data]);

  const tree = useMemo(() => {
    const children = buildTree(undefined, data);
    return [
      {
        key: 'company',
        title: (
          <Space>
            <span style={{ fontSize: '20px' }}>{companyName}</span>
            {/* <PlusCircleOutlined /> */}
          </Space>
        ),
        children,
      },
    ];
  }, [data, companyName]);

  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={12}>
          <Tree
            showLine
            showIcon
            expandedKeys={expandedKeys}
            // onSelect={onSelect}
            treeData={tree}
            onExpand={(keys) => {
              setExpanedKeys(keys);
            }}
            onSelect={([id]) => {
              if (id === 'company') {
                setSelectedDepartment(undefined);
                return;
              }
              setSelectedDepartment(data?.find((dep) => dep.id === id));
            }}
          />
        </Col>
        <Col>
          {selectedDepartment &&
          hasPermission('page:system:organization:edit') ? (
            <Row>
              <Col style={{ padding: '5px' }}>
                <DepartmentInfo
                  department={selectedDepartment}
                  refresh={refresh}
                  unSelect={() => setSelectedDepartment(undefined)}
                />
              </Col>
              <Col style={{ padding: '5px' }}>
                <AddDepartmentInfo
                  parentId={selectedDepartment.id}
                  refresh={refresh}
                />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col style={{ padding: '5px' }}>
                <AddDepartmentInfo refresh={refresh} parentId={0} />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Spin>
  );
};
