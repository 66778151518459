import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

export default () => {
  return (
    <Tooltip
      overlayInnerStyle={{ width: '400px' }}
      title={
        <p>
          以下几类客户遇到星级和状态修改成：1星--捣乱申请 <br />
          一：空号、停机（注意是否是手机号码问题） <br />
          二：客户不来上海了、同行 <br />
          三：再打报警之类很暴躁的客户 <br />
          四：黑户、老赖、执行人等 每个人都要严格执行。 <br />
        </p>
      }
    >
      <InfoCircleOutlined />
    </Tooltip>
  );
};
