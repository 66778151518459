import { ActionType, ProColumns, ProTable } from '@ant-design/pro-components';

import { useCompany } from '../../hooks/company';
import {
  CustomerGroupEnum,
  CustomerStateEnum,
  ListOutsideParamsDTO,
  OutsideAllocateItemDTO,
  OutsideItemDTO,
  outsideController,
} from '../../api';
import { InboxOutlined } from '@ant-design/icons';
import CustomerMobile from '../../components/CustomerMobile';
import dayjs from 'dayjs';
import {
  Button,
  Col,
  Input,
  Modal,
  Popconfirm,
  Progress,
  Rate,
  Row,
  Select,
  Space,
  Table,
  Upload,
  UploadFile,
  message,
} from 'antd';
import { useRequest } from 'ahooks';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import CustomerStateSelect from '../../components/CustomerStateSelect';
import { RcFile } from 'antd/es/upload';
import Allocate from '../../components/Allocate';
import '../../styles/common.scss';
import { customerGroupMapping } from '../../constants';

const refreshContext = createContext<{ refresh: () => void }>({ refresh() {} });

const RemoveButton = ({ id }: { id: I.ID }) => {
  const { id: companyId, hasPermission } = useCompany();

  const { refresh } = useContext(refreshContext);

  const { loading, run } = useRequest(
    () => {
      return outsideController.remove(companyId, id);
    },
    {
      manual: true,
      onSuccess: () => {
        message.success('删除成功');
        refresh();
      },
      onError: (e) => {
        message.error(`操作失败：${e.message}`);
      },
    },
  );
  const canDelete = useMemo(() => {
    return hasPermission('page:customer-list:outsource:delete');
  }, [hasPermission]);
  return (
    <Popconfirm
      title="确认"
      disabled={!canDelete}
      onConfirm={() => {
        run();
      }}
    >
      <Button type="primary" danger loading={loading} disabled={!canDelete}>
        删除
      </Button>
    </Popconfirm>
  );
};

const columns: ProColumns<OutsideItemDTO>[] = [
  {
    dataIndex: 'id',
    search: false,
    title: 'id',
  },
  {
    dataIndex: 'name',
    search: false,
    title: '姓名',
  },
  {
    dataIndex: 'mobile',
    title: '手机号码',
    render: (_, { mobile }) => {
      return <CustomerMobile mobile={mobile} withTips />;
    },
  },
  {
    dataIndex: 'star',
    title: '星级',
    valueType: 'rate',
  },
  {
    width: '20%',
    dataIndex: 'remark',
    search: false,
    title: '备注',
  },
  {
    title: '导入时间',
    dataIndex: 'createdAt',
    valueType: 'dateRange',
    search: {
      transform: (value) => {
        return {
          createdAt: [dayjs(value[0]), dayjs(value[1])],
        };
      },
    },
    render: (_, { createdAt }) =>
      dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    title: '操作',
    search: false,
    render: (_, { id }) => {
      return <RemoveButton id={id} />;
    },
  },
];

const ReAllocateButton = ({
  outsideList,
}: {
  outsideList: OutsideItemDTO[];
}) => {
  const { id: companyId } = useCompany();

  const { refresh } = useContext(refreshContext);

  const [open, setOpen] = useState<boolean>(false);
  const [remark, setRemark] = useState<string>();
  const [channel, setChannel] = useState<string>();
  const [allocation, setAllocation] = useState<OutsideAllocateItemDTO[]>();
  const [group, setGroup] = useState<CustomerGroupEnum>(
    CustomerGroupEnum.NEW_ALLOT,
  );

  useEffect(() => {
    setRemark(undefined);
    setAllocation(undefined);
    setGroup(CustomerGroupEnum.NEW_ALLOT);
  }, [open]);

  const { loading, run } = useRequest(
    () => {
      return outsideController.reAllocate(companyId, {
        remark,
        allocation,
        group,
        channel,
      });
    },
    {
      manual: true,
      onSuccess: (failedList) => {
        if (failedList.length > 0) {
          message.warning(
            `成功分派 ${outsideList.length - failedList.length} 个客户，失败 ${
              failedList.length
            } 个客户`,
          );
        } else {
          message.success('分派成功');
        }
        refresh();
        setOpen(false);
      },
      onError: (e) => {
        message.error(`操作失败：${e.message}`);
      },
    },
  );

  return (
    <>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title="分派客户"
        confirmLoading={loading}
        destroyOnClose
        okButtonProps={{ disabled: !allocation?.length || !channel }}
        onOk={() => run()}
      >
        <Allocate
          targets={outsideList}
          onChange={(v) =>
            setAllocation(
              v.map((item) => ({
                outsideIds: item.targets,
                userId: item.userId,
              })),
            )
          }
          remark={remark}
          onRemarkChange={(v) => setRemark(v)}
        />
        <Row>
          <Col span={24}>
            <Space style={{ width: '100%' }}>
              <h4>渠道</h4>
              <Input
                value={channel}
                onChange={(v) => setChannel(v.target.value)}
              />
            </Space>
          </Col>
          <Col span={24}>
            <Space style={{ width: '100%' }}>
              <h4>分组</h4>
              <Select
                value={group}
                onChange={(v) => setGroup(v)}
                style={{ width: '200px' }}
              >
                {Object.entries(customerGroupMapping).map(([key, value]) => (
                  <Select.Option key={key} value={key}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Space>
          </Col>
        </Row>
      </Modal>
      <Button
        type="primary"
        disabled={outsideList.length === 0}
        onClick={() => {
          setOpen(true);
        }}
      >
        重新分派
      </Button>
    </>
  );
};

const { Dragger } = Upload;

const DropButton = () => {
  const { id: companyId } = useCompany();
  const [open, setOpen] = useState<boolean>(false);
  const [channel, setChannel] = useState<string>();
  const [initTotal, setInitTotal] = useState<I.ID>(0);
  const { refresh } = useContext(refreshContext);

  const [progress, setPropress] = useState<number>(0);
  const [failed, setFailed] = useState<string[]>([]);
  const [doing, setDoing] = useState<boolean>(false);

  const size = 50;

  const {
    data: total,
    loading: totalLoading,
    refreshAsync: refreshTotal,
  } = useRequest(
    () => {
      return outsideController.getTotal(companyId);
    },
    {
      manual: true,
      onSuccess: (v) => {
        if (initTotal === 0) {
          setInitTotal(v);
        }
      },
    },
  );

  const { runAsync, loading, cancel } = useRequest(
    (c: string) => {
      if (total === 0) {
        return Promise.resolve([]);
      }
      return outsideController.dropToPublic(companyId, size, c);
    },
    {
      manual: true,
      onBefore: () => {
        setDoing(true);
      },
      onError: (e) => {
        message.error(`操作失败：${e.message}`);
      },
    },
  );

  useEffect(() => {
    if (open) {
      refreshTotal();
      setChannel(undefined);
      setPropress(0);
    } else {
      refresh();
      cancel();
      setDoing(false);
    }
  }, [open, refreshTotal, refresh, cancel, setPropress]);

  const doTask = useCallback(async () => {
    if (total === 0) {
      setDoing(false);
      message.success('导入成功');
      return;
    }
    const failedMobile = await runAsync(channel!);
    if (failedMobile.length > 0) {
      setFailed((f) => [...f, ...failedMobile]);
    }
    setPropress((p) => p + size);
    const t = await refreshTotal();
    console.log('aaa', t);
    if (t) {
      await doTask();
    } else {
      setDoing(false);
    }
  }, [
    runAsync,
    refreshTotal,
    total,
    channel,
    setPropress,
    setFailed,
    setDoing,
  ]);

  return (
    <>
      <Modal
        title="抛入公共池"
        onCancel={() => setOpen(false)}
        open={open}
        maskClosable={false}
        closable={doing}
        confirmLoading={doing}
        onOk={() => doTask()}
        okButtonProps={{ disabled: !channel || total === 0, loading: doing }}
      >
        <Row>
          <Col span={24}>
            <Space>
              <span>渠道</span>
              <Input
                disabled={loading}
                value={channel}
                onChange={(v) => setChannel(v.target.value)}
              />
            </Space>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ marginTop: '5px' }}>
            <Progress
              percent={Math.round((progress / Number(initTotal || 0)) * 100)}
            />
          </Col>
          <Col span={24} style={{ marginTop: '5px' }}>
            <Space>
              <span>总计</span>
              <span>
                {initTotal} 条数据，已经导入 {progress}，重复数据{' '}
                {failed.length}
              </span>
            </Space>
          </Col>
        </Row>
      </Modal>
      <Button type="primary" onClick={() => setOpen(true)}>
        抛入公共池
      </Button>
    </>
  );
};

const ImportButton = () => {
  const { id: companyId } = useCompany();

  const [open, setOpen] = useState<boolean>();
  const [star, setStar] = useState<number>(1);
  const [state, setState] = useState<CustomerStateEnum>(
    CustomerStateEnum.WAIT_FOLLOW_UP,
  );
  const [file, setFile] = useState<UploadFile>();

  const { refresh } = useContext(refreshContext);

  useEffect(() => {
    if (open) {
      setStar(1);
      setState(CustomerStateEnum.WAIT_FOLLOW_UP);
      setFile(undefined);
    }
  }, [open]);

  const { loading, run } = useRequest(
    () => {
      return outsideController.importOutside(
        companyId,
        file as RcFile,
        state,
        star,
      );
    },
    {
      manual: true,
      onSuccess: ({ success, total }) => {
        if (success === total) {
          message.success('导入成功');
        } else {
          message.warning(`识别到 ${total} 条数据，导入成功 ${success} 条数据`);
        }
        refresh();
        setOpen(false);
      },
      onError: (e) => {
        message.error(`操作失败：${e.message}`);
      },
    },
  );

  return (
    <>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title="导入数据"
        confirmLoading={loading}
        onOk={() => run()}
        okButtonProps={{ disabled: !file }}
      >
        <Row>
          <Col span={12} style={{ marginTop: '10px' }}>
            <Space>
              <span>客户星级：</span>
              <Rate value={star} onChange={(v) => setStar(v)} />
            </Space>
          </Col>
          <Col span={12} style={{ marginTop: '10px' }}>
            <Space>
              <span>客户状态：</span>
              <CustomerStateSelect
                value={state}
                onSelect={(v) => setState(v)}
              />
            </Space>
          </Col>
          <Col span={24} style={{ marginTop: '20px' }}>
            <Dragger
              accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              name="file"
              multiple={false}
              onRemove={(file) => {
                setFile(undefined);
              }}
              beforeUpload={(file) => {
                setFile(file);
                return false;
              }}
              maxCount={1}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">选择或拖拽文件到此区域进行上传</p>
            </Dragger>
          </Col>
        </Row>
      </Modal>
      <Button type="primary" onClick={() => setOpen(true)}>
        导入数据
      </Button>
    </>
  );
};

export default () => {
  const { id: companyId, hasPermission } = useCompany();
  const ref = useRef<ActionType>();

  const permissions = useMemo(() => {
    const result = {
      canRemove: hasPermission(`page:customer-list:outsource:delete`),
      canAlloc: hasPermission('page:customer-list:outsource:alloc'),
      canImport: hasPermission('page:customer-list:outsource:import'),
    };
    return result;
  }, [hasPermission]);

  return (
    <refreshContext.Provider value={{ refresh: () => ref.current?.reload() }}>
      <ProTable<OutsideItemDTO, ListOutsideParamsDTO & { companyId: I.ID }>
        rowSelection={{
          selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
        }}
        className="pro-table"
        rowKey="id"
        actionRef={ref}
        columns={columns}
        bordered
        size="small"
        cardBordered={{ search: true }}
        params={{
          companyId,
        }}
        pagination={{
          pageSizeOptions: [20, 50, 100, 200, 500, 1000],
        }}
        dateFormatter="number"
        toolBarRender={(_, { selectedRows }) => [
          permissions.canAlloc && (
            <ReAllocateButton
              key={'allocate'}
              outsideList={selectedRows || []}
            />
          ),
          permissions.canImport && <ImportButton key={'import'} />,
          permissions.canAlloc && <DropButton key={'drop'} />,
        ]}
        request={async (
          { current, pageSize, companyId, ...params },
          sort,
          filter,
        ) => {
          return outsideController
            .list(companyId, current, pageSize, params)
            .then((resp) => ({
              data: resp.list,
              success: true,
              total: resp.total,
            }));
        }}
      />
    </refreshContext.Provider>
  );
};
