import { useRequest, useWebSocket } from 'ahooks';
import { Carousel, Space } from 'antd';
import { companyMessageController } from '../../api';
import { useCompany } from '../../hooks/company';
import { pushHost } from '../../apis/request';
import '../../styles/layout.scss';
import { SoundTwoTone } from '@ant-design/icons';
const contentStyle: React.CSSProperties = {
  margin: 0,
  height: '100%',
  lineHeight: '36px',
  // textAlign: 'center',
  // overflow: 'hidden',
  // color: '#fff',
  // background: '#364d79',
};

export default () => {
  const { id: companyId } = useCompany();

  const { data, refresh } = useRequest(
    () => {
      return companyMessageController.listTopMessage(companyId);
    },
    {
      refreshDeps: [companyId],
    },
  );

  useWebSocket(`${pushHost}/top?token=${sessionStorage.getItem('token')}`, {
    onMessage(e) {
      refresh();
    },
  });

  return (
    <Carousel
      dots={false}
      autoplay
      autoplaySpeed={30 * 1000}
      dotPosition="right"
      effect="fade"
    >
      {data?.map((item) => (
        <div
          className="marquee-box"
          key={item.id}
          style={{ textAlign: 'center' }}
        >
          <div>
            <p className="marqueeT" style={contentStyle}>
              {' '}
              <SoundTwoTone
                style={{ fontSize: '18px' }}
                twoToneColor="orange"
              />{' '}
              {item.title}
            </p>
          </div>
        </div>
      ))}
    </Carousel>
  );
};
