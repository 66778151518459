import { ActionType, ProColumns, ProTable } from '@ant-design/pro-components';
import { useCompany } from '../../hooks/company';
import {
  BaseDepartmentDTO,
  BaseUserDTO,
  ListVisitorParamsDTO,
  VisitorListItemDTO,
  visitorController,
} from '../../api';
import CustomerMobile from '../../components/CustomerMobile';
import UserInfoModal from '../../components/UserInfoModal';
import dayjs from 'dayjs';
import {
  Button,
  ButtonProps,
  Modal,
  Popconfirm,
  Radio,
  Space,
  Tag,
  message,
} from 'antd';
import {
  findChildrenDepartment,
  flatDepartment,
  formatEndTime,
  formatStartTime,
} from '../../utils';
import DepartmentSelect from '../../components/DepartmentSelect';
import { createContext, useContext, useMemo, useRef } from 'react';
import OSSImage from '../../components/OSSImage';
import AddUpdateVisitor from '../../components/AddUpdateVisitor';
import { useRequest } from 'ahooks';
import '../../styles/common.scss';
import CustomerChannelSelect from '../../components/CustomerChannelSelect';
import UserSelect from '../../components/UserSelect';

export type VisitorQuerySession = Partial<ListVisitorParamsDTO>;

export const setVisitorQuerySession = (v: VisitorQuerySession) => {
  (window as any).__$$visitorQuerySession = v;
};

const getVisitorQuerySession = () => {
  const v = (window as any).__$$visitorQuerySession as VisitorQuerySession;
  return v;
};

const refreshContext = createContext<{
  refresh: () => void;
  showMobile?: boolean;
  canEdit?: boolean;
}>({ refresh() {} });

const UserInfo = ({ user }: { user: BaseUserDTO }) => {
  const { departments } = useCompany();
  const department = flatDepartment(departments, user.departmentId)
    .map(({ name }) => name)
    .reverse();
  return (
    <Space direction="vertical">
      <UserInfoModal id={user.id} name={user.name} />
      <span>{department.join('/')}</span>
    </Space>
  );
};

const RemoveButton = ({
  visitorId,
  refresh,
  ...props
}: { visitorId: I.ID; refresh: () => void } & ButtonProps) => {
  const { id: companyId } = useCompany();

  const { runAsync, loading } = useRequest(
    () => {
      return visitorController.remove(companyId, visitorId);
    },
    {
      manual: true,
      onSuccess: () => {
        message.success('删除成功');
        refresh();
      },
    },
  );
  return (
    <Popconfirm title="确认删除" onConfirm={runAsync}>
      <Button type="primary" danger {...props} loading={loading}>
        删除
      </Button>
    </Popconfirm>
  );
};

const ImageModal = ({
  text,
  visitorId,
  type,
  oid,
}: {
  text: string;
  visitorId: I.ID;
  type: 'sign' | 'contract';
  oid?: I.ID;
}) => {
  const [modal, contextHolder] = Modal.useModal();

  const { id: companyId } = useCompany();

  const url = useMemo(() => {
    if (!oid) {
      return `visitor/${visitorId}/${type}`;
    }
    return `old-visitor/${companyId}/${oid}/${type}.jpg`;
  }, [visitorId, type, oid, companyId]);

  return (
    <>
      <Button
        type="link"
        onClick={() => {
          modal.info({
            title: '查看图片',
            content: <OSSImage path={url} />,
          });
        }}
      >
        {text}
      </Button>
      {contextHolder}
    </>
  );
};

const SignTag = ({ sign }: { sign?: boolean }) => {
  if (sign) {
    return <Tag color="#4fc08d">已签约</Tag>;
  }

  return <Tag color="orange">未签约</Tag>;
};

const SuccessTag = ({ sign }: { sign?: boolean }) => {
  if (sign) {
    return <Tag color="#4fc08d">已成功</Tag>;
  }

  return <Tag color="orange">未成功</Tag>;
};

const columns: ProColumns<VisitorListItemDTO>[] = [
  {
    dataIndex: 'id',
    search: false,
    title: 'id',
  },
  {
    dataIndex: 'createdAt',
    valueType: 'dateRange',
    order: 998,
    search: {
      transform: (createdAt) => {
        return {
          createdAt: [
            formatStartTime(dayjs(createdAt[0])),
            formatEndTime(dayjs(createdAt[1])),
          ],
        };
      },
    },
    title: '上门日期',
    render: (_, { createdAt }) => dayjs(createdAt).format('YYYY-MM-DD'),
  },
  {
    title: '业务员',
    renderFormItem: () => {
      return <UserSelect allowClear mode="multiple" />;
    },
    search: {
      transform: (userIdList) => {
        return { userIdList };
      },
    },
    render: (_, { user }) => {
      return <UserInfo user={user} />;
    },
    fieldProps: {
      placeholder: '手机号码/姓名',
    },
  },
  {
    render: (_, dto) => {
      const { showMobile } = useContext(refreshContext);
      return (
        <Space direction="vertical">
          <Space>
            <span>{dto.visitorName}</span>
            {!dto.agentMobile && <SignTag sign={dto.isSign} />}
          </Space>
          <CustomerMobile
            mobile={dto.visitorMobile}
            withTips
            showMobile={showMobile}
          />
        </Space>
      );
    },
    search: {
      transform: (visitorKeyword) => {
        return { visitorKeyword };
      },
    },
    fieldProps: {
      placeholder: '手机号码/姓名',
    },
    title: '贷款人',
  },
  {
    title: '渠道',
    dataIndex: 'channel',
    render: (_, dto) => {
      return (
        <Space direction="vertical">
          <span>{dto.channel}</span>
          <span>
            {dto.customerCreateTime
              ? dayjs(dto.customerCreateTime).format('YYYY-MM-DD HH:mm')
              : '-'}
          </span>
        </Space>
      );
    },
    renderFormItem: () => <CustomerChannelSelect />,
  },
  {
    render: (_, dto) => {
      if (!dto.agentMobile) {
        return null;
      }
      return (
        <Space direction="vertical">
          <Space>
            <span>{dto.agentName}</span>
            <SuccessTag sign={dto.isSign} />
          </Space>
          <CustomerMobile withTips mobile={dto.agentMobile} />
        </Space>
      );
    },
    fieldProps: {
      placeholder: '手机号码/姓名',
    },
    search: {
      transform: (agentMobile) => {
        return { agentMobile };
      },
    },
    title: '拓展人',
  },
  {
    title: '贷款信息',
    search: false,
    render: (_, dto) => {
      return (
        <Space direction="vertical">
          <span>
            贷款金额：
            {dto.amount && (dto.amount / 100).toFixed(2)}万
          </span>
          <span>
            贷款点数：
            {dto.point && (dto.point / 100).toFixed(2)}%
          </span>
          <span>合同编号：{dto.contractId || '-'}</span>
        </Space>
      );
    },
  },
  {
    title: '照片',
    search: false,
    render: (_, dto) => {
      return (
        <Space direction="vertical">
          {dto.withContractImg && (
            <ImageModal
              oid={dto.oid}
              visitorId={dto.id}
              type="contract"
              text={'合同照片'}
            />
          )}
          {dto.withSignImg && (
            <ImageModal
              oid={dto.oid}
              visitorId={dto.id}
              text={'签约照片'}
              type="sign"
            />
          )}
        </Space>
      );
    },
  },
  {
    width: '20%',
    search: false,
    render: (_, dto) => {
      return (
        <Space direction="vertical">
          <span>资质情况：{dto.qualification || '-'}</span>
          <span>负债情况：{dto.liability || '-'}</span>
          <span>备注：{dto.remark || '-'}</span>
        </Space>
      );
    },
    title: '备注',
  },
  {
    title: '操作人',
    dataIndex: 'userId',
    search: false,
    render: (_, { remark, user }) => {
      if (user) {
        return <UserInfoModal id={user.id} name={user.name} />;
      }

      return <span>{remark}</span>;
    },
  },
  {
    title: '操作',
    search: false,
    render: (_, dto) => {
      const { refresh, canEdit } = useContext(refreshContext);
      return (
        <Space>
          {canEdit ? (
            <>
              <AddUpdateVisitor
                type="update"
                dto={dto}
                refresh={refresh}
                buttonProps={{ disabled: !canEdit }}
              />
              <RemoveButton
                disabled={!canEdit}
                visitorId={dto.id}
                refresh={refresh}
              />
            </>
          ) : (
            '-'
          )}
        </Space>
      );
    },
  },

  {
    title: '区域',
    hideInTable: true,
    order: 999,
    dataIndex: 'departmentId',
    renderFormItem: () => {
      return <DepartmentSelect />;
    },
  },
  {
    title: '是否签约',
    hideInTable: true,
    order: 997,
    search: {
      transform: (sign) => {
        let isSign: boolean | undefined;
        if (sign === '1') isSign = true;
        if (sign === '0') isSign = false;
        return {
          isSign,
        };
      },
    },
    renderFormItem: () => {
      return (
        <Radio.Group
          options={[
            {
              label: '全部',
              value: '',
            },
            {
              label: '已签约',
              value: '1',
            },
            {
              label: '未签约',
              value: '0',
            },
          ]}
        />
      );
    },
  },
  {
    title: '是否拓展',
    hideInTable: true,
    order: 996,
    search: {
      transform: (agent) => {
        let withAgent: boolean | undefined;
        if (agent === '1') withAgent = true;
        if (agent === '0') withAgent = false;
        return {
          withAgent,
        };
      },
    },
    renderFormItem: () => {
      return (
        <Radio.Group
          options={[
            {
              label: '全部',
              value: '',
            },
            {
              label: '是',
              value: '1',
            },
            {
              label: '否',
              value: '0',
            },
          ]}
        />
      );
    },
  },
];

export default () => {
  const { id: companyId, hasPermission, departments } = useCompany();
  const ref = useRef<ActionType>();

  const showMobile = useMemo(() => {
    return hasPermission('page:visitor:showMobile');
  }, [hasPermission]);

  const canEdit = useMemo(() => {
    return hasPermission('page:visitor:edit');
  }, [hasPermission]);

  return (
    <refreshContext.Provider
      value={{
        refresh: () => ref.current?.reloadAndRest?.(),
        showMobile,
        canEdit,
      }}
    >
      <ProTable<
        VisitorListItemDTO,
        ListVisitorParamsDTO & {
          companyId: I.ID;
          departments: BaseDepartmentDTO[];
        }
      >
        className="pro-table"
        toolBarRender={() => {
          return [
            <AddUpdateVisitor
              key={'add-visitor'}
              refresh={() => ref.current?.reloadAndRest?.()}
              type="add"
            />,
          ];
        }}
        rowKey="id"
        actionRef={ref}
        columns={columns}
        params={{
          companyId,
          departments,
        }}
        pagination={{
          pageSizeOptions: [20, 50, 100, 200, 500, 1000],
        }}
        bordered
        size="small"
        cardBordered={{ search: true }}
        dateFormatter="number"
        request={async (
          { current, pageSize, companyId, departments, ...params },
          sort,
          filter,
        ) => {
          const sessionQuery = getVisitorQuerySession();
          const vv = { ...sessionQuery, ...params };
          console.log(vv);
          let departmentIds: I.ID[] = [];
          if (vv.departmentId) {
            // 找到所有的子区域
            const childDepartments = findChildrenDepartment(
              departments,
              vv.departmentId,
            );
            departmentIds = [
              vv.departmentId,
              ...childDepartments.map(({ id }) => id),
            ];
          } else {
            departmentIds = departments.map(({ id }) => id);
          }
          return visitorController
            .list(companyId, current, pageSize, {
              ...vv,
              departmentIds,
            })
            .then((resp) => ({
              data: resp.list,
              success: true,
              total: resp.total,
            }));
        }}
      />
    </refreshContext.Provider>
  );
};
