import { Button, Descriptions, Modal, Popover, Space, Spin } from 'antd';
import { DepartmentDTO, UserDTO, userController } from '../api';
import { useBoolean, useRequest } from 'ahooks';
import { useEffect, useMemo, useState } from 'react';
import { useCompany } from '../hooks/company';
import { flatDepartment } from '../utils';

export default ({ id, name }: { id: I.ID; name: string }) => {
  const { id: companyId, allDepartments: departments } = useCompany();
  const [open, setOpen] = useState<boolean>();

  const {
    data: user,
    loading,
    run: loadUser,
  } = useRequest(
    () => {
      return userController.get(companyId, id).then((data) => {
        return {
          ...data,
          department: flatDepartment(departments, data.departmentId)
            .map(({ name }) => name)
            .reverse()
            .join('/'),
        };
      });
    },
    {
      refreshDeps: [companyId, id],
      manual: true,
    },
  );

  useEffect(() => {
    if (open) {
      loadUser();
    }
  }, [open]);

  return (
    <>
      <Popover
        placement="left"
        content={
          <Spin spinning={loading}>
            <Space direction="vertical">
              <span>工号：{user?.id}</span>
              {/* <span>账号：{user?.mobile}</span> */}
              <span>区域：{user?.department}</span>
              <span>注册时间：{user?.createdAt}</span>
            </Space>
          </Spin>
        }
        trigger="hover"
        title={user?.name}
        open={open}
        onOpenChange={(v) => {
          setOpen(v);
        }}
      >
        <Button type="link">{name}</Button>
      </Popover>
    </>
  );
};
