import { JumboTabs } from 'antd-mobile';
import { CustomerGroupEnum } from '../../api';
import CustomerList from './CustomerList';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

const groups: { name: string; group: CustomerGroupEnum }[] = [
  {
    name: '新客户',
    group: CustomerGroupEnum.NEW_CUSTOMER,
  },
  {
    name: '新分派',
    group: CustomerGroupEnum.NEW_ALLOT,
  },
  {
    name: '拓展',
    group: CustomerGroupEnum.EXPAND,
  },
  {
    name: '抵押',
    group: CustomerGroupEnum.MORTGAGE,
  },
  {
    name: '锁定',
    group: CustomerGroupEnum.LOCKED,
  },
  {
    name: 'A 类',
    group: CustomerGroupEnum.A,
  },
  {
    name: 'B 类',
    group: CustomerGroupEnum.B,
  },
  {
    name: 'C 类',
    group: CustomerGroupEnum.C,
  },
  {
    name: 'D 类',
    group: CustomerGroupEnum.D,
  },
  {
    name: 'E 类',
    group: CustomerGroupEnum.E,
  },
];

const useSearch = () => {
  const { search } = useLocation();
  return new URLSearchParams(search?.substring(1));
};

export default function MobileIndex() {
  const qs = useSearch();

  const defaultActiveKey = useMemo(() => {
    return qs.get('group');
  }, [qs]);

  return (
    <JumboTabs defaultActiveKey={defaultActiveKey}>
      {groups.map((group) => {
        return (
          <JumboTabs.Tab key={group.group} title={group.name} description="">
            <CustomerList group={group.group} />
          </JumboTabs.Tab>
        );
      })}
    </JumboTabs>
  );
}
