import { useRequest } from 'ahooks';
import {
  message,
  Modal,
  Row,
  Col,
  Input,
  Checkbox,
  Rate,
  Divider,
  Radio,
  InputNumber,
  Upload,
  Button,
  Form,
  ButtonProps,
  Space,
  Tooltip,
  Popconfirm,
} from 'antd';
import { useState, useContext, useEffect } from 'react';
import { VisitorListItemDTO, visitorController } from '../api';
import { useCompany } from '../hooks/company';
import { EyeFill } from 'antd-mobile-icons';

type AddUpdateProps = (
  | {
      type: 'add';
      dto?: { visitorName: string; visitorMobile: string; star?: number };
    }
  | { type: 'update'; dto: VisitorListItemDTO }
) & { refresh: () => void; hideMobile?: boolean };

function formatNumber(value?: any) {
  if (!value) {
    return value;
  }

  const v = String(value);

  if (!v.includes('.')) {
    return v + '00';
  }

  const field = v.split('.');
  if (field[1].length === 1) {
    // 说明只有一位小数，补一个 0
    return (v + '0').replace('.', '');
  }

  if (field[1].length === 2) {
    return v.replace('.', '');
  }

  // 大于 2，把后面的小数舍弃掉
  return field[0] + field[1].substring(0, 2);
}

const AddUpdateVisitor = ({
  type,
  dto,
  refresh,
  hideMobile,
  buttonProps,
  buttonText,
}: AddUpdateProps & { buttonProps?: ButtonProps; buttonText?: string }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [withAgent, setWithAgent] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [isSign, setIsSign] = useState<boolean>(false);
  const { id: companyId } = useCompany();

  const [twiceConfirm, setTwiceConfirm] = useState<boolean>(false);

  useEffect(() => {
    const isSign = type === 'update' ? dto.isSign || false : false;
    setIsSign(isSign);
    if (type === 'update') {
      setWithAgent(!!dto.agentMobile);
      form.setFieldsValue({
        ...dto,
        visitorMobile:
          dto?.visitorMobile &&
          dto?.visitorMobile.replace(/^(\d{4})\d{4}(\d+)$/, '$1****$2'),
        withAgent: !!dto.agentMobile,
        amount: dto.amount && dto.amount / 100,
        point: dto.point && dto.point / 100,
      });
    } else {
      form.resetFields();
    }
  }, [dto, type, open, setWithAgent]);

  const { loading: checkMobileLoading, runAsync: checkMobile } = useRequest(
    visitorController.checkMobile,
    {
      manual: true,
      onError: (e) => {
        message.error(`操作失败：${e.message}`);
      },
    },
  );

  const { loading: addLoading, run: addVisitor } = useRequest(
    visitorController.add,
    {
      manual: true,
      onSuccess: () => {
        setTwiceConfirm(false);
        message.success('新增成功');
        refresh();
        setOpen(false);
      },
      onError: (e) => {
        message.error(`操作失败：${e.message}`);
      },
    },
  );

  const { loading: updateLoading, run: updateVisitor } = useRequest(
    visitorController.update,
    {
      manual: true,
      onSuccess: () => {
        message.success('修改成功');
        refresh();
        setOpen(false);
      },
      onError: (e) => {
        message.error(`操作失败：${e.message}`);
      },
    },
  );

  const loading = updateLoading || addLoading || checkMobileLoading;

  const submit = () => {
    form
      .validateFields()
      .then((values) => {
        if (type === 'add') {
          const mobile = dto?.visitorMobile || values.visitorMobile;
          return addVisitor(
            companyId,
            values.visitorName,
            mobile,
            values.agentName,
            values.agentMobile,
            values.star,
            values.isSign,
            values.point && Number(formatNumber(values.point)),
            values.amount && Number(formatNumber(values.amount)),
            values.contractId,
            values.qualification,
            values.liability,
            values.remark,
            values.contractImg?.file,
            values.signImg?.file,
          );
        } else {
          return updateVisitor(companyId, dto.id, {
            ...values,
            visitorMobile: dto.visitorMobile,
            point: values.point && Number(formatNumber(values.point)),
            amount: values.amount && Number(formatNumber(values.amount)),
          });
        }
      })
      .catch((e) => {});
  };
  return (
    <>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title={type === 'add' ? '新增上门记录' : '修改上门记录'}
        width="800px"
        maskClosable={false}
        footer={[
          <Button key="cancel" onClick={() => setOpen(false)} loading={loading}>
            取消
          </Button>,
          <Popconfirm
            title={'手机号不存在，请确认是否添加'}
            open={twiceConfirm}
            onCancel={() => setTwiceConfirm(false)}
            onOpenChange={(open) => {
              if (!open) {
                setTwiceConfirm(open);
              }
              if (type === 'update' || dto?.visitorMobile) {
                return submit();
              }
              if (!form.getFieldValue('visitorMobile')) {
                return submit();
              }
              return checkMobile(
                companyId,
                form.getFieldValue('visitorMobile'),
              ).then((exist) => {
                if (!exist) {
                  setTwiceConfirm(open);
                } else {
                  return submit();
                }
              });
            }}
            onConfirm={() => submit()}
          >
            <Button key="submit" type="primary" loading={loading}>
              {buttonText || '确定'}
            </Button>
          </Popconfirm>,
        ]}
        confirmLoading={loading}
      >
        <Form
          disabled={loading}
          form={form}
          autoComplete="off"
          onValuesChange={(changedValues) => {
            if ('isSign' in changedValues) {
              setIsSign(changedValues.isSign);
            }
          }}
          initialValues={
            type === 'add'
              ? {
                  isSign: false,
                  star: 0,
                  ...dto,
                  visitorMobile: hideMobile
                    ? dto?.visitorMobile.replace(
                        /^(\d{4})\d{4}(\d+)$/,
                        '$1****$2',
                      )
                    : dto?.visitorMobile,
                }
              : {
                  ...dto,
                  visitorMobile:
                    dto?.visitorMobile &&
                    dto?.visitorMobile.replace(
                      /^(\d{4})\d{4}(\d+)$/,
                      '$1****$2',
                    ),
                  withAgent: !!dto.agentMobile,
                  amount: dto.amount && dto.amount / 100,
                  point: dto.point && dto.point / 100,
                }
          }
        >
          <Row>
            <Col span={12} style={{ padding: '10px' }}>
              <Form.Item
                label="贷款人姓名"
                name="visitorName"
                rules={[{ required: true }]}
              >
                <Input
                  disabled={!!dto?.visitorName}
                  addonAfter={
                    <Checkbox
                      onChange={(v) => setWithAgent(v.target.checked)}
                      checked={withAgent}
                    >
                      拓展
                    </Checkbox>
                  }
                />
              </Form.Item>
              <Form.Item
                label={
                  <Space>
                    <span>贷款人电话</span>
                    <Tooltip title={dto?.visitorMobile}>
                      <EyeFill />
                    </Tooltip>
                  </Space>
                }
                name="visitorMobile"
                rules={[
                  {
                    required: true,
                    max: 11,
                    min: 11,
                    message: '请输入11位手机号',
                  },
                ]}
              >
                <Input
                  maxLength={11}
                  minLength={11}
                  disabled={type === 'update' || !!dto?.visitorMobile}
                />
              </Form.Item>
              {type === 'add' && (
                <Form.Item
                  name="star"
                  label="贷款人星级"
                  rules={[{ required: true }]}
                >
                  <Rate />
                </Form.Item>
              )}
              {withAgent && (
                <>
                  <Divider />
                  <Form.Item
                    name="agentName"
                    label="拓展人姓名"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="agentMobile"
                    label="拓展人电话"
                    rules={[{ required: true, max: 11, min: 11 }]}
                  >
                    <Input maxLength={11} minLength={11} />
                  </Form.Item>
                </>
              )}
              <Form.Item
                name="isSign"
                label={withAgent ? '成功' : '签约'}
                rules={[{ required: true }]}
              >
                <Radio.Group>
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </Radio.Group>
              </Form.Item>
              {isSign && (
                <>
                  <Divider />
                  <Form.Item
                    name="amount"
                    label="贷款金额"
                    rules={[{ required: true }]}
                  >
                    <InputNumber stringMode addonAfter="万" precision={2} />
                  </Form.Item>
                  <Form.Item
                    name="point"
                    label="签约点数"
                    rules={[{ required: true }]}
                  >
                    <InputNumber
                      stringMode
                      addonAfter="%"
                      precision={2}
                      max={100}
                    />
                  </Form.Item>
                  <Form.Item name="contractId" label="合同编号">
                    <Input />
                  </Form.Item>
                  {type === 'add' && !withAgent && (
                    <>
                      <Form.Item
                        name="contractImg"
                        label="合同照片"
                        // valuePropName="fileList"
                      >
                        <Upload
                          beforeUpload={(file) => false}
                          accept="image/*"
                          multiple={false}
                          maxCount={1}
                        >
                          <Button>选择图片</Button>
                        </Upload>
                      </Form.Item>
                      <Form.Item
                        name="signImg"
                        label="签约照片"
                        // valuePropName="fileList"
                      >
                        <Upload
                          beforeUpload={(file) => false}
                          accept="image/*"
                          multiple={false}
                          maxCount={1}
                        >
                          <Button>选择图片</Button>
                        </Upload>
                      </Form.Item>
                    </>
                  )}
                </>
              )}
            </Col>

            <Col span={12} style={{ padding: '10px' }}>
              <Form.Item
                label="资质情况"
                name="qualification"
                labelCol={{ span: 24 }}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
              <Form.Item
                name="liability"
                label="负债情况"
                labelCol={{ span: 24 }}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
              <Form.Item name="remark" label="备注" labelCol={{ span: 24 }}>
                <Input.TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Button type="primary" onClick={() => setOpen(true)} {...buttonProps}>
        {type === 'add' ? '新增上门记录' : '修改'}
      </Button>
    </>
  );
};

export default AddUpdateVisitor;
