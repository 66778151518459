import {
  Badge,
  Button,
  Col,
  List,
  Modal,
  Pagination,
  Popover,
  Row,
  Skeleton,
  Space,
  notification,
} from 'antd';
import { useCompany } from '../../hooks/company';
import { useRequest, useWebSocket } from 'ahooks';
import { UserMessageListItemDTO, userController } from '../../api';
import {
  BellOutlined,
  MailOutlined,
  CheckOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { pushHost } from '../../apis/request';
import CustomerDetail from '../CustomerDetail';

export const HistoryMessage = () => {
  const [open, setOpen] = useState<boolean>();
  const { id: companyId } = useCompany();
  const [current, setCurrent] = useState<number>(1);

  const { data, loading, run } = useRequest(
    () => {
      return userController.listMessage(companyId, current, 5);
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (open) {
      setCurrent(1);
      run();
    }
  }, [open, run, setCurrent]);

  return (
    <>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title="历史消息"
        footer={false}
      >
        <Row>
          <Col span={24}>
            {loading ? (
              <Skeleton />
            ) : (
              <List>
                {data?.list?.map((item) => (
                  <List.Item key={item.id}>
                    <List.Item.Meta
                      title={item.title}
                      description={item.body}
                    />
                  </List.Item>
                ))}
              </List>
            )}
          </Col>
          <Col span={24}>
            {data?.total && (
              <Pagination
                current={current}
                size="small"
                simple
                total={data?.total}
                onChange={(page) => {
                  setCurrent(page);
                  run();
                }}
              />
            )}
          </Col>
        </Row>
      </Modal>
      <MailOutlined
        style={{
          fontSize: '18px',
          color: 'rgb(152, 155, 159)',
          cursor: 'pointer',
        }}
        onClick={() => {
          setOpen(true);
        }}
      />
    </>
  );
};

const CustomerLink = ({
  customerId,
  customerName,
}: {
  customerId: I.ID;
  customerName: string;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <Button
        type="link"
        onClick={() => {
          setOpen(true);
        }}
      >
        {customerName}
      </Button>
      <CustomerDetail
        id={customerId}
        open={open}
        onCancel={() => setOpen(false)}
        withComment={true}
      />
    </>
  );
};

export default () => {
  const { id: companyId } = useCompany();
  const [message, setMessage] = useState<UserMessageListItemDTO>();

  const [prevMessageIdList, setPreMessageIdList] = useState<I.ID[]>([]);

  const firstOpen = useRef<boolean>(true);

  const {
    data,
    loading: getLoading,
    refresh,
  } = useRequest(
    () => {
      return userController.listLatest(companyId);
    },
    {
      refreshDeps: [companyId],
    },
  );

  const { run: readMessage, loading: readLoading } = useRequest(
    (id: I.ID) => {
      return userController.readMessage(companyId, id);
    },
    {
      manual: true,
      onSuccess: () => {
        refresh();
      },
    },
  );

  useWebSocket(`${pushHost}/message?token=${sessionStorage.getItem('token')}`, {
    onMessage(e) {
      const msg = JSON.parse(e.data) as I.PushMessage<{
        id: I.ID;
        body?: string;
        title: string;
        extra?: string;
      }>;
      const extra = JSON.parse(msg?.data?.extra || '{}') as {
        type?: 'new_customer';
        customerId?: I.ID;
        customerName?: string;
      };
      if (msg?.type === 'MESSAGE') {
        refresh();
        api.info({
          message: '您有新的消息',
          description: (
            <Space direction="vertical">
              <span>{msg.data.title}</span>
              {extra.customerId && extra.customerName && (
                <CustomerLink
                  customerId={extra.customerId}
                  customerName={extra.customerName}
                />
              )}
            </Space>
          ),
          onClick: () => {
            readMessage(msg.data.id);
          },
          icon: (
            <MailOutlined
              style={{
                color: extra.type === 'new_customer' ? 'red' : '#108ee9',
              }}
            />
          ),
          placement: 'topRight',
          duration: 0,
        });
      }
    },
  });

  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (data && firstOpen.current) {
      setPreMessageIdList((v) => {
        // 找出新增的消息
        const newMessage = data.filter((item) => !v.includes(item.id));
        if (newMessage.length > 0) {
          newMessage.forEach((item) => {
            const extra = JSON.parse(item.extra || '{}') as {
              type: 'new_customer';
              customerId?: I.ID;
              customerName?: string;
            };
            api.info({
              message: '您有新的消息',
              description: (
                <Space direction="vertical">
                  <span>{item.title}</span>
                  {extra.customerId && extra.customerName && (
                    <CustomerLink
                      customerId={extra.customerId}
                      customerName={extra.customerName}
                    />
                  )}
                </Space>
              ),
              placement: 'topRight',
              onClose: () => {
                readMessage(item.id);
              },
              icon: (
                <MailOutlined
                  style={{
                    color: extra.type === 'new_customer' ? 'red' : '#108ee9',
                  }}
                />
              ),
              duration: 0,
            });
          });
        }
        firstOpen.current = false;
        return [...v, ...newMessage.map((item) => item.id)];
      });
    }
  }, [data]);

  return (
    <>
      {contextHolder}
      <Modal
        title="消息详情"
        open={!!message?.id}
        onCancel={() => setMessage(undefined)}
        onOk={() => {
          if (message?.id) {
            readMessage(message?.id);
          }
          setMessage(undefined);
        }}
      >
        <Space direction="vertical">
          <span>{message?.title}</span>
          <span>{message?.body}</span>
          <span>{dayjs(message?.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>
        </Space>
      </Modal>
      <Popover
        placement="bottomRight"
        trigger="hover"
        style={{ width: '400px' }}
        overlayStyle={{ width: '400px' }}
        onOpenChange={(open) => {
          if (open) refresh();
        }}
        content={
          <List style={{ width: '100%' }} loading={getLoading}>
            {!data?.length && <List.Item>暂无新消息</List.Item>}
            {data?.slice(0, 5)?.map((item) => (
              <List.Item
                key={item.id}
                actions={[
                  <Button
                    key="view"
                    icon={<EyeOutlined />}
                    onClick={() => setMessage(item)}
                    size="small"
                    shape="circle"
                  />,
                  <Button
                    shape="circle"
                    key={'read'}
                    size="small"
                    onClick={() => {
                      readMessage(item.id);
                    }}
                    loading={readLoading}
                    icon={<CheckOutlined />}
                  />,
                ]}
              >
                <List.Item.Meta
                  style={{ overflowX: 'auto' }}
                  avatar={
                    <MailOutlined
                      style={{ fontSize: '18px', color: 'rgb(152, 155, 159)' }}
                    />
                  }
                  title={item.title}
                  description={dayjs(item.createdAt).fromNow()}
                />
              </List.Item>
            ))}
          </List>
        }
      >
        <Badge count={data?.length} size="small">
          <BellOutlined
            style={{
              fontSize: '18px',
              color: 'rgb(152, 155, 159)',
              cursor: 'pointer',
            }}
          />
        </Badge>
      </Popover>
    </>
  );
};
