import {
  Checkbox,
  Col,
  Input,
  Row,
  Space,
  Spin,
  Tag,
  Tree,
  TreeDataNode,
} from 'antd';
import { useCallback, useEffect, useId, useMemo, useState } from 'react';
import {
  BaseDepartmentDTO,
  BaseUserDTO,
  CustomerGroupEnum,
  CustomerStateEnum,
  UserStateEnum,
  userController,
} from '../api';
import { useCompany } from '../hooks/company';
import { flatDepartment, sortName } from '../utils';
import { useRequest } from 'ahooks';
import { DataNode } from 'antd/es/tree';

interface Target {
  name: string;
  id: I.ID;
}

interface AllocateItem {
  userId: I.ID;
  targets: I.ID[];
}

interface Props {
  targets: Target[];
  onChange: (v: AllocateItem[]) => void;
  remark?: string;
  onRemarkChange: (v: string) => void;
}

const allocCustomer = (
  userIds: I.ID[],
  customer: Target[],
): Record<I.ID, I.ID[]> => {
  if (customer.length === 0 || userIds.length === 0) {
    return {};
  }
  const length = customer.length;
  // 平均一下
  const avg = Math.floor(length / userIds.length);
  const result: Record<I.ID, I.ID[]> = {};
  for (let i = 0; i < userIds.length; i++) {
    const id = userIds[i];
    const start = i * avg;
    const end = (i + 1) * avg;
    result[id] = customer.slice(start, end).map((item) => item.id);
  }
  // 再把剩下的平均分给 last.length 个人
  const last = customer.slice(userIds.length * avg);
  const lastResult = allocCustomer(userIds.slice(0, last.length), last);
  // 合并
  if (Object.keys(lastResult).length > 0) {
    Object.keys(lastResult).forEach((key) => {
      result[key] = [...(result[key] || []), ...lastResult[key]];
    });
  }
  return result;
};

const buildTree = (
  users: BaseUserDTO[],
  departments: BaseDepartmentDTO[],
  rootId?: I.ID,
  allAllocated?: boolean,
  result?: Record<I.ID, I.ID[]>,
): DataNode[] => {
  // 首先找到子区域
  const childrenDep = departments
    .filter((item) => {
      if (!rootId) {
        return !item.parentId;
      }
      return item.parentId === rootId;
    })
    .sort((a, b) => sortName(a.name, b.name));
  // 找到当前区域的用户
  const childrenUser = users.filter((item) => {
    if (!rootId) {
      // 找到没有区域的用户
      return !item.departmentId;
    }
    return item.departmentId === rootId;
  });

  const children: DataNode[] = [];

  childrenUser.forEach((user) => {
    const value = result?.[user.id];
    children.push({
      title: (
        <Space>
          <span style={{ fontSize: '15px' }}>{user.name}</span>
          <span>{!!value?.length && `x${value.length}`}</span>
        </Space>
      ),
      disableCheckbox: allAllocated && !value?.length,
      key: `user-${user.id}`,
    });
  });

  // 将子区域和子用户合并到 children
  childrenDep.forEach((dep) => {
    children.push({
      title: <span style={{ fontSize: '15px' }}>{dep.name}</span>,
      key: `dep-${dep.id}`,
      checkable: false,
      children: buildTree(users, departments, dep.id, allAllocated, result),
    });
  });

  return children;
};

const UserTree = ({
  selectedUser,
  onSelected,
  result,
  allAllocated,
}: {
  selectedUser?: I.ID[];
  onSelected: (v: I.ID[]) => void;
  result?: Record<I.ID, I.ID[]>;
  allAllocated?: boolean;
}) => {
  const {
    departments,
    id: companyId,
    currentUser,
    name: companyName,
  } = useCompany();

  const { data: allUsers, loading } = useRequest(
    () => {
      return userController
        .listAllBaseUser(companyId)
        .then((data) =>
          data.filter(
            (user) => user.departmentId && user.state === UserStateEnum.ACTIVE,
          ),
        );
    },
    {
      refreshDeps: [companyId],
    },
  );

  const users = useMemo(() => {
    const depIds = departments.map((item) => item.id);
    return allUsers
      ?.filter((user) => depIds.includes(user.departmentId!))
      .sort((a, b) => {
        const aDepId = a.departmentId!;
        const bDepId = b.departmentId!;
        if (aDepId > bDepId) {
          return 1;
        }
        if (aDepId < bDepId) {
          return -1;
        }
        return 0;
      });
  }, [allUsers, departments]);

  const treeData: DataNode[] = useMemo(() => {
    const tree = buildTree(
      users || [],
      departments,
      currentUser.departmentId,
      allAllocated,
      result,
    );

    return [
      {
        title:
          departments.find((item) => item.id === currentUser.departmentId)
            ?.name || companyName,
        key: 'all',
        checkable: false,
        children: tree,
      },
    ];
  }, [
    users,
    departments,
    allAllocated,
    result,
    currentUser.departmentId,
    companyName,
  ]);

  return (
    <Tree
      treeData={treeData}
      showLine
      showIcon
      checkable
      onCheck={(checked) => {
        if (Array.isArray(checked)) {
          onSelected(
            checked
              .filter((item) => String(item).startsWith('user-'))
              .map((item) => String(item).replace('user-', '')),
          );
        }
      }}
    />
  );
};

const UserList = ({
  targets,
  onChange,
}: {
  targets: Target[];
  onChange: (v: AllocateItem[]) => void;
}) => {
  const { departments, id: companyId } = useCompany();

  const { data: allUsers, loading } = useRequest(
    () => {
      return userController
        .listAllBaseUser(companyId)
        .then((data) =>
          data.filter(
            (user) => user.departmentId && user.state === UserStateEnum.ACTIVE,
          ),
        );
    },
    {
      refreshDeps: [companyId],
    },
  );

  const users = useMemo(() => {
    const depIds = departments.map((item) => item.id);
    return allUsers
      ?.filter((user) => depIds.includes(user.departmentId!))
      .sort((a, b) => {
        const aDepId = a.departmentId!;
        const bDepId = b.departmentId!;
        if (aDepId > bDepId) {
          return 1;
        }
        if (aDepId < bDepId) {
          return -1;
        }
        return 0;
      });
  }, [allUsers, departments]);

  const [selectedUser, setSelectedUser] = useState<Record<I.ID, I.ID[]>>({});

  const formatDepartment = useCallback(
    (depId?: I.ID) => {
      return flatDepartment(departments, depId)
        .map((item) => item.name)
        .reverse();
    },
    [departments],
  );

  useEffect(() => {
    setSelectedUser({});
  }, [targets]);

  useEffect(() => {
    const result: AllocateItem[] = [];
    Object.keys(selectedUser).forEach((key) => {
      const userId = Number(key);
      const targets = selectedUser[userId];
      if (targets.length > 0) {
        result.push({
          userId,
          targets,
        });
      }
    });
    onChange(result);
  }, [selectedUser]);

  const [selectedUserId, setSelectedUserId] = useState<I.ID[]>();

  const [alloctedResult, setAllocatedResult] = useState<Record<I.ID, I.ID[]>>();

  const getUserName = useCallback(
    (userId: I.ID) => {
      const user = users?.find((item) => String(item.id) === String(userId));
      if (user) {
        return `${formatDepartment(user.departmentId).join('-')}-${user.name}`;
      }
      return '';
    },
    [allUsers, formatDepartment],
  );

  useEffect(() => {
    onChange(
      Object.entries(alloctedResult || {})
        .filter(([key, value]) => !!value.length)
        .map(([key, value]) => {
          return {
            userId: Number(key),
            targets: value,
          };
        }),
    );
  }, [alloctedResult]);

  // 当每一个用户都有一个客户的时候说明已经没法再分配了
  const allAllocated = useMemo(() => {
    if (!Object.keys(alloctedResult || {}).length) {
      return false;
    }
    const v = Object.entries(alloctedResult || {}).every(
      ([_, value]) => value.length === 1,
    );
    return v;
  }, [alloctedResult]);

  return (
    <Spin spinning={loading}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Space wrap>
          {Object.entries(alloctedResult || {}).map(([key, value]) => (
            <Tag key={key}>
              {getUserName(key)}@{value.length}
            </Tag>
          ))}
        </Space>
        <div style={{ width: '100%' }}>
          <UserTree
            selectedUser={selectedUserId}
            allAllocated={allAllocated}
            result={alloctedResult}
            onSelected={(v) => {
              // setSelectedUserId(v);
              const result = allocCustomer([...new Set(v)], targets);
              setAllocatedResult(result);
            }}
          />
        </div>
      </Space>
    </Spin>
  );
};
export default ({ targets, onChange, remark, onRemarkChange }: Props) => {
  return (
    <Row>
      <Col span={24}>
        <Space direction="vertical">
          <h4>已选中 {targets.length} 个客户</h4>
          {/* <Space wrap>
            {targets.map((item) => (
              <Tag key={item.id}>{item.name}</Tag>
            ))}
          </Space> */}
        </Space>
      </Col>
      <Col span={24} style={{ width: '100%' }}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <h4>业务员</h4>
          <UserList targets={targets} onChange={onChange} />
        </Space>
      </Col>
      <Col span={24} style={{ width: '100%' }}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <h4>备注</h4>
          <Input.TextArea
            rows={4}
            value={remark}
            onChange={(v) => onRemarkChange(v.target.value)}
          />
        </Space>
      </Col>
    </Row>
  );
};
