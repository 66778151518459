import { Table } from 'antd';
import type { TableProps } from 'antd/lib/table';
import type { TableColumnType } from 'antd';
import React, { useEffect } from 'react';
import { Resizable } from 'react-resizable';
import 'react-resizable/css/styles.css';
import styled from 'styled-components';
import './ResizeTable.css';

const ResizeCellStyle = styled(Resizable)`
  &::before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 1.6em;
    background-color: rgba(0, 0, 0, 0.06);
    transform: translateY(-50%);
    transition: background-color 0.3s;
    content: '';
  }

  &:last-child::before {
    display: none;
  }

  .react-resizable {
    position: relative;
    background-clip: padding-box;
  }

  .react-resizable-handle {
    position: absolute;
    width: 10px;
    height: 100%;
    bottom: 0;
    right: -5px;
    cursor: col-resize;
    background-image: none;
    z-index: 1;
  }
`;

const TableHeaderCell: React.FC<Record<string, any>> = (props) => {
  const { onResize, width, ...restProps } = props;
  if (!width) return <th {...restProps} />;

  return (
    <ResizeCellStyle
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </ResizeCellStyle>
  );
};

const ResizeTable: React.FC<TableProps<any>> = (props) => {
  const { columns = [], ...rest } = props;
  // @ts-ignore
  const [tableColumns, setTableColumns] = React.useState<TableColumnType<any>>(
    columns as any,
  );

  useEffect(() => {
    setTableColumns(columns as any);
  }, [columns]);

  const handleResize =
    (index: number) =>
    (e: any, { size }: any) => {
      // @ts-ignore
      const nextColumns = [...tableColumns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      // @ts-ignore
      setTableColumns(nextColumns);
    };

  return (
    <Table
      {...rest}
      components={{
        header: {
          cell: TableHeaderCell,
        },
      }}
      scroll={{
        x: 'max-content',
      }}
      // @ts-ignore
      columns={tableColumns.map((col: any, index: any) => {
        return {
          ...col,
          onHeaderCell: (column: any) => ({
            ...column,
            width: column.width,
            onResize: handleResize(index),
          }),
        };
      })}
    />
  );
};

export default ResizeTable;
