import { useRequest } from 'ahooks';
import { Button, Col, Input, Modal, Row, Space, message } from 'antd';
import { useState } from 'react';
import { blackListController } from '../api';
import { useCompany } from '../hooks/company';

export default function ({
  ids,
  refresh,
}: {
  ids: I.ID[];
  refresh: () => void;
}) {
  const [open, setOpen] = useState<boolean>();
  const { id: companyId } = useCompany();
  const [remark, setRemark] = useState<string>();

  const { loading, run } = useRequest(
    () => {
      return blackListController.addBatch(companyId, {
        customerIdList: ids,
        remark,
      });
    },
    {
      manual: true,
      onSuccess: () => {
        setOpen(false);
        message.success('加入黑名单成功');
        refresh();
      },
      onError: (e) => {
        message.error(e.message || '加入黑名单失败');
      },
    },
  );

  return (
    <>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title="加入黑名单"
        confirmLoading={loading}
        onOk={() => {
          run();
        }}
      >
        <Row>
          <Col span={24}>
            <span>选中了 {ids.length} 个客户</span>
          </Col>
          <Col span={24} style={{ marginTop: '10px' }}>
            <Input.TextArea
              value={remark}
              onChange={(e) => setRemark(e.target.value)}
            />
          </Col>
        </Row>
      </Modal>
      <Button
        type="primary"
        disabled={!ids.length}
        onClick={() => {
          setOpen(true);
        }}
      >
        加入黑名单
      </Button>
    </>
  );
}
