import { useRequest } from 'ahooks';
import {
  CustomerDTO,
  CustomerGroupEnum,
  CustomerListItemDTO,
  CustomerListItemV2DTO,
  CustomerPoolEnum,
  CustomerStateEnum,
  ListCustomerType,
  TraceDTO,
  customerController,
  userController,
} from '../../api';
import { useCompany } from '../../hooks/company';
import {
  Button,
  ButtonProps,
  Checkbox,
  Descriptions,
  Input,
  Modal,
  Popconfirm,
  Rate,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  message,
} from 'antd';
import { customerGroupMapping, customerStateMapping } from '../../constants';
import CustomerDetail, {
  RefreshProvider,
  userRefreshContext,
} from '../../components/CustomerDetail';
import LastEvent, { LastEventDrawer } from '../../components/LastEvent';
import CustomerChannelSelect from '../../components/CustomerChannelSelect';
import CustomerStateSelect from '../../components/CustomerStateSelect';
import UserInfoModal from '../../components/UserInfoModal';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import BizCustomerOperator from '../../components/BizCustomerOperator';
import CustomerMobile from '../../components/CustomerMobile';
import { ActionType, ProColumns, ProTable } from '@ant-design/pro-components';
import dayjs from 'dayjs';
import AddUpdateVisitor from '../../components/AddUpdateVisitor';
import PublicPoolGetButton from '../../components/PublicPoolGetButton';
import PoolReAllocate from '../../components/PoolReAllocate';
import DropToPublicPoolBatch from '../../components/DropToPublicPoolBatch';
import AddCustomer from '../../components/AddCustomer';
import { createFromIconfontCN, UserOutlined } from '@ant-design/icons';
import { flatDepartment, formatEndTime, formatStartTime } from '../../utils';
import UserSelect from '../../components/UserSelect';
import '../../styles/common.scss';
import BatchDropToBlackList from '../../components/BatchDropToBlackList';
import DateRange from '../../components/DateRange';
import { AvatarImg } from '../../components/OSSImage';
import DepartmentSelect from '../../components/DepartmentSelect';

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_4093617_k95i7isd6z.js',
});

const listContext = createContext<{
  setUser?: (u: { id: I.ID; name: string }) => void;
  setCustomerEvent?: (c: { customerId: I.ID; customerName: string }) => void;
}>({});

export const CustomerTraceTimeUnit = ({
  time,
  name,
}: {
  name: string;
  time?: string;
}) => {
  return (
    <Space>
      <div style={{ position: 'relative', left: '-8px' }}>
        <span>【{name}】</span>
        <span>{time ? dayjs(time).format('YYYY-MM-DD HH:mm:ss') : '-'}</span>
      </div>
    </Space>
  );
};

const UserModal = ({
  id,
  name,
  open,
  onCancel,
}: {
  id: I.ID;
  name: string;
  open?: boolean;
  onCancel: () => void;
}) => {
  const { id: companyId, allDepartments: departments } = useCompany();

  const {
    data: user,
    loading,
    run: loadUser,
  } = useRequest(
    () => {
      return userController.get(companyId, id).then((data) => {
        return {
          ...data,
          department: flatDepartment(departments, data.departmentId)
            .map(({ name }) => name)
            .reverse()
            .join('/'),
        };
      });
    },
    {
      refreshDeps: [companyId, id],
      manual: true,
    },
  );

  useEffect(() => {
    if (open) {
      loadUser();
    }
  }, [open]);

  return (
    <Modal
      title={name}
      open={open}
      onCancel={onCancel}
      width={300}
      footer={false}
    >
      <Spin spinning={loading}>
        <Descriptions column={1}>
          <Descriptions.Item children={<AvatarImg userId={id} size={100} />} />
          <Descriptions.Item label="工号" children={id} />
          <Descriptions.Item label="姓名" children={user?.name} />
          <Descriptions.Item label="区域" children={user?.department} />
          <Descriptions.Item
            label="加入时间"
            children={dayjs(user?.createdAt).format('YYYY-MM-DD')}
          />
        </Descriptions>
      </Spin>
    </Modal>
  );
};

const DropToPublicPoolButton = ({ id }: { id: I.ID }) => {
  const { id: companyId } = useCompany();

  const { refresh } = userRefreshContext();

  const { loading, runAsync } = useRequest(
    () => {
      return customerController.dropToPublic(companyId, id);
    },
    {
      manual: true,
      refreshDeps: [id, companyId],
      onSuccess: (e) => {
        message.success('抛入成功');
        refresh?.();
      },
      onError: (e) => message.error(e.message),
    },
  );
  return (
    <Popconfirm title="确认抛入" onConfirm={runAsync}>
      <Button type="primary" size="small" loading={loading}>
        抛入公共池
      </Button>
    </Popconfirm>
  );
};

const UpdateScore = ({ id, score }: { id: I.ID; score?: number }) => {
  const { id: companyId } = useCompany();

  const { refresh } = userRefreshContext();

  const { run: updateScore } = useRequest(
    (score: number) => {
      return customerController.updateScore(companyId, id, score);
    },
    {
      manual: true,
      onError: (e) => message.error(`操作失败：${e.message}`),
      refreshDeps: [id, companyId],
      onSuccess: () => {
        message.success('操作成功');
        refresh?.();
      },
    },
  );
  return (
    <Button
      type="primary"
      size="small"
      danger={!!(score && score >= 90)}
      onClick={() => updateScore((score || 0) >= 90 ? 0 : 90)}
    >
      {(score || 0) >= 90 ? '取消优质' : '设为优质'}
    </Button>
  );
};

const MyCustomerOperator = ({
  id,
  name,
  mobile,
  star,
  canUpdateScore,
  score,
}: {
  id: I.ID;
  name: string;
  mobile?: string;
  star?: number;
  score?: number;
  canUpdateScore?: boolean;
}) => {
  const { refresh } = userRefreshContext();
  return (
    <Space direction="vertical">
      <DropToPublicPoolButton id={id} />
      {mobile && (
        <AddUpdateVisitor
          buttonProps={{ size: 'small' }}
          type="add"
          dto={{ visitorName: name, visitorMobile: mobile, star: star }}
          hideMobile={true}
          refresh={() => refresh?.()}
        />
      )}
      {canUpdateScore && <UpdateScore id={id} score={score} />}
    </Space>
  );
};

const CustomerManangeDetail = ({
  id,
  name,
  count,
  disabled,
  onClick,
  aliasName,
  score,
}: {
  id: I.ID;
  name: string;
  count?: number;
  aliasName?: string;
  score?: number;
} & Pick<ButtonProps, 'onClick' | 'disabled'>) => {
  return (
    <Space>
      {disabled ? (
        <span>{name}</span>
      ) : (
        <Button onClick={onClick} type="link" style={{ padding: 0 }}>
          {name}
        </Button>
      )}
      {aliasName && <span>（{aliasName}）</span>}
      {!!count && count > 1 && <Tag>{count}申</Tag>}
      {(score || 0) >= 90 && (
        <Tag
          color="#f50"
          style={{ padding: '0 4px', border: 'none', fontSize: '10px' }}
        >
          优
        </Tag>
      )}
    </Space>
  );
};

const hideInSearch = true;
const hideInForm = true;
const hideInTable = true;

export const CustomerSmummary = ({
  customer,
  onSelect,
  listType,
  showMobie,
}: {
  customer: CustomerListItemDTO;
  onSelect: (id: I.ID) => void;
  listType: ListCustomerType;
  showMobie?: boolean;
}) => {
  return (
    <Space direction="vertical" size={0}>
      <CustomerManangeDetail
        disabled={listType === 'POOL'}
        onClick={() => onSelect(customer.id)}
        count={customer.count}
        id={customer?.id!}
        name={customer?.name!}
        score={customer?.score}
        aliasName={customer.aliasName}
      />
      <CustomerMobile mobile={customer.mobile} withTips={showMobie} />
      <Rate
        className="list-rate-box"
        style={{
          height: '20px',
          width: '132px',
          fontSize: '16px',
          color: 'orange',
        }}
        value={customer.star}
        disabled
      />
    </Space>
  );
};

interface EnhanceUserResult {
  userIdList?: I.ID;
  excludeUserIdList?: boolean;
}

const EnhanceUserList = ({
  value,
  onChange,
}: {
  value?: EnhanceUserResult;
  onChange?: (v: EnhanceUserResult) => void;
}) => {
  return (
    <Space className="enhance-user-list">
      <UserSelect
        mode="multiple"
        disableAll
        allowClear
        value={value?.userIdList}
        onChange={(v) =>
          onChange?.({
            ...value,
            userIdList: v,
          })
        }
      />
      <Checkbox
        checked={value?.excludeUserIdList}
        onChange={(v) => {
          onChange?.({
            ...value,
            excludeUserIdList: v.target.checked,
          });
        }}
      >
        反选
      </Checkbox>
    </Space>
  );
};

export const DropToPublicPoolTime = ({
  trace,
  state,
}: {
  trace?: TraceDTO;
  state: CustomerStateEnum;
}) => {
  const { config: companyConfig } = useCompany();
  const day = useMemo(() => {
    if (!companyConfig?.enableJoinPublicPool) {
      return undefined;
    }
    const waitDay =
      companyConfig?.joinPublicPoolConfig?.[state]?.day ||
      companyConfig.defaultJoinPublicPoolConfig;
    if (!waitDay) {
      return undefined;
    }

    const isHandled = !!trace?.handledAt;

    const time = isHandled ? trace?.eventAt : trace?.assignedAt;

    if (!time) {
      return undefined;
    }
    // 每晚 24 点抛
    const dropTime = dayjs().endOf('day'); // 这就是抛入时间

    const deadline = dayjs(time).add(waitDay, 'day'); // 将要抛入的时间

    const duration = deadline.diff(dropTime, 'day');
    return duration;
  }, [trace, state, companyConfig]);

  const color = useMemo(() => {
    if (day === undefined) {
      return undefined;
    }
    if (day < 2) {
      return 'red';
    }
    return undefined;
  }, [day]);
  return (
    <Space style={{ color }}>
      <span>还有</span>
      <span>{day === undefined ? '-' : day}</span>
      <span>天入公共池</span>
    </Space>
  );
};

export default function CustomerList({
  group,
  pool,
  listType,
}: {
  group?: CustomerGroupEnum;
  pool?: CustomerPoolEnum;
  listType: ListCustomerType;
}) {
  const { id: companyId, hasPermission } = useCompany();

  const permissions = useMemo(() => {
    const result = {
      showMobile: false,
      showChannel: false,
      canEditCustomerName: false,
      canDropToBlackList: false,
      canUpdateCustomer: false,
      canGetCustomerBySelf: false, // 是否能够领取客户
      canUpdateScore: false, // 是否能够修改客户评分
      addLoan: false, // 添加放款
      reAllocate: false, // 重新分配
      drop2publicPoolBatch: false, // 批量抛入公共池
    };
    result.drop2publicPoolBatch = hasPermission(
      'page:customer-list:biz-pool:drop2public-pool',
    );
    result.canEditCustomerName = hasPermission(
      'page:my-customer:editCustomerName',
    );

    result.canUpdateScore = hasPermission(
      `page:customer-list:biz-pool:update-score`,
    );
    switch (listType) {
      case 'MY': {
        result.showMobile = hasPermission(
          'page:my-customer:showCustomerMobile',
        );
        result.showChannel = hasPermission(`page:my-customer:showChannel`);
        break;
      }
      case 'POOL': {
        let poolType = '';
        if (pool === undefined) {
          poolType = 'total';
        } else if (pool === CustomerPoolEnum.PUBLIC) {
          poolType = 'public';
        } else if (pool === CustomerPoolEnum.BIZ) {
          poolType = 'biz';
        }
        result.showMobile = hasPermission(
          `page:customer-list:${poolType}-pool:showMobile`,
        );
        result.reAllocate = hasPermission(
          `page:customer-list:${poolType}-pool:re-allocate`,
        );
        result.addLoan = hasPermission(
          `page:customer-list:${poolType}-pool:add-loan`,
        );
        result.showChannel = hasPermission(
          `page:customer-list:${poolType}-pool:showChannel`,
        );
        result.canDropToBlackList = hasPermission(
          `page:customer-list:${poolType}-pool:drop2blacklist`,
        );
        result.canUpdateCustomer = hasPermission(
          `page:customer-list:${poolType}-pool:edit`,
        );
        if (pool === CustomerPoolEnum.PUBLIC) {
          result.canGetCustomerBySelf = hasPermission(
            'page:customer-list:public-pool:get',
          );
        }
        break;
      }
    }
    return result;
  }, [listType, pool]);

  const ref = useRef<ActionType>();

  const [currentSelectedCustomerId, setCurrentSelectedCustomerId] =
    useState<I.ID>();

  const [currentCustomerIdList, setCurrentCustomerIdList] = useState<I.ID[]>();

  const [handledCustomerId, setHandledCustomerId] = useState<I.ID[]>();

  const columns: ProColumns<CustomerListItemV2DTO>[] = [
    {
      title: 'id',
      search: false,
      dataIndex: 'id',
      width: '5%',
      fixed: 'left',
      render: (_, { id, score }) => {
        return <span>{id}</span>;
      },
    },
    {
      search: false,
      title: '客户',
      width: '10%',
      render: (_, customer) => {
        return (
          <CustomerSmummary
            showMobie={permissions.showMobile}
            customer={customer}
            onSelect={setCurrentSelectedCustomerId}
            listType={listType}
          />
        );
      },
    },
    {
      title: '渠道',
      width: '5%',
      order: 995,
      renderFormItem: () => <CustomerChannelSelect mode="multiple" />,
      dataIndex: 'channel',
      hideInTable: !permissions.showChannel,
      hideInForm: !permissions.showChannel,
      hideInSearch: !permissions.showChannel,
      search: {
        transform: (value) => {
          return {
            channelList: value,
          };
        },
      },
    },
    {
      title: '分组',
      hideInSearch: true,
      width: '5%',
      dataIndex: 'group',
      render: (_, { group }) => {
        return <span>{group && customerGroupMapping[group]}</span>;
      },
    },
    {
      search: false,
      title: '资质',
      width: '18%',
      render: (
        _,
        {
          remark,
          hasBusinessLicense,
          hasCar,
          hasCreditCard,
          hasHouse,
          hasInsurance,
          hasProvidentFund,
          hasSocialSecurity,
          hasWages,
          isNative,
        },
      ) => {
        return (
          <Space direction="vertical" size={0}>
            <Space size={0} className="space-flex-box">
              {hasHouse && (
                <span className="list-table-tips">房</span>
                // <Tooltip title="房">
                //   <IconFont type="icon-shouye"  />
                // </Tooltip>
              )}
              {hasCar && (
                <span className="list-table-tips">车</span>
                // <Tooltip title="车">
                //   <IconFont type="icon-car-v2-full" />
                // </Tooltip>
              )}
              {hasCreditCard && (
                <span className="list-table-tips">信用卡</span>
                // <Tooltip title="信用卡">
                //   <IconFont type="icon-xinyongqia" />
                // </Tooltip>
              )}
              {hasInsurance && (
                <span className="list-table-tips">寿险保险</span>
                // <Tooltip title="寿险保险">
                //   <IconFont type="icon-weibiaoti2fuzhi10" />
                // </Tooltip>
              )}
              {hasSocialSecurity && (
                <span className="list-table-tips">社保</span>
                // <Tooltip title="社保">
                //   <IconFont type="icon-shebao" />
                // </Tooltip>
              )}
              {hasWages && (
                <span className="list-table-tips">代发工资</span>
                // <Tooltip title="代发工资">
                //   <IconFont type="icon-gongzitiao" />
                // </Tooltip>
              )}
              {hasProvidentFund && (
                <span className="list-table-tips">公积金</span>
                // <Tooltip title="公积金">
                //   <IconFont type="icon-gongjijin" />
                // </Tooltip>
              )}
              {hasBusinessLicense && (
                <span className="list-table-tips">营业执照</span>
                // <Tooltip title="营业执照">
                //   <IconFont type="icon-yingyezhizhao" />
                // </Tooltip>
              )}
              {isNative && (
                <span className="list-table-tips">本地人</span>
                // <Tooltip title="本地人">
                //   <UserOutlined />
                // </Tooltip>
              )}
            </Space>
            <span>{remark}</span>
          </Space>
        );
      },
    },
    // {
    //   search: false,
    //   title: '放款',
    //   render: (_, { loanCount, loanSum }) => {
    //     if (!loanCount) {
    //       return null;
    //     }
    //     return (
    //       <Space direction="vertical" style={{ width: '140px' }}>
    //         <span>次数：{loanCount}</span>
    //         <span>金额：¥{loanSum}</span>
    //       </Space>
    //     );
    //   },
    // },
    {
      title: '状态',
      width: '5%',
      search: false,
      render: (_, { state }) => {
        return <span style={{}}>{customerStateMapping[state] || state}</span>;
      },
    },
    {
      title: pool === CustomerPoolEnum.PUBLIC ? '前业务员' : '业务员',
      width: '7%',
      search: false,
      hideInTable: listType === ListCustomerType.MY,
      render: (_, { userId, userName }) => {
        const { setUser } = useContext(listContext);
        return (
          <>
            {userId && userName && (
              <Button
                type="link"
                onClick={() => setUser?.({ id: userId, name: userName })}
              >
                <Space className="avatar-box">
                  <AvatarImg userId={userId} />
                  <span>{userName}</span>
                </Space>
              </Button>
            )}
          </>
        );
      },
    },
    {
      title: '最后跟进',
      search: false,
      sorter: true,
      dataIndex: 'lastEventAt',
      render: (_, { lastEvent, id, name, group }) => {
        const { currentUser } = useCompany();
        const { setCustomerEvent } = useContext(listContext);
        const highlight =
          listType === 'MY' &&
          group === CustomerGroupEnum.NEW_ALLOT &&
          currentUser?.id !== lastEvent?.userId;

        if (!lastEvent?.id) {
          return null;
        }
        return (
          <Space direction="vertical" size={0}>
            <Button
              style={{ color: highlight ? 'red' : undefined, padding: '0px' }}
              size="small"
              type="link"
              onClick={() => {
                setCustomerEvent?.({ customerId: id, customerName: name });
              }}
            >
              {lastEvent?.userName || '系统'}@
              {dayjs(lastEvent?.createdAt).format('YYYY-MM-DD HH:mm:ss')}
            </Button>
            <span style={{ whiteSpace: 'pre-line' }}>{lastEvent?.comment}</span>
            {/* <span>{event?.remark}</span> */}
          </Space>
        );
      },
      width: '19%',
    },
    {
      title: '时间',
      sorter: true,
      width: '14%',
      dataIndex: 'createdAt',
      search: false,
      render: (_, dto) => {
        return (
          <Space direction="vertical" size={0}>
            <CustomerTraceTimeUnit name="入" time={dto.trace?.createdAt} />
            <CustomerTraceTimeUnit name="派" time={dto.trace?.assignedAt} />
            {dto.pool === CustomerPoolEnum.BIZ &&
              dto.group !== CustomerGroupEnum.LOCKED && (
                <DropToPublicPoolTime state={dto.state} trace={dto.trace} />
              )}
          </Space>
        );
      },
    },
    {
      title: '操作',
      search: false,
      fixed: 'right',
      width: '8%',
      render: (_, { id, pool, name, mobile, star, score }) => {
        const canUpdateScore = permissions.canUpdateScore;
        if (listType === ListCustomerType.MY) {
          return (
            <MyCustomerOperator
              mobile={mobile}
              id={id}
              score={score}
              name={name}
              star={star}
              canUpdateScore={canUpdateScore}
            />
          );
        }
        return (
          <BizCustomerOperator
            name={name}
            id={id}
            pool={pool}
            score={score}
            canAddLoan={permissions.addLoan}
            showMobile={permissions.showMobile}
            canDropToBlackList={permissions.canDropToBlackList}
            canUpdateCustomer={permissions.canUpdateCustomer}
            canEditCustomerName={permissions.canEditCustomerName}
            canUpdateScore={canUpdateScore}
          />
        );
      },
    },

    // 下面是搜索
    {
      hideInTable,
      title: '客户信息',
      dataIndex: 'keyword',
      search: {
        transform: (keyword) => {
          return {
            keyword: keyword.replace(/(\s|　)*/g, ''),
          };
        },
      },
      order: 998,
      renderFormItem: () => <Input placeholder="客户姓名/电话" />,
    },
    {
      hideInTable,
      title: '客户星级',
      order: 999,
      dataIndex: 'star',
      valueType: 'select',
      proFieldProps: {
        allowClear: true,
      },
      valueEnum: {
        0: {
          text: <Rate disabled value={0} />,
        },
        1: {
          text: <Rate disabled value={1} />,
        },
        2: {
          text: <Rate disabled value={2} />,
        },
        3: {
          text: <Rate disabled value={3} />,
        },
        4: {
          text: <Rate disabled value={4} />,
        },
        5: {
          text: <Rate disabled value={5} />,
        },
      },
      search: {
        transform: (value) => {
          return {
            star: Number(value),
          };
        },
      },
    },
    {
      hideInTable,
      hideInSearch: listType === 'MY',
      title: '业务员',
      order: 997,
      search: {
        transform: (result) => {
          return {
            ...result,
          };
        },
      },
      renderFormItem: () => {
        return <EnhanceUserList />;
      },
    },
    {
      hideInTable,
      title: '客户状态',
      order: 994,
      dataIndex: 'state',
      search: {
        transform: (stateList) => ({ stateList }),
      },
      renderFormItem: () => <CustomerStateSelect mode="multiple" allowClear />,
    },
    ...[
      ['createdAt', '入库时间'],
      ['handledAt', '派发时间'],
      ['visitedAt', '上门时间'],
      ['signedAt', '签约时间'],
      ['lastEventAt', '最后跟进'],
    ].map(
      ([dataIndex, title]) =>
        ({
          search: {
            transform: (value: any) => {
              return {
                [dataIndex]: [
                  value?.[0] ? formatStartTime(value[0]) : undefined,
                  value?.[1] ? formatEndTime(value[1]) : undefined,
                ],
              };
            },
          },
          dataIndex,
          title,
          hideInTable,
          renderFormItem: () => {
            return <DateRange />;
          },
        } as const),
    ),
    {
      hideInTable,
      title: '客户分组',
      order: 993,
      dataIndex: 'searchGroup',
      search: {
        transform: (groupList) => {
          return { groupList };
        },
      },
      renderFormItem: () => {
        const groupOptions = Object.entries(customerGroupMapping).map(
          ([value, label]) => ({
            label,
            value,
          }),
        );
        return (
          <Select
            allowClear
            options={groupOptions}
            placeholder="客户分组"
            mode="multiple"
          />
        );
      },
    },
    {
      hideInTable,
      title: '重复申请',
      dataIndex: 'count',
      valueType: 'checkbox',
      valueEnum: {
        1: {
          text: '是',
        },
      },
      search: {
        transform: (value) => {
          return {
            count: !!value?.length ? 1 : undefined,
          };
        },
      },
    },
    {
      hideInTable,
      title: '客户资质',
      search: {
        transform: (values) => {
          return values.reduce((acc: {}, cur: string) => {
            return {
              ...acc,
              [cur]: 1,
            };
          }, {});
        },
      },
      renderFormItem: () => {
        const options = [
          ['本地人', 'isNative'],
          ['营业执照', 'hasBusinessLicense'],
          ['车子', 'hasCar'],
          ['信用卡', 'hasCreditCard'],
          ['房子', 'hasHouse'],
          ['寿险保险', 'hasInsurance'],
          ['公积金', 'hasProvidentFund'],
          ['社保', 'hasSocialSecurity'],
          ['代发工资', 'hasWages'],
        ];
        return (
          <Select
            mode="tags"
            allowClear
            placeholder="客户资质"
            options={options.map((item) => ({
              label: item[0],
              value: item[1],
            }))}
          />
        );
      },
    },
    {
      search: {
        transform: (departmentIdList) => {
          return { departmentIdList };
        },
      },
      hideInTable,
      hideInSearch: listType === 'MY' || pool !== CustomerPoolEnum.BIZ,
      title: '团队筛选',
      renderFormItem: () => {
        return <DepartmentSelect multiple />;
      },
    },
    {
      hideInTable,
      title: '优质客户',
      dataIndex: 'score',
      valueType: 'checkbox',
      valueEnum: {
        1: {
          text: '是',
        },
      },
      search: {
        transform: (value) => {
          return {
            score: !!value?.length ? 90 : undefined,
          };
        },
      },
    },
  ];

  useEffect(() => {
    ref.current?.reloadAndRest?.();
  }, [listType, pool, group]);

  const [selectedUser, setSelectdUser] = useState<{ id: I.ID; name: string }>();
  const [selectedCustomerEvent, setSelectedCustomerEvent] = useState<{
    customerId: I.ID;
    customerName: string;
  }>();

  const enableVirtual = useMemo(() => {
    return !!(
      ref.current?.pageInfo?.pageSize && ref.current.pageInfo.pageSize > 500
    );
  }, [ref.current]);

  return (
    <RefreshProvider refresh={() => ref.current?.reload()}>
      <listContext.Provider
        value={{
          setUser: setSelectdUser,
          setCustomerEvent: setSelectedCustomerEvent,
        }}
      >
        {selectedCustomerEvent && (
          <LastEventDrawer
            open={!!selectedCustomerEvent}
            onCancel={() => setSelectedCustomerEvent(undefined)}
            customerId={selectedCustomerEvent.customerId}
            customerName={selectedCustomerEvent.customerName}
          />
        )}
        {selectedUser && (
          <UserModal
            id={selectedUser.id}
            name={selectedUser.name}
            open={!!selectedUser}
            onCancel={() => setSelectdUser(undefined)}
          />
        )}
        {currentSelectedCustomerId && (
          <CustomerDetail
            showMobile={permissions.showMobile}
            updateCustomerNamePermission={permissions.canEditCustomerName}
            onCancel={() => setCurrentSelectedCustomerId(undefined)}
            id={currentSelectedCustomerId}
            next={(handledId) => {
              setHandledCustomerId((v) => {
                const vv = [...(v || []), handledId];

                // 这里可能不是从头开始，所有要从 currentCustomerIdList 里找到当前的位置
                const index = currentCustomerIdList?.findIndex(
                  (id) => id === handledId,
                );

                if (index === -1 || index === undefined) {
                  // 没有了
                  message.warning('当前页面的数据已经全部处理完毕');
                  return [];
                }

                const remainIds = currentCustomerIdList?.slice(index + 1);

                setCurrentSelectedCustomerId(remainIds?.[0]);
                if (remainIds?.length === 0) {
                  message.warning('当前页面的数据已经全部处理完毕，请手动翻页');
                  return [];
                }
                return vv;
              });
            }}
            withComment
            open={!!currentSelectedCustomerId}
          />
        )}
        <ProTable<
          CustomerListItemDTO,
          NonNullable<
            Parameters<typeof customerController.listCustomerV2>[1]
          > & {
            listType: ListCustomerType;
            group?: CustomerGroupEnum;
            searchGroup?: CustomerGroupEnum;
          }
        >
          actionRef={ref}
          rowSelection={
            listType === 'POOL' && {
              selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
              columnWidth: 50,
              fixed: true,
            }
          }
          columns={columns}
          className="pro-table"
          size="small"
          bordered
          cardBordered={{ search: true }}
          params={{
            listType,
            pool,
            group,
          }}
          virtual
          scroll={{
            x: window.innerWidth - 248 - 10,
            y: window.innerHeight - 65,
            scrollToFirstRowOnChange: true,
          }}
          toolBarRender={(_, rows) => {
            return [
              listType === ListCustomerType.MY && <AddCustomer />,
              pool === CustomerPoolEnum.PUBLIC &&
                permissions.canGetCustomerBySelf && (
                  <PublicPoolGetButton
                    onSuccess={() => ref.current?.reloadAndRest?.()}
                    ids={rows.selectedRowKeys}
                    key="public-pool-get"
                  />
                ),
              listType === ListCustomerType.POOL &&
                pool &&
                permissions.reAllocate &&
                rows.selectedRows && (
                  <PoolReAllocate
                    pool={pool}
                    targets={rows.selectedRows}
                    onSuccess={() => {
                      ref.current?.reload?.();
                      ref.current?.clearSelected?.();
                    }}
                  />
                ),
              listType === ListCustomerType.POOL &&
                pool === CustomerPoolEnum.BIZ &&
                permissions.drop2publicPoolBatch &&
                rows.selectedRows && (
                  <DropToPublicPoolBatch
                    targets={rows.selectedRows}
                    onSuccess={() => ref.current?.reloadAndRest?.()}
                  />
                ),
              listType === ListCustomerType.POOL &&
                rows.selectedRowKeys &&
                permissions.canDropToBlackList && (
                  <BatchDropToBlackList
                    refresh={() => ref.current?.reload?.()}
                    ids={rows.selectedRowKeys}
                  />
                ),
            ];
          }}
          options={false}
          rowKey="id"
          dateFormatter={false}
          pagination={{
            pageSizeOptions: [20, 50, 100, 200, 500, 1000],
            showSizeChanger: true,
          }}
          request={async (
            { current, pageSize, listType, group, ...params },
            sort,
          ) => {
            console.log(params);
            return customerController
              .listCustomerV2(
                companyId,
                {
                  ...params,
                  sorter: sort as any,
                },
                listType,
                group ? group : params?.searchGroup,
                current,
                pageSize,
              )
              .then((data) => {
                setCurrentCustomerIdList(
                  data?.list?.map((item) => item.id) || [],
                );
                return { data: data.list, success: true, total: data.total };
              });
          }}
        />
      </listContext.Provider>
    </RefreshProvider>
  );
}
