import { Modal, Select, Space, Spin, message } from 'antd';
import {
  UserStateEnum,
  companyController,
  customerController,
  systemController,
  userController,
} from '../api';
import { useRequest } from 'ahooks';
import { useEffect, useState } from 'react';
import { userRefreshContext } from './CustomerDetail';
import CompanySelect from './CompanySelect';

export default ({
  customerId,
  open,
  onCancel,
}: {
  customerId: I.ID;
  open?: boolean;
  onCancel: () => void;
}) => {
  const {
    data: staffs,
    run: refreshStaffs,
    loading: staffListLoading,
  } = useRequest(
    (companyId: I.ID) => {
      return userController.listAllBaseUser(companyId).then((user) => {
        return user.filter((item) => item.state === UserStateEnum.ACTIVE);
      });
    },
    {
      manual: true,
    },
  );

  const [selectedUser, setSelectedUser] = useState<I.ID>();

  const { refresh } = userRefreshContext();

  useEffect(() => {
    if (open) {
      setSelectedUser(undefined);
    }
  }, [open, setSelectedUser]);

  const { loading: transferLoading, run: transfer } = useRequest(
    (targetId: I.ID) => {
      return customerController.transfer(customerId, customerId, targetId);
    },
    {
      manual: true,
      onSuccess: () => {
        message.success('转移成功');
        refresh?.();
        onCancel();
      },
      onError: (e) => message.error(`操作失败：${e.message}`),
    },
  );

  const onSubmit = () => {
    if (selectedUser) {
      transfer(selectedUser);
    }
  };

  const loading = staffListLoading || transferLoading;
  return (
    <Modal
      confirmLoading={loading}
      open={open}
      onCancel={onCancel}
      title="转移客户"
      onOk={onSubmit}
    >
      <Space direction="vertical">
        <Space>
          <span>目标公司：</span>
          <CompanySelect
            style={{ width: 200 }}
            loading={loading}
            onSelect={(id) => {
              refreshStaffs(id);
            }}
          />
        </Space>
        <Space>
          <span>目标员工：</span>
          <Select
            disabled={staffListLoading || !staffs}
            style={{ width: 200 }}
            showSearch
            loading={loading}
            filterOption={(input, option) =>
              (option?.label || '')
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0 ||
              (option?.mobile || '').indexOf(input) >= 0
            }
            onSelect={(value) => {
              setSelectedUser(value);
            }}
            value={selectedUser}
            options={staffs?.map((item) => ({
              label: item.name,
              mobile: item.mobile,
              value: item.id,
            }))}
          />
        </Space>
      </Space>
    </Modal>
  );
};
