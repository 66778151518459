import { useRequest } from 'ahooks';
import { useCompany } from '../../hooks/company';
import {
  addRole,
  getSystemConfig,
  listRole,
  listRolePermission,
  updateRole,
  updateRolePermission,
} from '../../apis/api';
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Select,
  SelectProps,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  message,
} from 'antd';
import { ColumnType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { PermissionTypeEnum, loginController, userController } from '../../api';
import '../../styles/common.scss';
interface EditRoleProps {
  open?: boolean;
  refresh: () => void;
  onCancel: () => void;
}

interface RolePermissionEditData {
  key: string;
  name: string;
  permissions: {
    name: string;
    code: string;
  }[];
  children?: RolePermissionEditData[];
}

const RolePermissionEditor = ({
  open,
  refresh,
  onCancel,
  role,
}: EditRoleProps & { role: I.Role }) => {
  const { id: companyId, currentUser } = useCompany();

  const [codes, setCodes] = useState<string[]>();

  const { data, loading } = useRequest(
    () => {
      return Promise.all([
        loginController.getSystemConfig(),
        listRolePermission(companyId, role.id),
      ]).then(([{ permissions }, rolePermissions]) => {
        const root = permissions
          ?.filter(
            (p) =>
              (p.type === PermissionTypeEnum.MENU ||
                p.type === PermissionTypeEnum.STATIC) &&
              !p.parentCode,
          )
          .map((item) => {
            // root 下面可能是页面，也可能没有页面
            const children = permissions
              ?.filter(
                (p) =>
                  p.parentCode === item.code &&
                  p.type === PermissionTypeEnum.MENU,
              )
              .map((p) => {
                return {
                  key: p.code,
                  ...p,
                  permissions: [
                    p.type === PermissionTypeEnum.MENU && {
                      name: '菜单可见',
                      code: p.code,
                    },
                    ...permissions?.filter((p2) => p2.parentCode === p.code),
                  ],
                };
              });
            const menuPermission = permissions.filter(
              (p) =>
                p.parentCode === item.code &&
                p.type !== PermissionTypeEnum.MENU,
            );
            return {
              key: item.code,
              ...item,
              permissions: [
                item.type === PermissionTypeEnum.MENU && {
                  name: '菜单可见',
                  code: item.code,
                },
                ...menuPermission,
              ].filter((item) => !!item),
              children: children.length > 0 ? children : undefined,
            };
          });
        return { permissions, rolePermissions, root };
      });
    },
    {
      refreshDeps: [role, companyId],
    },
  );

  const { permissions, rolePermissions, root } = data || {};

  useEffect(() => {
    setCodes(rolePermissions?.map(({ code }) => code));
  }, [rolePermissions]);

  const { loading: updateLoading, runAsync: updatePermission } = useRequest(
    (data: string[]) => {
      return updateRolePermission(companyId, role.id, data);
    },
    {
      manual: true,
      refreshDeps: [companyId, role.id],
      onSuccess: () => {
        message.success('更新成功');
        onCancel();
      },
      onError: (e) => {
        message.error(e.message);
      },
    },
  );

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      confirmLoading={updateLoading}
      width={600}
      onOk={() => updatePermission(codes || [])}
      title={`编辑【${role.name}】的权限`}
    >
      <Table
        className="normal-table"
        loading={loading}
        expandable={{
          defaultExpandAllRows: true,
        }}
        bordered
        size="small"
        dataSource={root}
        showHeader={false}
        pagination={false}
        columns={[
          {
            title: '',
            dataIndex: 'name',
            key: 'name',
            width: '140px',
          },
          {
            title: '',
            dataIndex: 'permissions',
            key: 'permissions',
            render: (p: RolePermissionEditData['permissions']) => {
              return (
                <Checkbox.Group
                  onChange={(v) => {
                    setCodes((prev) => {
                      const vv = v as unknown as string[];
                      return [
                        ...(prev?.filter(
                          (item) => !p.some(({ code }) => item === code),
                        ) || []),
                        ...vv,
                      ];
                    });
                  }}
                  value={p
                    .filter((item) => codes?.includes(item.code))
                    .map(({ code }) => code)}
                >
                  {p.map((item) => (
                    <Checkbox value={item.code}>
                      {item.name}
                      {currentUser.isSuperAdmin && `(${item.code})`}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              );
            },
          },
        ]}
      />
    </Modal>
  );
};

const RoleViewSelect = (props: SelectProps) => {
  const { id: companyId, roles } = useCompany();

  return (
    <Select {...props} mode="multiple">
      {roles?.map((role) => {
        return <Select.Option value={role.id}>{role.name}</Select.Option>;
      })}
    </Select>
  );
};

const AddRole = ({ open, onCancel, refresh }: EditRoleProps) => {
  const [form] = Form.useForm();

  const { id: companyId, roles } = useCompany();

  useEffect(() => {
    form.resetFields();
  }, []);

  const { run: update, loading } = useRequest(
    (data: Parameters<typeof addRole>[1]) => {
      return addRole(companyId, data);
    },
    {
      manual: true,
      refreshDeps: [companyId],
      onSuccess: () => {
        message.success('新增成功');
        refresh();
        onCancel();
      },
      onError: (e) => message.error(e.message),
    },
  );

  const onSubmit = () => {
    return form
      .validateFields()
      .then((values) => {
        return update(values);
      })
      .catch((e) => {});
  };

  return (
    <Modal
      onCancel={onCancel}
      open={open}
      title="新增角色"
      confirmLoading={loading}
      onOk={onSubmit}
    >
      <Form form={form}>
        <Form.Item name="name" label="角色名称" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="viewRoleId" label="可视角色">
          <RoleViewSelect />
        </Form.Item>
        <Form.Item name="description" label="角色描述">
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const EditRole = ({
  open,
  onCancel,
  role,
  refresh,
}: {
  role: I.Role;
} & EditRoleProps) => {
  const [form] = Form.useForm();

  const { id: companyId } = useCompany();

  useEffect(() => {
    form.resetFields();
  }, [role]);

  const { run: update, loading } = useRequest(
    (data: Parameters<typeof updateRole>[2]) => {
      return updateRole(companyId, role.id, data);
    },
    {
      manual: true,
      refreshDeps: [companyId, role.id],
      onSuccess: () => {
        message.success('更新成功');
        refresh();
        onCancel();
      },
      onError: (e) => message.error(e.message),
    },
  );

  const onSubmit = () => {
    return form
      .validateFields()
      .then((values) => {
        return update(values);
      })
      .catch((e) => {});
  };

  return (
    <Modal
      onCancel={onCancel}
      open={open}
      title="编辑角色"
      confirmLoading={loading}
      onOk={onSubmit}
    >
      <Form
        form={form}
        initialValues={{
          ...role,
          viewRoleId: role.views?.map((view) => view.view.id),
        }}
      >
        <Form.Item name="name" label="角色名称" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="viewRoleId" label="可视角色">
          <RoleViewSelect />
        </Form.Item>
        <Form.Item name="description" label="角色描述">
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default () => {
  const { id: companyId, hasPermission, currentUser } = useCompany();
  const { data, loading, refresh } = useRequest(
    () => {
      return listRole(companyId);
    },
    {
      refreshDeps: [companyId],
    },
  );

  const [selectRole, setSelectRole] = useState<I.Role>();
  const [addOpen, setAddOpen] = useState<boolean>();
  const [permissionRole, setPermissionRole] = useState<I.Role>();

  const columns: ColumnType<I.Role>[] = [
    {
      title: 'id',
      dataIndex: 'id',
      width: '15%',
    },
    {
      title: '角色名称',
      dataIndex: 'name',
    },
    {
      title: '可视角色',
      render: (_, role) => {
        return (
          <Space>
            {role.views?.map((item) => {
              return <Tag>{item.view.name}</Tag>;
            })}
          </Space>
        );
      },
    },
    {
      title: '描述',
      dataIndex: 'description',
    },
    {
      title: hasPermission('page:system:role:edit') && (
        <Button
          type="link"
          onClick={() => setAddOpen(true)}
          disabled={!currentUser.isSuperAdmin}
        >
          新增
        </Button>
      ),
      dataIndex: 'id',
      render: (_, role) => {
        if (!hasPermission('page:system:role:edit')) {
          return null;
        }
        return (
          <Space>
            <Button
              onClick={() => setSelectRole(role)}
              type="link"
              disabled={!currentUser.isSuperAdmin}
            >
              编辑
            </Button>
            <Button
              onClick={() => setPermissionRole(role)}
              type="link"
              disabled={!currentUser.isSuperAdmin}
            >
              授权
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <AddRole
        open={addOpen}
        onCancel={() => setAddOpen(false)}
        refresh={refresh}
      />
      {selectRole && (
        <EditRole
          open={!!selectRole}
          onCancel={() => setSelectRole(undefined)}
          role={selectRole}
          refresh={refresh}
        />
      )}
      {permissionRole && (
        <RolePermissionEditor
          open={!!permissionRole}
          onCancel={() => setPermissionRole(undefined)}
          refresh={refresh}
          role={permissionRole}
        />
      )}
      <Table
        className="normal-table"
        columns={columns}
        dataSource={data}
        loading={loading}
        bordered
        size="small"
        rowKey={'id'}
        pagination={false}
      />
    </>
  );
};
