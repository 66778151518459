import {
  RouteObject,
  createBrowserRouter,
  useNavigate,
} from 'react-router-dom';
import Login from './pages/login/Login';
import Layout from './components/layouts/Layout';
import CompanyRouter from './hooks/company';
import menuRouter, { MenuRouter } from './components/layouts/router';
import SwitchUser from './pages/login/SwitchUser';
import KeepAlive from 'react-activation';
import { MobileMenuRouter, mobileRoutes } from './pages/h5/route';
import MobileLayout from './pages/h5/Layout';
import { useEffect } from 'react';
import AppDownload from './pages/AppDownload';
import LoginWithToken from './pages/login/LoginWithToken';

const renderRouter = (
  router: MenuRouter[],
  parentKey?: string,
): RouteObject[] => {
  return router.map((item) => {
    return {
      path: item.key,
      element: item.component && (
        <KeepAlive
          autoFreeze
          cacheKey={item.key}
          name={item.key}
          menuRoute={{
            ...item,
            fullpath: parentKey ? `${parentKey}/${item.key}` : item.key,
          }}
        >
          {item.component}
        </KeepAlive>
      ),
      children: item.children && renderRouter(item.children, item.key),
    };
  });
};

const renderMobileRoute = (router: MobileMenuRouter[]): RouteObject[] => {
  return router.map((item) => {
    return {
      path: item.key,
      element: item.component,
      children: item.children && renderMobileRoute(item.children),
    };
  });
};

const Index = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/login');
  }, []);
  return <></>;
};

const router = createBrowserRouter([
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/app-download',
    element: <AppDownload />,
  },
  {
    path: '/',
    element: <Index />,
  },
  {
    path: '/h5',
    children: renderMobileRoute(mobileRoutes),
  },
  {
    path: '/switch-user',
    element: <SwitchUser />,
  },
  {
    path: '/login_with_token',
    element: <LoginWithToken />,
  },
  {
    path: '/:companyId',
    element: <CompanyRouter />,
    children: [
      {
        path: '',
        element: <Layout />,
        children: renderRouter(menuRouter),
      },
    ],
  },
]);

export default router;
