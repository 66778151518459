import { Switch, Tooltip } from 'antd';
import { useCompany } from '../hooks/company';
import { useRequest } from 'ahooks';
import { userController } from '../api';

export default function SelfPauseSwitcher() {
  const { id: companyId, currentUser, refresh, hasPermission } = useCompany();

  const hasPausePermission = hasPermission('system:user-config:self-pause');

  const pause = !!currentUser.userConfig?.newCustomerStatus;

  const { run, loading } = useRequest(
    (status: boolean) => {
      return userController.updateUserConfig(companyId, currentUser.id, {
        selfPause: status,
      });
    },
    {
      manual: true,
      onSuccess: () => {
        refresh();
      },
    },
  );

  return (
    <Switch
      disabled={!hasPausePermission}
      checked={!pause}
      loading={loading}
      onClick={() => {
        run(!pause);
      }}
    />
  );
}
