import { useRequest } from 'ahooks';
import { Select, SelectProps } from 'antd';
import { listRole } from '../apis/api';
import { useCompany } from '../hooks/company';

export default ({ ...props }: SelectProps) => {
  const { id: companyId, roles, currentUser } = useCompany();

  const views = roles?.find((role) => role.id === currentUser?.role?.id)?.views;

  const { data, loading } = useRequest(
    () => {
      return listRole(companyId).then((roles) => {
        if (currentUser.isSuperAdmin) {
          return roles;
        }
        return roles.filter((role) => {
          return views?.some((view) => view.view.id === role.id);
        });
      });
    },
    {
      refreshDeps: [companyId, views, currentUser.isSuperAdmin],
    },
  );
  return (
    <Select {...props} loading={loading} allowClear placeholder="角色">
      {data?.map((role) => (
        <Select.Option value={role.id} key={role.id}>
          {role.name}
        </Select.Option>
      ))}
    </Select>
  );
};
