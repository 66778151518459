import { Button, message } from 'antd';
import { useMemo } from 'react';
import { useCompany } from '../hooks/company';
import { useRequest } from 'ahooks';
import { customerController } from '../api';

export default ({
  ids,
  onSuccess,
}: {
  ids?: I.ID[];
  onSuccess: () => void;
}) => {
  const { config, id: companyId } = useCompany();
  const canGet = useMemo(() => {
    return !!ids?.length;
  }, [ids]);

  const { run, loading } = useRequest(
    () => {
      if (ids?.length) {
        return customerController.pickCustomer(companyId, ids);
      }
      return Promise.reject(new Error('请选择客户'));
    },
    {
      manual: true,
      onSuccess: () => {
        onSuccess();
        message.success('领取成功');
      },
      onError: (e) => {
        message.error(e.message || '领取失败');
      },
    },
  );
  return (
    <Button
      disabled={!canGet}
      type="primary"
      loading={loading}
      onClick={() => run()}
    >
      领取
    </Button>
  );
};
