import {
  ModalForm,
  ProForm,
  ProFormDatePicker,
  ProFormDigit,
  ProFormInstance,
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
} from '@ant-design/pro-components';
import {
  AutoComplete,
  AutoCompleteProps,
  Form,
  Modal,
  Select,
  SelectProps,
  Space,
  Spin,
  message,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import {
  AddLoanDTO,
  BaseUserDTO,
  UserStateEnum,
  customerController,
  loanController,
  userController,
} from '../api';
import { useRequest } from 'ahooks';
import { useCompany } from '../hooks/company';
import { DefaultOptionType } from 'antd/es/select';
import { userRefreshContext } from './CustomerDetail';
import { formatStartTime } from '../utils';
import UserSelect from './UserSelect';

const BankAutoComplete = ({
  companyId,
  ...props
}: { companyId: I.ID } & Omit<AutoCompleteProps, 'options'>) => {
  const [bank, setBank] = useState<string[]>([]);
  const { loading, data } = useRequest(
    () => {
      return loanController.listBank(companyId);
    },
    {
      refreshDeps: [companyId],
    },
  );

  useEffect(() => {
    setBank(data || []);
  }, [data]);

  return (
    <AutoComplete
      {...props}
      onSearch={(v) =>
        setBank(data?.filter((value) => value.includes(v)) || [])
      }
      options={bank?.map((value) => ({ label: value, value }))}
    />
  );
};

export default ({
  id,
  open,
  name,
  onCancel,
}: {
  id: I.ID;
  open?: boolean;
  name: string;
  onCancel: () => void;
}) => {
  const { id: companyId } = useCompany();
  const { refresh } = userRefreshContext();
  return (
    <ModalForm<AddLoanDTO>
      open={open}
      initialValues={{ name }}
      title="添加放款"
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: true,
        onCancel,
      }}
      onFinish={async (values) => {
        let loanAmount = String(values.loanAmount).includes('.')
          ? String(values.loanAmount)
          : String(values.loanAmount) + '.00';
        let brokerage = String(values.brokerage).includes('.')
          ? String(values.brokerage)
          : String(values.brokerage) + '.00';

        if (loanAmount.split('.')[1].length === 1) {
          loanAmount = loanAmount + '0';
        }
        if (brokerage.split('.')[1].length === 1) {
          brokerage = brokerage + '0';
        }

        if (
          loanAmount.split('.')[1].length !== 2 ||
          brokerage.split('.')[1].length !== 2
        ) {
          throw new Error('金额错误');
        }

        await loanController.add(companyId, {
          ...values,
          loanAmount: loanAmount.replace('.', ''),
          brokerage: brokerage.replace('.', ''),
          loanedAt: dayjs(values.loanedAt).format('YYYY-MM-DD[T]23:59:00'), // 如果设置 00 的话，会导致统计那里日期不对
          customerId: id,
        });
        message.success('添加放款成功');
        refresh?.();
        onCancel();
      }}
    >
      <ProForm.Group>
        <div style={{ width: '328px' }}>
          <h1>{name}</h1>
        </div>
        <ProForm.Item
          label="业务经理"
          name="userId"
          style={{ width: '328px' }}
          rules={[{ required: true }]}
        >
          <UserSelect />
        </ProForm.Item>
      </ProForm.Group>
      <ProForm.Group>
        <Form.Item
          name="bank"
          label="放款银行"
          style={{ width: '328px' }}
          rules={[{ required: true }]}
        >
          <BankAutoComplete companyId={companyId} />
        </Form.Item>
        <ProFormDatePicker
          width="md"
          name="loanedAt"
          label="放款日期"
          dataFormat="YYYY-MM-DD 00:00:00"
          transform={(value) => ({
            loanedAt: dayjs(value),
          })}
          rules={[{ required: true }]}
        />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormDigit
          width="md"
          name="loanAmount"
          label="放款金额"
          rules={[{ required: true }]}
          fieldProps={{ precision: 2 }}
        />
        <ProFormDigit
          width="md"
          name="brokerage"
          label="收佣金额"
          min={Number.MIN_SAFE_INTEGER}
          fieldProps={{ precision: 2 }}
          rules={[{ required: true }]}
        />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormTextArea width="lg" name="remark" label="备注" />
      </ProForm.Group>
    </ModalForm>
  );
};
