import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

export default () => {
  return (
    <Tooltip
      overlayInnerStyle={{ width: '400px' }}
      title={
        <ul>
          <li>0星 待跟进 没有探出来客户资质的 </li>
          <li>1星 资质不符 情况已经了解，但是无任何条件的</li>
          <li>2星 待签约 有资质但是目前没有较好方案的客户</li>
          <li>3星 待签约 可贷点比较薄弱，只能批复10万左右的</li>
          <li>4星 待签约 可贷点非常好 能操作20万以上的</li>
        </ul>
      }
    >
      <InfoCircleOutlined />
    </Tooltip>
  );
};
