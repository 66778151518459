import { BaseDepartmentDTO, Long } from './api';

import dayjs from 'dayjs';

function replaceChineseNumbers(text: string) {
  const chineseNumbers: Record<string, string> = {
    零: '0',
    一: '1',
    二: '2',
    三: '3',
    四: '4',
    五: '5',
    六: '6',
    七: '7',
    八: '8',
    九: '9',
    十: '10',
  };

  // 通过正则表达式匹配中文数字并替换
  return text.replace(/[零一二三四五六七八九十]/g, function (match) {
    return chineseNumbers[match];
  });
}

export const sortName = (a?: string, b?: string) => {
  if (!a || !b) {
    return 0;
  }
  return replaceChineseNumbers(a).localeCompare(
    replaceChineseNumbers(b),
    'zh-Hans-CN',
    {
      numeric: true,
      sensitivity: 'accent',
    },
  );
};

export const flatDepartment = (
  departments: BaseDepartmentDTO[],
  depId?: Long,
): BaseDepartmentDTO[] => {
  if (!depId) {
    return [];
  }
  const dep = departments.find((item) => item.id === depId);
  if (!dep) {
    return [];
  }

  return [dep, ...flatDepartment(departments, dep.parentId)];
};

// 找到所有的直接子区域
export const findChildrenDepartment = (
  departments: BaseDepartmentDTO[],
  depId: I.ID | null,
) => {
  return departments.filter((dep) => dep.parentId === depId);
};

// 找到包含自己的所有的子区域
export const findAllDepartmentId = (
  departments: BaseDepartmentDTO[],
  depId: I.ID | null | undefined,
): BaseDepartmentDTO[] => {
  if (!depId) {
    return departments;
  }
  // 先找到 depId 的区域
  const dep = departments.find((dep) => dep.id === depId);
  if (!dep) {
    return [];
  }

  const children = findChildrenDepartment(departments, dep.id);
  if (children.length === 0) {
    return [dep];
  }

  // // 找到所有的子区域
  // const allChildren = children.reduce<BaseDepartmentDTO[]>(
  //   (acc, cur) => [...acc, ...findAllDepartmentId(departments, cur.id)],
  //   [],
  // );

  return [...children, dep];
};

export const formatStartTime = (time: dayjs.Dayjs) => {
  return time.format('YYYY-MM-DD[T]00:00:00');
};

export const formatEndTime = (time: dayjs.Dayjs) => {
  return time.format('YYYY-MM-DD[T]23:59:59');
};
