import {
  PlayCircleOutlined,
  DashboardOutlined,
  UserOutlined,
  DeploymentUnitOutlined,
  AreaChartOutlined,
  InsertRowAboveOutlined,
  MailOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import UserList from '../../pages/system/UserList';
import Department from '../../pages/system/Department';
import RoleList from '../../pages/system/RoleList';
import CustomerList from '../../pages/customer/List';
import {
  CustomerGroupEnum,
  CustomerPoolEnum,
  ListCustomerType,
} from '../../api';
import LoanList from '../../pages/customer/LoanList';
import BlackList from '../../pages/customer/BlackList';
import OutsideList from '../../pages/customer/OutsideList';
import VisitorList from '../../pages/customer/VisitorList';
import VisitorAnalyse from '../../pages/analyse/VisitorAnalyse';
import CustomerAnalyse from '../../pages/analyse/CustomerAnalyse';
import ChannelAnalyse from '../../pages/analyse/ChannelAnalyse';
import LoanAnalyse from '../../pages/analyse/LoanAnalyse';
import HandleAnalyse from '../../pages/analyse/HandleAnalyse';
import Welcome from '../../pages/welcome';
import NewDataAnalyse from '../../pages/analyse/NewDataAnalyse';
import CompanyMessageList from '../../pages/CompanyMessageList';
import CompanyConfig from '../../pages/system/CompanyConfig';
import StudyList from '../../pages/study/StudyList';
import UserInfo from '../../pages/UserInfo';
import UserFeedback from '../../pages/system/UserFeedback';

export interface MenuRouter {
  key: string;
  label: string;
  component?: React.ReactNode;
  icon?: React.ReactNode;
  children?: MenuRouter[];
  permissionCode?: string;
}

const MyCustomerList = ({ group }: { group?: CustomerGroupEnum }) => {
  return <CustomerList listType={ListCustomerType.MY} group={group} />;
};

const menuRouter = [
  {
    key: 'welcome',
    icon: <DashboardOutlined />,
    component: <Welcome />,
    label: '欢迎页',
  },
  {
    key: 'my-customer',
    icon: <UserOutlined />,
    label: '我的客户',
    permissionCode: 'page:my-customer',
    children: [
      {
        key: 'all',
        label: '全部客户',
        component: <MyCustomerList />,
      },
      {
        key: 'new-customer',
        label: '新客户',
        component: <MyCustomerList group={CustomerGroupEnum.NEW_CUSTOMER} />,
      },
      {
        key: 'new_allot',
        label: '新分派',
        component: <MyCustomerList group={CustomerGroupEnum.NEW_ALLOT} />,
      },
      {
        key: 'expand',
        label: '拓展',
        component: <MyCustomerList group={CustomerGroupEnum.EXPAND} />,
      },
      {
        key: 'mortgage',
        label: '抵押',
        component: <MyCustomerList group={CustomerGroupEnum.MORTGAGE} />,
      },
      {
        key: 'locked',
        label: '锁定',
        component: <MyCustomerList group={CustomerGroupEnum.LOCKED} />,
      },
      {
        key: 'a',
        label: 'A 类客户',
        component: <MyCustomerList group={CustomerGroupEnum.A} />,
      },
      {
        key: 'b',
        label: 'B 类客户',
        component: <MyCustomerList group={CustomerGroupEnum.B} />,
      },
      {
        key: 'c',
        label: 'C 类客户',
        component: <MyCustomerList group={CustomerGroupEnum.C} />,
      },
      {
        key: 'd',
        label: 'D 类客户',
        component: <MyCustomerList group={CustomerGroupEnum.D} />,
      },
      {
        key: 'e',
        label: 'E 类客户',
        component: <MyCustomerList group={CustomerGroupEnum.E} />,
      },
    ],
  },
  // {
  //   key: 'visit-manage',
  //   label: '上门统计',
  //   permissionCode: 'page:visitor',
  //   component: <VisitorList />,
  //   icon: <InsertRowAboveOutlined />,
  // },
  {
    key: 'customer-manage',
    label: '客户管理',
    permissionCode: 'page:customer-list',
    icon: <DeploymentUnitOutlined />,
    children: [
      {
        key: 'pool',
        label: '总池',
        permissionCode: 'page:customer-list:total-pool',
        component: <CustomerList listType={ListCustomerType.POOL} />,
      },
      {
        key: 'biz-pool',
        label: '业务池',
        permissionCode: 'page:customer-list:biz-pool',
        component: (
          <CustomerList
            pool={CustomerPoolEnum.BIZ}
            listType={ListCustomerType.POOL}
          />
        ),
      },
      {
        key: 'public-pool',
        label: '公共池',
        permissionCode: 'page:customer-list:public-pool',
        component: (
          <CustomerList
            pool={CustomerPoolEnum.PUBLIC}
            listType={ListCustomerType.POOL}
          />
        ),
      },
      {
        permissionCode: 'page:customer-list:blacklist',
        key: 'blacklist',
        label: '黑名单',
        component: <BlackList />,
      },
      {
        key: 'outsider-pool',
        permissionCode: 'page:customer-list:outsource',
        label: '外部数据源',
        component: <OutsideList />,
      },
    ],
  },
  {
    key: 'system',
    label: '系统管理',
    permissionCode: 'page:system',
    icon: <SettingOutlined />,
    children: [
      {
        key: 'company-config',
        label: '公司配置',
        permissionCode: 'page:system:company',
        component: <CompanyConfig />,
      },
      {
        key: 'user',
        label: '系统用户',
        permissionCode: 'page:system:user',
        component: <UserList />,
      },
      {
        key: 'department',
        permissionCode: 'page:system:organization',
        label: '组织架构',
        component: <Department />,
      },
      {
        key: 'roles',
        permissionCode: 'page:system:role',
        label: '系统角色',
        component: <RoleList />,
      },
      {
        key: 'feedbacks',
        permissionCode: 'page:system:feedback',
        label: '员工反馈',
        component: <UserFeedback />,
      },
    ],
  },
  {
    key: 'analyse',
    label: '报表中心',
    icon: <AreaChartOutlined />,
    permissionCode: 'page:report',
    children: [
      {
        key: 'loan',
        label: '放款统计',
        permissionCode: 'page:report:loan-report',
        component: <LoanAnalyse />,
      },
      {
        key: 'loan-list',
        label: '放款记录',
        permissionCode: 'page:report:loan',
        component: <LoanList />,
      },
      {
        key: 'visitor',
        label: '上门统计',
        permissionCode: 'page:report:visitor',
        component: <VisitorAnalyse />,
      },
      {
        key: 'visit-manage',
        label: '上门记录',
        permissionCode: 'page:visitor',
        component: <VisitorList />,
        // icon: <InsertRowAboveOutlined />,
      },
      {
        key: 'handle',
        label: '工作跟进',
        permissionCode: 'page:report:handle',
        component: <HandleAnalyse />,
      },
      {
        key: 'channel',
        label: '渠道统计',
        permissionCode: 'page:report:channel',
        component: <ChannelAnalyse />,
      },
      {
        key: 'new_data',
        label: '数据跟进',
        permissionCode: 'page:report:new-data',
        component: <NewDataAnalyse />,
      },
      {
        key: 'customer',
        label: '客户分布',
        permissionCode: 'page:report:customer',
        component: <CustomerAnalyse />,
      },
    ],
  },
  {
    key: 'company-message',
    label: '系统公告',
    permissionCode: 'page:company-message',
    component: <CompanyMessageList />,
    icon: <MailOutlined />,
  },
  {
    key: 'company-study',
    label: '学习园地',
    permissionCode: 'page:study',
    component: <StudyList />,
    icon: <PlayCircleOutlined />,
  },
  {
    key: 'user-self-info',
    label: '个人信息',
    component: <UserInfo />,
    icon: <InsertRowAboveOutlined />,
  },
] as MenuRouter[];

export default menuRouter;
