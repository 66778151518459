import { useRequest } from 'ahooks';
import { App, Spin, message } from 'antd';
import { createContext, useCallback, useContext, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { getCompany, getCurrentUser, listDepartment } from '../apis/api';
import {
  BaseDepartmentDTO,
  CompanyDTO,
  CurrentUserDTO,
  PermissionCodeModel,
  RoleDTO,
  SystemConfigModel,
  UserDTO,
  UserStateEnum,
  companyController,
  loginController,
  roleController,
} from '../api';
import { findAllDepartmentId, findChildrenDepartment } from '../utils';

interface Company extends CompanyDTO {
  departments: BaseDepartmentDTO[];
  allDepartments: BaseDepartmentDTO[];
  allCompanyDepartments: BaseDepartmentDTO[];
  rootDepartmentId: I.ID | null;
  currentUser: CurrentUserDTO;
  systemConfig?: SystemConfigModel;
  roles?: RoleDTO[];
}

const context = createContext<
  Company & {
    hasPermission: (permission: string) => boolean;
    refresh: () => void;
  }
>({
  id: '',
  refresh() {},
  name: '',
  rootDepartmentId: null,
  createdAt: '',
  updatedAt: '',
  departments: [],
  allDepartments: [],
  allCompanyDepartments: [],
  hasPermission() {
    return false;
  },
  currentUser: {
    id: '',
    name: '',
    mobile: '',
    isSuperAdmin: false,
    state: UserStateEnum.DELETED,
    createdAt: '',
    updatedAt: '',
  },
});

const CompanyRouter = () => {
  const [currentCompany, setCurrentCompany] = useState<Company | undefined>(
    undefined,
  );
  const navigate = useNavigate();

  const { companyId } = useParams<{ companyId: string }>();

  const { loading, error, refresh } = useRequest(
    () => {
      return Promise.all([
        loginController.getUser(),
        companyController.getCompany(companyId!),
        companyController.listDepartment(companyId!),
        loginController.getSystemConfig(),
        roleController.list(companyId!),
        companyController.listAllDepartment(companyId!),
      ]).then(
        ([
          currentUser,
          company,
          allDepartments,
          systemConfig,
          roles,
          allCompanyDepartments,
        ]) => ({
          currentUser,
          company,
          allDepartments,
          allCompanyDepartments,
          systemConfig,
          roles,
        }),
      );
    },
    {
      refreshDeps: [companyId],
      onSuccess: ({
        company,
        allDepartments,
        currentUser,
        systemConfig,
        allCompanyDepartments,
        roles,
      }) => {
        const rootDepartmentId = currentUser.departmentId || null;

        // 过滤下 department，只能看到自己所在的区域和下属区域
        const departments = findAllDepartmentId(
          allDepartments,
          rootDepartmentId,
        );

        setCurrentCompany({
          ...company,
          rootDepartmentId: currentUser.departmentId || null,
          currentUser,
          allCompanyDepartments,
          departments,
          allDepartments,
          systemConfig,
          roles,
        });
      },
    },
  );

  if (error) {
    // TODO: 401 go to login
    navigate(`/login`);
    message.error(error.message);
  }

  const hasPermission = useCallback(
    (permission: string) => {
      if (currentCompany?.currentUser?.isSuperAdmin) {
        return true;
      }
      return !!currentCompany?.currentUser?.role?.permissions?.find(
        (p) => p.code === permission,
      );
    },
    [currentCompany],
  );

  return (
    <App>
      <Spin spinning={loading}>
        {currentCompany && (
          <context.Provider
            value={{
              ...currentCompany,
              hasPermission,
              refresh: () => {
                refresh();
              },
            }}
          >
            <Outlet />
          </context.Provider>
        )}
      </Spin>
    </App>
  );
};

export const useCompany = () => {
  return useContext(context);
};

export default CompanyRouter;
