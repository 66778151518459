import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

const DateRange = ({
  onChange,
}: {
  onChange?: (
    d: [dayjs.Dayjs | null | undefined, dayjs.Dayjs | null | undefined],
  ) => void;
}) => {
  const [start, setStart] = useState<dayjs.Dayjs | null>();
  const [end, setEnd] = useState<dayjs.Dayjs | null>();

  useEffect(() => {
    onChange?.([start, end]);
  }, [start, end, onChange]);
  return (
    <Space>
      <DatePicker
        placeholder="开始时间"
        value={start}
        onChange={(v) => {
          console.log(v);
          setStart(v);
        }}
        allowClear
      />
      <span>-</span>
      <DatePicker
        allowClear
        placeholder="结束时间"
        value={end}
        onChange={(v) => setEnd(v)}
      />
    </Space>
  );
};

export default DateRange;
