import { Spin } from 'antd';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default () => {
  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    const qs = new URLSearchParams(location.search);
    const token = qs.get('token');
    const companyId = qs.get('companyId');
    if (token) {
      sessionStorage.setItem('token', token);
      navigate(`/${companyId}/welcome`);
    }
  }, [location]);

  return <Spin spinning={true} tip="切换用户中" />;
};
